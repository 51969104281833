//package MahuConfig;

//import java.awt.Image;
//import java.awt.Toolkit;
//import java.awt.image.BufferedImage;
//import java.awt.image.MemoryImageSource;
//import java.io.FileInputStream;
//import java.io.FileOutputStream;
//import java.io.IOException;
//import java.sql.Date;
//import java.text.SimpleDateFormat;


/**
 * The simplest possible servlet.
 *
 * @author James Duncan Davidson
 */

//public        class MahuConfig {

	  export const  	SYSTEM_OK=0X00;
	  export const  	SYSTEM_FAIL=0XFFFF;
	  export const  	MAHU_LIGHT_SYSTEM_RESET=0XFF00;
	  export const MAHU_INT16_SIZE					=	2;
	  export const MAHU_INT32_SIZE					=	4;
	  export const MAHU_INT64_SIZE					=	8;
	  export const MAHU_INT128_SIZE					=	16;
	  export const MAHU_INT256_SIZE					=	32;

	  export const MAHU_INT16_TAIL					=	MAHU_INT16_SIZE-1;
	  export const MAHU_INT32_TAIL					=	MAHU_INT32_SIZE-1;
	  export const MAHU_INT64_TAIL					=	MAHU_INT64_SIZE-1;
	  export const MAHU_INT128_TAIL					=	MAHU_INT128_SIZE-1;
	  export const MAHU_INT256_TAIL					=	MAHU_INT256_SIZE-1;

	  export const MAHU_INT16_MASK					=	~MAHU_INT16_TAIL;
	  export const MAHU_INT32_MASK					=	~MAHU_INT32_TAIL;
	  export const MAHU_INT64_MASK					=	~MAHU_INT64_TAIL;
	  export const MAHU_INT128_MASK					=	~MAHU_INT128_TAIL;
	  export const MAHU_INT256_MASK					=	~MAHU_INT256_TAIL;

	  export const MAHU_INT32_HEXCODE_SIZE			=	MAHU_INT32_SIZE*2;
	  export const MAHU_INT64_HEXCODE_SIZE			=	MAHU_INT64_SIZE*2;
	  export const MAHU_INT128_HEXCODE_SIZE			=	MAHU_INT128_SIZE*2;
	  export const MAHU_INT256_HEXCODE_SIZE			=	MAHU_INT256_SIZE*2;

	  export const MAHU_STRING_MAX_SIZE					=	0X80;

	  export const MAHU_U8_RATE_MAX						=	0XFF;

	  export const MAHU_INT32_NEG_FLAG=  0XFFFF0000;
	  export const MAHU_INT16_NEG_MASK=  0X00008000;

	  export const	MAHU_FILE_ID_SIZE				=0x4;

	  export const	MAHU_FILE_ID_MIN				=0x00000100;

	  export const	MAHU_FILE_ID_MAX				=0x3FFFFF00;
	  export const	MAHU_IMAGE_ID_MIN				=0x40000100;
	  export const	MAHU_IMAGE_ID_MAX				=0x6FFFFF00;

	  export const	MAHU_BORAD_CAST_ID				=0xFFFFFFFF;

	  export const	MAHU_FLOWER_SERVER_ID_DEFAULT	=0x800000F0;


	  export const	MAHU_LIGHT_ID_MIN				=0x00010000;
	  export const	MAHU_LIGHT_ID_MAX				=0x3FFFFF00;
	  export const	MAHU_LIGHT_ID_NULL				=(0);

	  export const	MAHU_USER_ID_NEW				=0x4000FFFF;
	  export const	MAHU_USER_ID_MIN				=0x40010000;
	  export const	MAHU_USER_ID_MAX				=0x7FFFFF00;
	  export const	MAHU_USER_ID_NULL				=(0);

	  export const	MAHU_FILE_FLASH_ID_MASK			=0x70000000;
	  export const	MAHU_FILE_FLASH_ID_FLAG			=0x70000000;
	  export const	MAHU_FILE_FLASH_SIZE_MASK		=0x0FFF0000;
	  export const	MAHU_FILE_FLASH_ID_MIN			=0x70000000;
	  export const	MAHU_FILE_FLASH_ID_MAX			=0x7FFFFFFF;



	  export const	MAHU_FILE_INT16_MAX				=0xFF00;


	  export const MAHU_MAC_SIZE 				= 	6;
	  export const MAHU_ID_SIZE					=	4;
	  export const MAHU_PORT_SIZE				=	2;
	  export const MAHU_IP_SIZE	  				=	4;
	  export const	MAHU_CMD_SIZE				=	0X002;
	  export const	MAHU_CMD_LENGTH_SIZE		=	0X002;
	  export const	MAHU_CMD_ADDR_SIZE			=	0X002;
	  export const	MAHU_CMD_CRC_SIZE			=	0X002;
	  export const 	MAHU_LONG_SYSTEM_TIME_SIZE	=(8);

	  export const 	MAHU_CMD_EXT_SIZE					=0X02;
	  export const 	MAHU_CMD_EXT_ACK					=0X80;
	  export const 	MAHU_CMD_EXT_MASK					=0X7F;

	  export const	MAHU_CMD_DST_MAC_OFFSET				=0;
	  export const	MAHU_CMD_SRC_MAC_OFFSET				=(MAHU_CMD_DST_MAC_OFFSET+MAHU_MAC_SIZE);
	  export const	MAHU_CMD_CMD_OFFSET					=(MAHU_CMD_SRC_MAC_OFFSET+MAHU_MAC_SIZE);
	  export const	MAHU_CMD_ADDR_OFFSET				=(MAHU_CMD_CMD_OFFSET+MAHU_CMD_SIZE);
	  export const	MAHU_CMD_WRITE_LENGTH_OFFSET		=(MAHU_CMD_ADDR_OFFSET+MAHU_CMD_ADDR_SIZE);
	  export const	MAHU_CMD_READ_LENGTH_OFFSET			=(MAHU_CMD_WRITE_LENGTH_OFFSET+MAHU_CMD_LENGTH_SIZE);

	  export const	 MAHU_CMD_CRC_OFFSET				=  (MAHU_CMD_READ_LENGTH_OFFSET+MAHU_CMD_LENGTH_SIZE);
		export const	MAHU_CMD_DATA_OFFSET				=(MAHU_CMD_CRC_OFFSET+MAHU_CMD_CRC_SIZE);
	  export const	MAHU_CMD_HEADER_SIZE				=	(MAHU_MAC_SIZE*2+MAHU_CMD_SIZE+MAHU_CMD_ADDR_SIZE+MAHU_CMD_ADDR_SIZE*2+MAHU_CMD_CRC_SIZE);
	  export const	MAHU_CMD_DATA_SIZE					=	0x100;

	 export const RF_COM_BUF_SIZE	=		0X40;//	(MAHU_CMD_HEADER_SIZE +RF_COM_DATA_SIZE)
	 export const RF_COM_PACKET_SIZE_MIN	=2;//	 (MAHU_CMD_HEADER_SIZE*2)
	 export const  		RF_COM_CODE_FLAG_SIZE  =6;
	 export const RF_COM_PACKET_SIZE_MAX=RF_COM_BUF_SIZE+RF_COM_CODE_FLAG_SIZE+RF_COM_CODE_FLAG_SIZE;//	 (RF_COM_BUF_SIZE*2)

	  export const	MAHU_FILE_ID_ALL			=(0XFFFFFFF0);
	  export const	MAHU_FILE_ID_NULL			=(0);
	  export const	MAHU_CMD_ADDR_NULL			=(0XFFFF);
	  export const	MAHU_CMD_ADDR_MAX			=(0XFFF0);
	  export const	MAHU_CMD_ADDR_START			=(0XFFF1);
	  export const	MAHU_CMD_ADDR_END			=(0XFFF2);

	  export const	MAHU_NAME_SIZE 				= 	32;
	 export const MAHU_TIME_SIZE				=	24;
	 export const MAHU_FILE_NUM					=	16;
	 //export const MAHU_FILE_SIZE				=	0X100;
	  //export const	MAHU_FILE_EPROM_SIZE			= 0X100;


	  export const	MAHU_USER_IP_BUF_SIZE=0X100;
	  export const	MAHU_IP_BUF_SIZE=0X400;



	  export const MAHU_BUF_SIZE=0X580;//1280;
	  export const MAHU_SERVER_BUF_SIZE=0X1000;//1280;
	  export const MAHU_CMD_LENGTH_NEOF=0X8000;
	  export const MAHU_CAMER_SEG_BUF_SIZE=0X8080;//1280;


	  export const	MAHU_MES_SIZE	=(MAHU_ID_SIZE+MAHU_MAC_SIZE*2+MAHU_IP_SIZE*3+MAHU_PORT_SIZE*2+MAHU_NAME_SIZE*2);


	  export const	MAHU_MES_SQL_SIZE	=(MAHU_MES_SIZE+MAHU_TIME_SIZE);


	  export const MAHU_WEB_SERVER_HOST=	"www.mahuled.com";//"moons.imwork.net";// "192.168.9.200";
	  export const    MAHU_WEB_SERVER_HOST_IP=(103<<24)|(44<<16)|(124<<8)|288;
	  export const    MAHU_LOCAL_SERVER_HOST_IP=(127<<24)|(0<<16)|(0<<8)|1;//127.0.0.1
	  export const    MAHU_WEB_SERVER_PORT=	80;//"moons.imwork.net";// "192.168.9.200";

	  export const MAHU_SERVER_IP=	"localhost";//"moons.imwork.net";// "192.168.9.200";
	  export const MAHU_LIGHT_SERVER_IP="localhost";// 103.44.124.288;//"192.168.9.200";
	  export const MAHU_USER_SERVER_IP= "localhost";//"192.168.9.200";
	  export const MAHU_DOWN_SERVER_IP= "localhost";//"192.168.9.200";
	  export const MAHU_BATS_ELF_DEFENSE_SERVER_IP= "localhost";//"192.168.9.200";
	  export const MAHU_CAMERS_ELF_DEFENSE_SERVER_IP= "localhost";//"192.168.9.200";


	  export const MAHU_SERVER_PORT					=	30009;
	  export const	MAHU_SERVER_WEB_SOCKET_PORT		=	80;
	  export const	MAHU_LIGHT_SERVER_PORT			=	30009;
	  export const	MAHU_DOWN_SERVER_PORT			=	30010;
	  export const	MAHU_CAMERS_SERVER_PORT			=	30100;
	  export const	MAHU_FLOWER_WATER_SERVER_PORT	=	30101;
	  export const	MAHU_BATS_SERVER_PORT			=	30102;

	  export const	MAHU_BATS_ELE_DEFENSE_SERVER_PORT		=	30103;
	  export const	MAHU_CAMERS_ELE_DEFENSE_SERVER_PORT		=	30104;
	  export const	MAHU_FLOWER_AIR_ELE_DEFENSE_SERVER_PORT	=	30105;
	  export const	MAHU_TEMPERATURE_ELE_DEFENSE_SERVER_PORT=	30106;
	  export const	MAHU_DIST_VOICE_ELE_DEFENSE_SERVER_PORT=	30107;
	  export const	MAHU_LOCKER_DEBUG_ELE_DEFENSE_SERVER_PORT=	30108;
	  export const	MAHU_SI_BAT_ELE_DEFENSE_SERVER_PORT		 =	30109;









	  export const	MAHU_ELE_DEFENSE_TIME_OUT		=	100*1000;

	  export const	MAHU_NODE_TIME_OUT				=	10*1000;
	  export const	MAHU_LISTENER_TIME_OUT			=	10*1000;
	  export const	MAHU_WEB_VIEW_TIME_OUT			=	10*1000;
	  export const	MAHU_CAMERS_NODE_ID_DEFAULT=0X11;
	  //export const	MAHU_BATS_ELE_DEFENSE_PORT_DEFAULT		=	30104;

	  export const MAHU_DATA_SERVER_ID				=	0X80000001;

	  export const MAHU_NET_IP_LOCAL				=	0X7F000001;
	  export const MAHU_NET_PORT_LOCAL					=	30000;
	  export const MAHU_NET_IP_NULL					=	0X00000000;
	  export const MAHU_NET_PORT_NULL					=	0;


	  export const	MAHU_CMD_NULL  					=0X000;


	  export const	MAHU_CMD_OK					=	0X00;
	  export const	MAHU_CMD_STATE_CRC_ERROR			=	0X01;
	  export const	MAHU_CMD_STATE_TIME_OUT			=	0X02;
	  export const	MAHU_CMD_STATE				=	0X03;
	  export const 	MAHU_CMD_ACK				=	0X8000;
	  export const	  MAHU_CMD_MASK				=	0X7FFF;

		export const	  MAHU_CMD_FLAG					=  0x0000;

	  export const	  MAHU_CMD_NODE	 				= (MAHU_CMD_FLAG|0X0010);
	  export const	  MAHU_CMD_ELF	 				= (MAHU_CMD_FLAG|0X0011);
	  export const	  MAHU_CMD_SQL	 				= (MAHU_CMD_FLAG|0X0012);
	  export const	  MAHU_CMD_WEB	 				= (MAHU_CMD_FLAG|0X0013);

	  export const	  MAHU_CMD_UART	 				= (MAHU_CMD_FLAG|0X0021);
	  export const	  MAHU_CMD_RF	 				= (MAHU_CMD_FLAG|0X0022);

	  export const	  MAHU_CMD_JSON	 				= (MAHU_CMD_FLAG|0X0031);

	  export const	MAHU_SQL_HEADER_SIZE		=	MAHU_CMD_HEADER_SIZE;
	  export const	MAHU_SQL_FLAG					   =0x0100;
	  export const	MAHU_CMD_SQL_SEARCH 				=(MAHU_SQL_FLAG|0X0001);
	  export const	MAHU_CMD_SQL_INSERT 				=(MAHU_SQL_FLAG|0X0002);
	  export const	MAHU_CMD_SQL_UPDATE 				=(MAHU_SQL_FLAG|0X0003);
	  export const	MAHU_CMD_SQL_CLOSE  				=(MAHU_SQL_FLAG|0X0004);

	  export const	MAHU_CMD_SQL_SEARCH_REST_TYPE_INT32		=(0X0001);
	  export const	MAHU_CMD_SQL_SEARCH_REST_TYPE_STRING	=(0X0002);
	  export const	MAHU_CMD_SQL_SEARCH_REST_SIZE		=(0X0200);

	  export const	MAHU_SQL_SERVER_CMD_TYPE_SQL_INSERT	=(MAHU_SQL_FLAG | 0X0010);
	  export const	MAHU_SQL_SERVER_CMD_TYPE_SQL_SEARCH	=(MAHU_SQL_FLAG | 0X0020);
	  export const	MAHU_SQL_SERVER_CMD_TYPE_SRC		=(MAHU_SQL_FLAG | 0X20);
	  export const	MAHU_SQL_SERVER_CMD_SIZE			=(MAHU_SQL_FLAG | 12);
	  export const	MAHU_SQL_SERVER_CMD_NULL 			=(MAHU_SQL_FLAG | 0);

	  export const	MAHU_SQL_TYPE_STATE					=(MAHU_SQL_FLAG | 0X0010);
	  export const	MAHU_SQL_TYPE_MES  					=(MAHU_SQL_FLAG | 0X0020);
	  export const	MAHU_STATE_MES_PUT  				=(MAHU_SQL_FLAG | 0X0021);
	  export const	MAHU_STATE_MES_GET  				=(MAHU_SQL_FLAG | 0X0022);
	  export const	MAHU_SQL_TYPE_CHAT_ROOM_MES  		=(MAHU_SQL_FLAG | 0X0030);
	  export const	MAHU_SQL_TYPE_DRAW_MES  			=(MAHU_SQL_FLAG | 0X0040);
	  export const	MAHU_SQL_TYPE_IMAGE_MES				=(MAHU_SQL_FLAG | 0X0050);
	  export const	MAHU_SQL_TYPE_LED_SETUP_DEMO_MES	=(MAHU_SQL_FLAG | 0X0060);
	  export const	MAHU_SQL_TYPE_TXT_STORY				=(MAHU_SQL_FLAG | 0X0070);
	  export const	MAHU_SQL_TYPE_USER					=(MAHU_SQL_FLAG | 0X0080);
	  export const	MAHU_SQL_TYPE_VIDEO					=(MAHU_SQL_FLAG | 0X0090);
	  export const	MAHU_SQL_TYPE_WEB_ADVERT			=(MAHU_SQL_FLAG | 0X00A0);
	  export const	MAHU_SQL_TYPE_WEB_ROTBOT			=(MAHU_SQL_FLAG | 0X00B0);
	  export const	MAHU_SQL_TYPE_WEB_SALE				=(MAHU_SQL_FLAG | 0X00C0);
	  export const	MAHU_SQL_TYPE_SETALL				=(MAHU_SQL_FLAG | 0X00D0);

	  export const	MAHU_SQL_CMD_LOG_SEARCH				=(MAHU_SQL_FLAG | 0X00E0);
	  export const	MAHU_SQL_CMD_LOG_WRITE				=(MAHU_SQL_FLAG | 0X00E1);
	  export const	MAHU_SQL_CMD_LOG_SEARCH_CMD_ALL		=0XFFFF;
	  export const	MAHU_SQL_CMD_LOG_SEARCH_TIME_ALL	=0XFFFF;

	  export const	MAHU_LIGHT_FILE_FLAG					=0X700;

	  export const	MAHU_LIGHT_FILE_WRITE_STATE_SEARCH		=(MAHU_LIGHT_FILE_FLAG | 0X0001);
	//  export const	MAHU_LIGHT_FILE_WRITE_STATE_INSERT		=(MAHU_LIGHT_FILE_FLAG | 0X0002);
	//  export const	MAHU_LIGHT_FILE_WRITE_STATE_UPDATE		=(MAHU_LIGHT_FILE_FLAG | 0X0003);
	  export const	MAHU_LIGHT_FILE_WRITE_STATE_DELETE		=(MAHU_LIGHT_FILE_FLAG | 0X0004);
	  export const	MAHU_LIGHT_FILE_WRITE_STATE_FILEID		=(MAHU_LIGHT_FILE_FLAG | 0X0005);

	  export const	MAHU_LIGHT_FILE_WRITE_STATE_SET		=(MAHU_LIGHT_FILE_FLAG | 0X0006 );


	  export const	MAHU_LIGHT_FILE_CHECK_STATE_SEARCH		=(MAHU_LIGHT_FILE_FLAG | 0X0008);
//	  export const	MAHU_LIGHT_FILE_CHECK_STATE_INSERT		=(MAHU_LIGHT_FILE_FLAG | 0X0009);
//	  export const	MAHU_LIGHT_FILE_CHECK_STATE_UPDATE		=(MAHU_LIGHT_FILE_FLAG | 0X000A);
	  export const	MAHU_LIGHT_FILE_CHECK_STATE_DELETE		=(MAHU_LIGHT_FILE_FLAG | 0X000B);
	  export const	MAHU_LIGHT_FILE_CHECK_STATE_FILEID		=(MAHU_LIGHT_FILE_FLAG | 0X000C);
	  export const	MAHU_LIGHT_FILE_CHECK_STATE_SET		=(MAHU_LIGHT_FILE_FLAG | 0X000D );
	  export const	MAHU_LIGHT_FILE_CHECK_STATE				=(MAHU_LIGHT_FILE_CHECK_STATE_SEARCH );


	  export const	MAHU_LIGHT_FILE_WRITE					=(MAHU_LIGHT_FILE_FLAG | 0X0010);
	  export const	MAHU_LIGHT_FILE_READ					=(MAHU_LIGHT_FILE_FLAG | 0X0011);
	  export const	MAHU_LIGHT_FILE_CREATE					=(MAHU_LIGHT_FILE_FLAG | 0X0012);
	  export const	MAHU_LIGHT_FILE_DELETE					=(MAHU_LIGHT_FILE_FLAG | 0X0013);




	  export const	MAHU_LIGHT_FILE_LOG_GET					=(MAHU_LIGHT_FILE_FLAG | 0X0018);
	  export const	MAHU_LIGHT_FILE_LOG_SET					=(MAHU_LIGHT_FILE_FLAG | 0X0019);

	  export const	MAHU_LIGHT_IMAGE_CREATE					=(MAHU_LIGHT_FILE_FLAG | 0X0040);
	  export const	MAHU_LIGHT_IMAGE_DELETE					=(MAHU_LIGHT_FILE_FLAG | 0X0041);
	  export const	MAHU_LIGHT_IMAGE_WRITE					=(MAHU_LIGHT_FILE_FLAG | 0X0042);
	  export const	MAHU_LIGHT_IMAGE_READ					=(MAHU_LIGHT_FILE_FLAG | 0X0043);
	  export const	MAHU_LIGHT_IMAGE_TO_DISK_FILE			=(MAHU_LIGHT_FILE_FLAG | 0X0044);
	  export const	MAHU_LIGHT_IMAGE_GET					=(MAHU_LIGHT_FILE_FLAG | 0X0045);

	  export const	MAHU_LIGHT_IMAGE_WRITE_STATE_SEARCH		=(MAHU_LIGHT_FILE_FLAG | 0X0048);
	  export const	MAHU_LIGHT_IMAGE_WRITE_STATE_DELETE		=(MAHU_LIGHT_FILE_FLAG | 0X0049);
	  export const	MAHU_LIGHT_IMAGE_WRITE_STATE_FILEID		=(MAHU_LIGHT_FILE_FLAG | 0X004A);
	  export const	MAHU_LIGHT_IMAGE_WRITE_STATE_SET		=(MAHU_LIGHT_FILE_FLAG | 0X004B );



	//  export const	MAHU_SQL_FILE_SET						=(MAHU_LIGHT_FILE_FLAG | 0X0080);
	//  export const	MAHU_SQL_FILE_GET						=(MAHU_LIGHT_FILE_FLAG | 0X0081);
	//  export const	MAHU_SQL_FILE_READ						=(MAHU_LIGHT_FILE_FLAG | 0X0082);
	//  export const	MAHU_SQL_FILE_WRITE						=(MAHU_LIGHT_FILE_FLAG | 0X0083);



	  export const	MAHU_LIGHT_FILE_ID_MAX				=(0XFFF0);
//	  export const	MAHU_SQL_ACTION_LED_STATE_SEARCH  =0X0E0;

//	  export const	MAHU_SQL_TYPE_LED=0X10;
//	  export const	MAHU_SQL_TYPE_LED_SIZE=0X08;










		  export const 	MAHU_CMD_WEB_FLAG							=0X0400;
		  export const 	MAHU_WEB_SYSTEM_FILE_REFRESH				=(0x01 | MAHU_CMD_WEB_FLAG);

		  export const	  MAHU_CMD_WEB_CHECK_VCC					=(0X11 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_CPU					=(0X12 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_OSC					=(0X13 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_EXT_FLASH				=(0X14 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_EXT_FLASH_ID			=0X461F;
		  export const	  MAHU_CMD_WEB_CHECK_WIFI					=(0X15 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_TEMPOR					=(0X16 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_PIR					=(0X17 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_LUX					=(0X18 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_TFT					=(0X19 |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_LED					=(0X1A |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_CHECK_FAN					=(0X1B |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_MODE_SERIAL					=(0X1C |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_MODE_SPI						=(0X1D |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_MODE_OSC						=(0X1E |MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_OSC_STATE					=(0X1F |MAHU_CMD_WEB_FLAG);

		  export const	  MAHU_CMD_WEB_IDLE_CPU						=(0X20|MAHU_CMD_WEB_FLAG);


		  export const	  MAHU_CMD_WEB_RESET_CPU					=(0X21|MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_READ_CPU_FOUSE				=(0X22|MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_WEB_WRITE_CPU_FOUSE				=(0X23|MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_CPU_FOUSE_SIZE					=(0X04);

		  export const	  MAHU_CMD_LIGHT_MAC_GET					=(0X24|MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_LIGHT_MAC_SET					=(0X25|MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_LIGHT_MAC_CLR					=(0X26|MAHU_CMD_WEB_FLAG);
		  export const	  MAHU_CMD_LIGHT_MAC_NEW					=(0X27|MAHU_CMD_WEB_FLAG);

		  export const 	MAHU_CMD_WEB_CHECK_VOICE_DIST				=(0X28|MAHU_CMD_WEB_FLAG) ;
		  export const 	MAHU_CMD_WEB_MOTO_STEP_DIST					=(0X29|MAHU_CMD_WEB_FLAG) ;




		  export const	MAHU_LIGHT_LOG_ON  				=(MAHU_CMD_WEB_FLAG | 0X0030);

		  export const 	MAHU_LIGHT_LOG_OFF 				=(MAHU_CMD_WEB_FLAG | 0X0031);
		  export const 	MAHU_LIGHT_DRAW 				=(MAHU_CMD_WEB_FLAG | 0X0032);
		  export const	MAHU_LIGHT_ROUTE  				=(MAHU_CMD_WEB_FLAG | 0X0033);

		  export const 	MAHU_LIGHT_MES_GET				=(MAHU_CMD_WEB_FLAG | 0X0034);
		  export const 	MAHU_LIGHT_MES_PUT				=(MAHU_CMD_WEB_FLAG | 0X0035);
		  export const 	MAHU_LIGHT_MES_SET				=(MAHU_CMD_WEB_FLAG | 0X0036);
		  export const 	MAHU_LIGHT_SYSTEM_UPDATE		=(MAHU_CMD_WEB_FLAG | 0X0040);
		  export const 	MAHU_LIGHT_SYSTEM_VERSION_GET	=(MAHU_CMD_WEB_FLAG | 0X0041);
		  export const 	MAHU_LIGHT_SYSTEM_VERSION_SET	=(MAHU_CMD_WEB_FLAG | 0X0042);
		  export const 	MAHU_LIGHT_SYSTEM_TIME_SET		=(MAHU_CMD_WEB_FLAG | 0X0043);
		  export const 	MAHU_LIGHT_SYSTEM_TIME_GET		=(MAHU_CMD_WEB_FLAG | 0X0044);

		  export const 	MAHU_LIGHT_SYSTEM_FILE_GET		=(MAHU_CMD_WEB_FLAG | 0X0048);
		  export const 	MAHU_LIGHT_SYSTEM_FILE_PUT		=(MAHU_CMD_WEB_FLAG | 0X0049);
		  export const 	MAHU_LIGHT_CONFIG_FILE_SET		=(MAHU_CMD_WEB_FLAG | 0X004C);
		  export const 	MAHU_LIGHT_CONFIG_FILE_GET		=(MAHU_CMD_WEB_FLAG | 0X004D);
		  export const 	MAHU_LIGHT_SYSTEM_FILE_ID_GET		=(MAHU_CMD_WEB_FLAG | 0X004E);
		  export const 	MAHU_LIGHT_SYSTEM_FILE_ID_SET		=(MAHU_CMD_WEB_FLAG | 0X004F);
		  export const	MAHU_LIGHT_CONFIG_FILE_SET_REFRESH_MASK=0XC000C000;
		  export const	MAHU_LIGHT_CONFIG_FILE_SET_REFRESH_FLAG=0X40008000;


		  export const 	MAHU_LIGHT_CONFIG_FILE_SET_ADDR_SHIRT	=0+16;
		  export const 	MAHU_LIGHT_CONFIG_FILE_SET_LENGTH_SHIRT	=0;
		  export const 	MAHU_LIGHT_CONFIG_FILE_SET_ADDR_MASK	=(0X0FFF);
		  export const 	MAHU_LIGHT_CONFIG_FILE_SET_LENGTH_MASK	=(0X0FFF);


		  export const 	MAHU_CMD_WEB_IMAGE_PUT			=(MAHU_CMD_WEB_FLAG | 0X0050);
		  export const 	MAHU_CMD_SERVER_DATA_IMAGE_PUT	=(MAHU_CMD_WEB_FLAG | 0X0051);
		  export const 	MAHU_CMD_SERVER_FILE_IMAGE_PUT	=(MAHU_CMD_WEB_FLAG | 0X0052);
		  export const 	MAHU_CMD_LIGHT_IMAGE_PUT		=(MAHU_CMD_WEB_FLAG | 0X0053);

		  export const 	MAHU_WEB_CONFIG_FILE_SET		=(MAHU_CMD_WEB_FLAG | 0X0054);
		  export const 	MAHU_WEB_CONFIG_FILE_GET		=(MAHU_CMD_WEB_FLAG | 0X0055);

		  export const 	MAHU_CONFIG_FILE_SET		=(MAHU_CMD_WEB_FLAG | 0X0056);
		  export const 	MAHU_CONFIG_FILE_GET		=(MAHU_CMD_WEB_FLAG | 0X0057);



		  export const 	MAHU_CMD_SERVER_DATA_IMAGE_GET	=(MAHU_CMD_WEB_FLAG | 0X0059);
		  export const 	MAHU_CMD_SERVER_FILE_IMAGE_GET	=(MAHU_CMD_WEB_FLAG | 0X005A);
		  export const 	MAHU_CMD_WEB_IMAGE_GET			=(MAHU_CMD_WEB_FLAG | 0X005B);

		  export const 	MAHU_CMD_WEB_IMAGE_PNG_GET			=(MAHU_CMD_WEB_FLAG | 0X005C);
		  export const 	MAHU_CMD_WEB_IMAGE_PNG_PUT			=(MAHU_CMD_WEB_FLAG | 0X005D);


		  export const 	MAHU_CMD_WEB_ICON_PUT			=(MAHU_CMD_WEB_FLAG | 0X0060);
		  export const 	MAHU_CMD_SERVER_DATA_ICON_PUT	=(MAHU_CMD_WEB_FLAG | 0X0061);
		  export const 	MAHU_CMD_SERVER_FILE_ICON_PUT	=(MAHU_CMD_WEB_FLAG | 0X0062);
		  export const 	MAHU_CMD_LIGHT_ICON_PUT			=(MAHU_CMD_WEB_FLAG | 0X0063);

		  export const 	MAHU_CMD_WEB_ICON_GET			=(MAHU_CMD_WEB_FLAG | 0X0068);
		  export const 	MAHU_CMD_SERVER_DATA_ICON_GET	=(MAHU_CMD_WEB_FLAG | 0X0069);
		  export const 	MAHU_CMD_SERVER_FILE_ICON_GET	=(MAHU_CMD_WEB_FLAG | 0X006A);
		  export const 	MAHU_CMD_LIGHT_ICON_GET			=(MAHU_CMD_WEB_FLAG | 0X006B);
		  export const 	MAHU_CMD_WEB_ICON				=MAHU_CMD_WEB_ICON_PUT;

		  export const 	MAHU_CMD_WEB_SYSTEM_FILE_UPLOAD	=(MAHU_CMD_WEB_FLAG | 0X006C);
		  export const 	MAHU_CMD_WEB_USER_LOCAL_FILE_UPLOAD	=(MAHU_CMD_WEB_FLAG | 0X006D);
		  export const 	MAHU_CMD_WEB_USER_URL_FILE_UPLOAD	=(MAHU_CMD_WEB_FLAG | 0X006E);


		  export const 	MAHU_WEB_FILE_ID_SEARCH			=(MAHU_CMD_WEB_FLAG | 0X0070);
		  export const 	MAHU_WEB_FILE_GET				=(MAHU_CMD_WEB_FLAG | 0X0071);
		  export const	MAHU_WEB_FILE_CREATE			=(MAHU_CMD_WEB_FLAG | 0X0072);
		  export const	MAHU_WEB_FILE_UPDATE			=(MAHU_CMD_WEB_FLAG | 0X0073);
		  export const 	MAHU_WEB_FILE_DELETE			=(MAHU_CMD_WEB_FLAG | 0X0074);
		  export const 	MAHU_WEB_FILE_WRITE				=(MAHU_CMD_WEB_FLAG | 0X0074);
		  export const 	MAHU_WEB_FILE_READ				=(MAHU_CMD_WEB_FLAG | 0X0076);

		  export const 	MAHU_WEB_IMAGE_ID_SEARCH		=(MAHU_CMD_WEB_FLAG | 0X0078);
		  export const 	MAHU_WEB_IMAGE_GET				=(MAHU_CMD_WEB_FLAG | 0X0079);
		  export const 	MAHU_WEB_IMAGE_SEARCH			=(MAHU_CMD_WEB_FLAG | 0X007A);
		  export const 	MAHU_WEB_IMAGE_DELETE			=(MAHU_CMD_WEB_FLAG | 0X007B);
		  export const 	MAHU_WEB_IMAGE_WRITE			=(MAHU_CMD_WEB_FLAG | 0X007C);
		  export const 	MAHU_WEB_IMAGE_READ				=(MAHU_CMD_WEB_FLAG | 0X007D);
	//	  let 	MAHU_WEB_LASER_SCAN_MODEL		=(MAHU_CMD_WEB_FLAG | 0X007E);
	//	  let 	MAHU_WEB_LASER_SCAN_MODEL_OPEN=0X01;
	//	  let 	MAHU_WEB_LASER_SCAN_MODEL_MOVE=0X02;


			export const 	MAHU_LIGHT				=(MAHU_CMD_WEB_FLAG | 0X0080);
		  export const 	MAHU_LIGHT_AUTO			=0XF1;
		  export const 	MAHU_LIGHT_ON				=0XF8;
		  export const 	MAHU_LIGHT_ONN			=MAHU_LIGHT_ON;
		  export const 	MAHU_LIGHT_LOW			=0X40;
		  export const 	MAHU_LIGHT_OFF			=0x00;
			export const 	MAHU_CURRENT_ZERO			 =0X04;
			export const 	MAHU_CURRENT_REST			 =0X06;
		  export const 	MAHU_LIGHT_MUTE			=0x40;
		  export const 	MAHU_LIGHT_FAN					=(MAHU_CMD_WEB_FLAG | 0X0081);
		  export const 	MAHU_LIGHT_FAN_AUTO				=0XF1;
		  export const 	MAHU_LIGHT_FAN_ON				=0XF0;
		  export const 	MAHU_LIGHT_FAN_ONN				=MAHU_LIGHT_FAN_ON;
		  export const 	MAHU_LIGHT_FAN_OFF				=0X00;
		  export const 	MAHU_LIGHT_FAN_SLIENCE				=0X40;

		  export const 	MAHU_LIGHT_CLOSE			=	 0X80;
		  export const 	MAHU_LIGHT_CHECK			=	 0X82;
		  export const 	MAHU_LIGHT_NEG				=	 0X84;
		  export const 	MAHU_LIGHT_HIGH				=	 0XE8;
	//	  export const 	MAHU_LIGHT_LOW				= 	 0X18;

		  export const 	MAHU_LIGHT_WAKE			 =	( 0X00A8);
		  export const 	MAHU_LIGHT_RUN			 =	(0XAC);
		  export const 	MAHU_LIGHT_IDLE			 =	( 0X00A9);
		  export const 	MAHU_LIGHT_SLEEP		 =	( 0X00AA);
		  export const 	MAHU_LIGHT_DEEP_SLEEP	=	( 0X00AB);

		  export const 	MAHU_LIGHT_ERROR		=	( 0X00EE);


		  export const 	MAHU_LIGHT_TEMP					=(MAHU_CMD_WEB_FLAG | 0X0082);

		  export const 	MAHU_LIGHT_PIR					=(MAHU_CMD_WEB_FLAG | 0X0083);
		  export const 	MAHU_LIGHT_PIR_DIR_LEFT			=0X01;
		  export const 	MAHU_LIGHT_PIR_DIR_RIGHT		=0X02;
		  export const 	MAHU_LIGHT_MIC					=(MAHU_CMD_WEB_FLAG | 0X0084);

		  export const 	MAHU_LIGHT_CAMER				=(MAHU_CMD_WEB_FLAG | 0X0085);
		  export const 	MAHU_LIGHT_CAMER_WRITE			=(0X01);
		  export const 	MAHU_LIGHT_CAMER_READ			=(0X02);

		  export const 	MAHU_CMD_LASER_PUT				=(MAHU_CMD_WEB_FLAG | 0X0090);
		  export const 	MAHU_CMD_LASER_MOVE				=(MAHU_CMD_WEB_FLAG | 0X0091);
		  export const 	MAHU_CMD_LASER_DIST				=(MAHU_CMD_WEB_FLAG | 0X0092);
		  export const 	MAHU_CMD_USER_LIGHT				=(MAHU_CMD_WEB_FLAG | 0X0093);
		  export const 	MAHU_CMD_LASER_LINE_DRAW		=(MAHU_CMD_WEB_FLAG | 0X0094);
		  export const 	MAHU_CMD_LASER_ROOM_DRAW		=(MAHU_CMD_WEB_FLAG | 0X0095);
		  export const 	MAHU_CMD_LIGHT_NET				=(MAHU_CMD_WEB_FLAG | 0X00A0);
		  export const 	MAHU_CMD_LIGHT_CONNECT			=(MAHU_CMD_WEB_FLAG | 0X00A1);
		  export const 	MAHU_CMD_LIGHT_CONNECT_WRITE	=(1);
		  export const 	MAHU_CMD_LIGHT_CONNECT_READ		=(0);

		  export const 	MAHU_CMD_LIGHT_CONNECT_CLOSE	=	0X20;
		  export const 	MAHU_CMD_LIGHT_CONNECT_DELAY	=	0XF0;
		  export const 	MAHU_CMD_LIGHT_CONNECT_LIGHT	 =  0XFF;
		  export const 	MAHU_CMD_LIGHT_CONNECT_WEB		=	0XFE;
		  export const 	MAHU_CMD_LIGHT_CONNECT_SERVER	=   0XFD;
		  //net State: flag(1byte)  num(1byte) data

		  export const 	MAHU_CMD_LIGHT_NET_LIGHT_ID					=0X01;
		  export const 	 	MAHU_CMD_LIGHT_NET_LOCAL_IP				=0X02 ;
		  export const 	 	MAHU_CMD_LIGHT_NET_LOCAL_PORT			=0X03;
		  export const 	 	MAHU_CMD_LIGHT_NET_LOCAL_WEB_IP			=0X04;
		  export const 	 	MAHU_CMD_LIGHT_NET_LOCAL_WEB_PORT		=0X05;
		  export const 	 	MAHU_CMD_LIGHT_NET_SERVER_ID			=0X06;
		  export const 	 	MAHU_CMD_LIGHT_NET_SERVER_IP			=0X07;
		  export const 	 	MAHU_CMD_LIGHT_NET_SERVER_PORT			=8;
		  export const 	 	MAHU_CMD_LIGHT_NET_SERVER_NAME			=9;
		  export const 	 	MAHU_CMD_LIGHT_NET_WEB_USER_ID			=10;
		  export const 	 	MAHU_CMD_LIGHT_NET_LISTEN_PORT			=14;
		  export const 	 	MAHU_CMD_LIGHT_NET_LISTEN_USER_ID		=15	;
		  export const 	 	MAHU_CMD_LIGHT_NET_LISTEN_USER_IP		=16;
		  export const 	 	MAHU_CMD_LIGHT_NET_LISTEN_USER_PORT			=17;
		  export const 	 	MAHU_CMD_LIGHT_NET_LISTEN_USER_LOGON_TIME	=18;
		  export const 	 	MAHU_CMD_LIGHT_NET_LISTEN_USER_LOGOFF_TIME	=19;
		  export const 	 	MAHU_CMD_LIGHT_NET_ROUTE_IP					=21;
		  export const 	 	MAHU_CMD_LIGHT_NET_DISP_FILE_ID					=22;
		  export const 	 	MAHU_CMD_LIGHT_NET_LOG_ON_TIME					=23 ;
		  export const 	 	MAHU_CMD_LIGHT_NET_SSID							=24 ;
		  export const 	 	MAHU_CMD_LIGHT_NET_PASSWORD						=25 ;

		  export const 	MAHU_CMD_WEB_LIGHT_ID				=(MAHU_CMD_WEB_FLAG | MAHU_CMD_LIGHT_NET_LIGHT_ID);
		  export const 	MAHU_CMD_LIGHT_STATE			=(MAHU_CMD_WEB_FLAG | 0X00A1);
		  export const 	MAHU_CMD_LIGHT_STATE_READ		=0X01;
		  export const 	MAHU_CMD_LIGHT_STATE_WRITE		=0X02;
		  export const 	MAHU_CMD_LIGHT_STATE_SIZE		=0X40;

		  export const 	MAHU_LIGHT_DISP_CONFIG			=(MAHU_CMD_WEB_FLAG | 0X00E0);
		  export const 	MAHU_LIGHT_DISP_CONFIG_DIR		=0X01;
		  export const 	MAHU_LIGHT_DISP_CONFIG_CLR		=0X02;
		  export const 	MAHU_LIGHT_DRAW_BMP				=(MAHU_CMD_WEB_FLAG | 0X00E1);
		  export const 	MAHU_LIGHT_DISP_DELAY			=(MAHU_CMD_WEB_FLAG | 0X00E2);

		  export const 	MAHU_LIGHT_DISP_DRAW			=(MAHU_CMD_WEB_FLAG | 0X00F0);
		  export const	MAHU_CMD_WEB_MESSAGE			=( MAHU_CMD_WEB_FLAG |0X00F1 );
		  export const 	MAHU_LIGHT_DISP_LENGTH_MAX		=0XFF00;

		  export const 	 MAHU_LIGHT_DISK				=(MAHU_CMD_WEB_FLAG | 0X00A0);
		  export const 	 MAHU_LIGHT_DISK_READ			=(MAHU_CMD_WEB_FLAG | 0X00A1);
		  export const 	 MAHU_LIGHT_DISK_WRITE			=(MAHU_CMD_WEB_FLAG | 0X00A2);
		  export const 	 MAHU_LIGHT_DISK_EARSE			=(MAHU_CMD_WEB_FLAG | 0X00A3);

		  export const 	 MAHU_LIGHT_FILE_NUM			=(MAHU_CMD_WEB_FLAG | 0X00A4);
		  export const 	 MAHU_LIGHT_FILE_DIR_SIZE		=(MAHU_CMD_WEB_FLAG | 0X00A5);



		  export const	   MAHU_CMD_WEB_READ_CPU_FLASH			=( MAHU_CMD_WEB_FLAG |0XB0 );
		  export const	   MAHU_CMD_WEB_READ_CPU_EPROM			=( MAHU_CMD_WEB_FLAG |0XB1 );
		  export const	   MAHU_CMD_WEB_READ_EXT_FLASH			=( MAHU_CMD_WEB_FLAG |0XB2 );
		  export const	   MAHU_CMD_WEB_CHECK_SEG_FLASH			=( MAHU_CMD_WEB_FLAG |0XB3 );

		  export const	   MAHU_CMD_WEB_WRITE_CPU_FLASH			=( MAHU_CMD_WEB_FLAG |0XB4 );
		  export const	   MAHU_CMD_WEB_WRITE_CPU_EPROM			=( MAHU_CMD_WEB_FLAG |0XB5 );
		  export const	   MAHU_CMD_WEB_WRITE_EXT_FLASH			=( MAHU_CMD_WEB_FLAG |0XB6 );
		  export const	   MAHU_CMD_WEB_MOVE_EXT_FLASH			=( MAHU_CMD_WEB_FLAG |0XB7 );

		  export const	   MAHU_CMD_WEB_EARSE_CPU_FLASH			=( MAHU_CMD_WEB_FLAG |0XB8 );
		  export const	   MAHU_CMD_WEB_EARSE_CPU_EPROM			=( MAHU_CMD_WEB_FLAG |0XB9 );
		  export const	   MAHU_CMD_WEB_EARSE_EXT_FLASH			=( MAHU_CMD_WEB_FLAG |0XBA );

		  export const	   MAHU_CMD_WEB_HELP_CODE				=( MAHU_CMD_WEB_FLAG |0XC0 );
		  export const	   MAHU_CMD_WEB_BOOT_LOAD_CODE			=( MAHU_CMD_WEB_FLAG |0XC1 );
		  export const	   MAHU_CMD_WEB_OSC_CODE				=( MAHU_CMD_WEB_FLAG |0XC2 );
		  export const	   MAHU_CMD_WEB_FILE_SYSTEM				=( MAHU_CMD_WEB_FLAG |0XC3 );

		  export const	   MAHU_LIGHT_CONFIG_SSID				=( MAHU_CMD_WEB_FLAG |0XC4 );


		  export const	   MAHU_CMD_WEB_CONFIG_SYSTEM			=( MAHU_CMD_WEB_FLAG |0X75 );





		  export const	MAHU_USER_LAG 					=0X0500;

		  export const	MAHU_USER_ELF_DEFENSE_ID_SET_FLAG	=( MAHU_USER_LAG |0X0001 );
		  export const	MAHU_USER_ELF_DEFENSE_STATE_OK		=( MAHU_USER_LAG |0X0002 );
		  export const  ELF_DEFENSE_SERVER_CONNECT_NUM		=10;
		  export const  ELF_DEFENSE_RCV_TIME_OUT			=1000*20 ;

		//  export const	MAHU_USER_LOG_ON  				=( MAHU_USER_LAG |0X0010 );
		//  export const 	MAHU_USER_LOG_OFF 				=( MAHU_USER_LAG |0X0020 );


		  export const 	MAHU_USER_DRAW 					=( MAHU_USER_LAG |0X0030 );
		  export const 	MAHU_USER_IMAGE_GET				=( MAHU_USER_LAG |0X0040 );
		  export const 	MAHU_USER_MES_GET				=( MAHU_USER_LAG |0X0050 );
		  export const 	MAHU_USER_MES_PUT				=( MAHU_USER_LAG |0X0051 );
		  export const 	MAHU_USER_MES_SET				=( MAHU_USER_LAG |0X0052 );
		  export const 	MAHU_USER_ID_SERVER		    	=( MAHU_USER_LAG |0X0001 );

		  export const 	MAHU_USER_LIGHT_ID_SET	    	=( MAHU_USER_LAG |0X0060);
		  export const 	MAHU_USER_LIGHT_ID_GET	    	=( MAHU_USER_LAG |0X0061);
		  export const 	MAHU_USER_LIGHT_ID_SEARCH    	=( MAHU_USER_LAG |0X0062);
		  export const 	MAHU_USER_LIGHT_NET_STATE		=( MAHU_USER_LAG |0X0063);
		  export const 	MAHU_CMD_LIGHT_USER_ID_GET		=( MAHU_USER_LAG |0X0064);

		  export const 	MAHU_CMD_LIGHT_USER_IMAGE_ID		=( MAHU_USER_LAG |0X0065);
		  export const 	MAHU_CMD_LIGHT_USER_IMAGE_ID_NEW	=0X000E;
		  export const 	MAHU_CMD_LIGHT_USER_IMAGE_ID_OLD	=0X000F;
		  export const 	MAHU_CMD_LIGHT_USER_IMAGE_ID_CUR	=0X0000;
		  export const 	MAHU_CMD_LIGHT_SERVER_ID_SET		=( MAHU_USER_LAG |0X0066);
		  export const 	MAHU_CMD_LIGHT_USER_ID_SET			=( MAHU_USER_LAG |0X0067);
		  export const 	MAHU_CMD_LIGHT_GROUP_ID_SET			=( MAHU_USER_LAG |0X0068);
		  export const 	MAHU_CMD_LIGHT_GROUP_NODE_ID_SET	=( MAHU_USER_LAG |0X0069);
		  export const 	MAHU_CMD_LIGHT_CUR_NODE_ID_SET	    =( MAHU_USER_LAG |0X006A);

		  export const 	MAHU_CMD_WEB_CAMER_FLAG							=0X0800;

			export const	MAHU_NODE_TYPE_CAMER_FLAG						=0X0000;
			export const MAHU_NODE_TYPE_NODE_FLAG				=(0 );// MAHU_CMD_WEB_FLAG |0X75 );
			export const MAHU_NODE_TYPE_ELF_DEFENSE_ID_FLAG	=(0X70 );// MAHU_CMD_WEB_FLAG |0X75 );
			export const MAHU_NODE_TYPE_CLIENT_ID_FLAG			=(0X71 );// MAHU_CMD_WEB_FLAG |0X75 );
			export const MAHU_NODE_TYPE_WIFI_FLAG				=(0X72 );// MAHU_CMD_WEB_FLAG |0X75 );
			export const MAHU_NODE_TYPE_GROUP_FLAG				=(0X73 );// MAHU_CMD_WEB_FLAG |0X75 );
			export const MAHU_NODE_TYPE_ROUTE_RF_FLAG			=(0X74 );// MAHU_CMD_WEB_FLAG |0X75 );
			export const  MAHU_NODE_TYPE_ROUTE_WIFI_FLAG		=(0X75 );// MAHU_CMD_WEB_FLAG |0X75 );
			export const	MAHU_NODE_TYPE_WEB_VIEW_FLAG				=0X00F0;

		  export const	MAHU_WEB_USER_NUM								=	0X20;
		  export const	MAHU_WEB_NODE_NUM								=	0X20;
		  export const	MAHU_WEB_VIEW_NUM								=	0X20;
		  export const	MAHU_WEB_VIEW_NODE_NUM							=	0X20;



		  export const 	 MAHU_FILE_DIR_START_ADDR				=0X020000;


		  export const 	 MAHU_FILE_SIZE_MAX						=0X050000;

		  export const 	 MAHU_FILE_BLOCK_BIT_NUM				= 0X08;
		  export const 	 MAHU_FILE_BLOCK_SIZE					= 0X100;
		  export const 	 MAHU_FILE_BLOCK_MASK					= (MAHU_FILE_BLOCK_SIZE-1);
		  export const 	 MAHU_FILE_BLOCK_MASK_EXT				=(~(MAHU_FILE_BLOCK_SIZE-1));
		  export const 	 MAHU_FILE_WRITE_BLOCK_SIZE				= MAHU_FILE_BLOCK_SIZE;
		  export const 	 MAHU_FILE_READ_BLOCK_SIZE				= MAHU_FILE_BLOCK_SIZE;

		  export const 	 MAHU_FILE_SYSTEM_BLOCK_SIZE			= (MAHU_FILE_READ_BLOCK_SIZE*2);

		  export const 	 MAHU_FILE_READ_BLOCK_TIME				= 50;
		  export const 	 MAHU_FILE_READ_BLOCK_TIME_OUT			= 50;
		  export const 	 MAHU_FILE_READ_BLOCK_TIME_OUT_NUM		= (MAHU_FILE_READ_BLOCK_TIME_OUT/MAHU_FILE_READ_BLOCK_TIME);


		  export const 	 MAHU_FILE_WRITE_BLOCK_TIME				= 50;
		  export const 	 MAHU_FILE_WRITE_BLOCK_TIME_OUT			= 100;
		  export const 	 MAHU_FILE_WRITE_BLOCK_TIME_OUT_NUM		= (MAHU_FILE_WRITE_BLOCK_TIME_OUT/MAHU_FILE_WRITE_BLOCK_TIME);

		  export const 	 MAHU_FILE_EARSE_BLOCK_TIME				= 50;
		  export const 	 MAHU_FILE_EARSE_BLOCK_TIME_OUT			= 120;
		  export const 	 MAHU_FILE_EARSE_BLOCK_TIME_OUT_NUM		= (MAHU_FILE_EARSE_BLOCK_TIME_OUT/MAHU_FILE_EARSE_BLOCK_TIME);

		  //
		  export const 	 MAHU_FILE_PAT_NAME_SIZE					=0X100;

		  export const 	 MAHU_FILE_NAME_SIZE					=0X08;
		  export const 	 MAHU_FILE_DIR_SIZE						=0X10;
		  export const 	 MAHU_FILE_DIR_NUM						=0X40;
		  export const 	 MAHU_FILE_DIR_ADDR						=0X800;
		  export const 	 MAHU_FILE_DIR_FILE_ADDR_SIZE_SIZE		=0X04;
		  export const 	 MAHU_FILE_DIR_FILE_ID_SIZE				=0X04;
		  export const 	 MAHU_FILE_DIR_FILE_ADDR_MASK			=0XFFF;
		  export const 	 MAHU_FILE_DIR_FILE_SIZE_MASK			=0XFFFFF;//1M
		  export const 	 MAHU_FILE_DIR_FILE_ADDR_BIT_NUM		=12;//4K  1M
		  export const 	 MAHU_FILE_DIR_BUF_SIZE					=(MAHU_FILE_DIR_NUM*MAHU_FILE_DIR_SIZE);
		  export const 	 MAHU_FILE_ID_OFFSET					=(MAHU_FILE_NAME_SIZE+MAHU_FILE_DIR_FILE_ADDR_SIZE_SIZE);

		  																	//0XC00
		  export const 	 MAHU_CONFIG_FILE_DIR_START				=0X0800;
		  export const 	 MAHU_CONFIG_FILE_DIR_SIZE				=(MAHU_FILE_DIR_NUM*MAHU_FILE_DIR_SIZE);

		  //export const 	 MAHU_CONFIG_START						=0X0800;
		  //export const 	 MAHU_CONFIG_SIZE						= (0X0F00-MAHU_CONFIG_START);
		  export const 	 MAHU_CONFIG_ADDR						=0X0000;
		  export const 	 MAHU_CONFIG_SIZE						=0X1000;


		  export const 	 MAHU_FLASH_START_ADDR					=0X080000;
		  export const 	 MAHU_FLASH_SIZE						=0X180000;


		  export const 	 MAHU_FLASH_MAC_ID_ADDR				=0X00000;



		  export const 	 MAHU_FILE_BLOCK_4K_SIZE				=(0X001000);
		  export const 	 MAHU_FILE_BLOCK_32K_SIZE				=(0X008000);
		  export const 	 MAHU_FILE_BLOCK_64K_SIZE				=(0X010000);

		  export const 	 MAHU_FILE_BLOCK_4K_EARSE_TIME			=(300);
		  export const 	 MAHU_FILE_BLOCK_32K_EARSE_TIME			=(700);
		  export const 	 MAHU_FILE_BLOCK_64K_EARSE_TIME			=(1100);

		  export const 	 MAHU_FILE_SEG_SIZE						=(MAHU_FILE_BLOCK_4K_SIZE);
		  export const 	 MAHU_FILE_SEG_MASK						=(MAHU_FILE_SEG_SIZE-1);
		  export const 	 MAHU_FILE_SEG_MASK_EXT					=~MAHU_FILE_SEG_MASK;
		  export const 	 MAHU_FILE_SEG_BIT_SHIRT				=(12);

		  export const 	 MAHU_FILE_SEG_BLOCK_NUM			=   MAHU_FILE_SEG_SIZE/  MAHU_FILE_WRITE_BLOCK_SIZE;
		  export const 	 MAHU_START_ADDR						=(0X000000);
		  export const 	 MAHU_FILE_USER_START_ADDR				=(0X020000+0X1000);
		  export const 	 MAHU_FILE_USER_END_ADDR				=(0X200000);

		  export const 	 MAHU_FILE_SYSTEM_UPDATE_SEG_ADDR		=0X00000;
		  export const 	 MAHU_FILE_SYSTEM_UPDATE_SEG_SIZE		=0X40000;


		  export const 	 MAHU_FILE_SYSTEM_INIT_SEG_ADDR			=0X40000;
		  export const 	 MAHU_FILE_SYSTEM_INIT_SEG_SIZE			=0X20000;

		  export const 	 MAHU_FILE_WIFI_HELP_ADDR				=0X60000;
		  export const 	 MAHU_FILE_WIFI_HELP_SIZE				=0X1000;
		  export const 	 MAHU_FILE_WIFI_FW_ADDR					=(MAHU_FILE_WIFI_HELP_ADDR+MAHU_FILE_SEG_SIZE);
		  export const 	 MAHU_FILE_WIFI_FW_SIZE					=0X1F000;

		  export const 	 MAHU_FILE_DIR_BLOCK_SIZE				=(MAHU_FILE_DIR_SIZE*MAHU_FILE_NUM);


		  export const 	 MAHU_FILE_FLASH_NOVE_BLOCK_TIME_OUT	=100;





		  export const 	 MAHU_STATE_DISK_MAHU_READ		=0X0010;
		  export const 	 MAHU_STATE_DISK_MAHU_WRITE	=0X0011;

		  export const 	 MAHU_STATE_DISK_MAHU_BLOCK_SIZE	=0X0010;

		  export const 	 MAHU_CONIFIG_READ_TIME				= 10;
		  export const 	 MAHU_CONFIG_READ_TIME_OUT			= 40;
		  export const 	 MAHU_CONFIG_READ_TIME_OUT_NUM		= (MAHU_CONFIG_READ_TIME_OUT/MAHU_CONIFIG_READ_TIME);


		  export const 	 MAHU_CONFIG_WRITE_TIME			= 10;
		 // export const 	 MAHU_CONFIG_WRITE_TIME_OUT		= MAHU_STATE_DISK_MAHU_BLOCK_SIZE;
		  export const 	 MAHU_CONFIG_WRITE_TIME_OUT_NUM		= MAHU_STATE_DISK_MAHU_BLOCK_SIZE;//(MAHU_CONFIG_WRITE_TIME_OUT/MAHU_CONFIG_WRITE_TIME);


		  export const		  MAHU_CMD_SEND_RETRY_NUM =5;

		  export const		  MAHU_CMD_WEB_SERVER_CHECK_TIME 	=20;
		  export const		  MAHU_CMD_WEB_SERVER_CHECK_NUM 	=3;
		  export const		  MAHU_CMD_WEB_LIGHT_CHECK_TIME 	=30;
		  export const		  MAHU_CMD_WEB_LIGHT_CHECK_NUM 		=6;
		  export const		  MAHU_CMD_LOCAL_LIGHT_CHECK_TIME 	=15;
		  export const		  MAHU_CMD_LOCAL_LIGHT_CHECK_NUM 	=3;

		  export const		  MAHU_CMD_SEND_TIME_NONE = 0;
		  export const		  MAHU_CMD_SEND_TIME_OUT =1000;
		  export const		  MAHU_CMD_REMOTE_IMAGE_SEND_TIME_OUT = 30;
		  export const		  MAHU_CMD_LOCAL_IMAGE_SEND_TIME_OUT = 30;
		  export const		  MAHU_CMD_LOCAL_SERVER_DATA_TIME_OUT = 80;
		  export const		  MAHU_CMD_LOCAL_SERVER_DATA_WRITE_TIME_OUT = 200;
		  export const		  CMAER_NODE_JPEG_SAVE_TIME_OUT =15000;
		  export const 	 SYSRUNTIMES_ADRESS=	0x02;	//star 0x02   end 0x10
		  export const 	 LEDSTAT_ADDRESS_=	0x10;	//start 0x10  end 0x1C			//开关状态
		  export const 	 LEDSTAT_RGB_ADDRESS_=	LEDSTAT_ADDRESS_+ 10 + 2;//6    //start 0x1C  end 0x62  (7X10)
		  export const 	 SERVERIP_ADDRESS_	=LEDSTAT_RGB_ADDRESS_ + 7 * 10 + 3;//6//start 0x65  end 0x6E  	//服务器IP地址

		  export const 	 PASSWORD_ADDRESS_	=SERVERIP_ADDRESS_ + 9;//10//12//8//start 0x6E  end 0x7A 	//密码
		  export const 	 DEVICEID_ADDRESS_	=PASSWORD_ADDRESS_ + 0x0C;//0x10//start 0x7A  end 0x82	//设备ID（MAC）

		  export const 	 DEVICEID_GROUP_ADDRESS_=	DEVICEID_ADDRESS_ + 0X08 ;//start 0x82  end 0x8A//设备组地址
		  export const 	 DEVICE_DC_ADDRESS_=	DEVICEID_GROUP_ADDRESS_ + 0X08;  //start 0x8A  end 0x9A  //直连方式设备ID


		  export const 	  DEVICESSID_ADDRESS_=				DEVICE_DC_ADDRESS_ + 0x10;//start 0x9A  end 0xBC		//路由器SSID
		  export const 	  DEVICESSID_PASSWORD_ADDRESS_	=DEVICESSID_ADDRESS_ + 0x22;//start 0xBC  end 0xDE	//路由器连接密码
		  export const 	  DEVICESSID_PMKDATA_ADDRESS_	=	DEVICESSID_PASSWORD_ADDRESS_ + 0x22;//start 0xDE  end 0x100	//计算过的PMK密码数据

		  export const 	  DEVICEIP_ADDRESS_	=DEVICESSID_PMKDATA_ADDRESS_ + 0x22;//start 0x100  end 0x108		//设备IP

		  export const 	  SYSDESTINEFLG_ADDRESS_	=DEVICEIP_ADDRESS_ + 0x08;	//	start 0x108  end 0x10A		//系统定时启动标志
		  export const 	  SYSDESTINE_ADDRESS_		=SYSDESTINEFLG_ADDRESS_ + 0x02;//start 0x10A  end 0x24E		//系统定时

		  export const 	  MAXDESTINES		=20;	//系统最大定时条数
		  export const 	  MAXDESTINESLEN	=16;  //单条定时数据长度

		  export const 	  SYS_LEDDEVICE_ADDRESS_	=	SYSDESTINE_ADDRESS_ + MAXDESTINES * MAXDESTINESLEN;		//系统封杀标志

		  export const 	  SYS_LEDDEVICEBMESSAGE_ADDRESS_	=	SYS_LEDDEVICE_ADDRESS_ + 4		;//start 0x24E  end 0x270 //LED系统基础信息

		  export const 	  SYS_SETSSID_SWITCH_ADDRESS_		=SYS_LEDDEVICEBMESSAGE_ADDRESS_ + 34;//start 0x270  end 0x272// 是否开启SSID配置开关 7位关配置功能 其他为开启配置功能

		  export const 	  SYS_DEFAULT_LED_ADDRESS_		=SYS_SETSSID_SWITCH_ADDRESS_ + 2	;	//start 0x272  end 0x27A//设定上电亮度，颜色值

		  export const 	  SYSLOG_ADDRESS_				=SYS_DEFAULT_LED_ADDRESS_ + 8	;//start 0x27A  end 0x28A//系统日志











		  export const	MAHU_DISP_WINDOW_HEIGHT			=240;
		  export const	MAHU_DISP_WINDOW_WIDTH			=240;
		  export const	MAHU_DISP_COLOR_SIZE			=2;
		  export const	MAHU_DISP_COLOR32_SIZE			=4;

		  export const	MAHU_DISP_BUF_SIZE			=(MAHU_DISP_WINDOW_WIDTH*MAHU_DISP_WINDOW_HEIGHT*MAHU_DISP_COLOR_SIZE);

		  export const	MAHU_DISP_TYPE_DRAW				=0X00;
		  export const	MAHU_DISP_TYPE_CLEAR			=0X01;
		  export const	MAHU_DISP_TYPE_DRAW_STRING		=0X02;
		  export const	MAHU_DISP_TYPE_DRAW_POINT		=0X03;

		  export const	MAHU_IMAGE_WIDTH_MAX			=1024;
		  export const	MAHU_IMAGE_LINE_BUF_SIZE		=(MAHU_IMAGE_WIDTH_MAX*MAHU_DISP_COLOR_SIZE);




		  export const	MAHU_CMD_DOWN_FLAG				=	0X0600;


		  export const	  MAHU_CMD_DOWN_CHECK_VCC			=( MAHU_CMD_DOWN_FLAG |0X11 );
		  export const	   MAHU_CMD_DOWN_CHECK_VCC_SLEEP_TIME	=( MAHU_CMD_DOWN_FLAG |300 );

		  export const	   MAHU_CMD_DOWN_RESET_SERVER		=( MAHU_CMD_DOWN_FLAG |5 );
		 // export const	   MAHU_CMD_DOWN_LIGHT_MAC			=( MAHU_CMD_DOWN_FLAG |6 );


		  export const	  MAHU_CMD_DOWN_CHECK_CPU			=( MAHU_CMD_DOWN_FLAG |0X12 );
		  export const	  MAHU_CMD_DOWN_CHECK_OSC			=( MAHU_CMD_DOWN_FLAG |0X13 );
		  export const	  MAHU_CMD_DOWN_CHECK_EXT_FLASH		=( MAHU_CMD_DOWN_FLAG |0X14 );
		  export const	  MAHU_CMD_DOWN_CHECK_EXT_FLASH_ID=( MAHU_CMD_DOWN_FLAG |0X461F );
		  export const	  MAHU_CMD_DOWN_CHECK_WIFI			=( MAHU_CMD_DOWN_FLAG |0X15 );
		  export const	  MAHU_CMD_DOWN_CHECK_TEMPOR		=( MAHU_CMD_DOWN_FLAG |0X16 );
		  export const	  MAHU_CMD_DOWN_CHECK_PIR			=( MAHU_CMD_DOWN_FLAG |0X17 );
		  export const	  MAHU_CMD_DOWN_CHECK_LUX			=( MAHU_CMD_DOWN_FLAG |0X18 );
		  export const	  MAHU_CMD_DOWN_CHECK_TFT			=( MAHU_CMD_DOWN_FLAG |0X19 );
		  export const	  MAHU_CMD_DOWN_CHECK_LED			=( MAHU_CMD_DOWN_FLAG |0X1A );
		  export const	  MAHU_CMD_DOWN_CHECK_FAN			=( MAHU_CMD_DOWN_FLAG |0X1B );
		  export const	  MAHU_CMD_DOWN_MODE_SERIAL			=( MAHU_CMD_DOWN_FLAG |0X1C );
		  export const	  MAHU_CMD_DOWN_MODE_SPI			=( MAHU_CMD_DOWN_FLAG |0X1D );
		  export const	  MAHU_CMD_DOWN_MODE_OSC			=( MAHU_CMD_DOWN_FLAG |0X1E );
		  export const	  MAHU_CMD_DOWN_OSC_STATE			=( MAHU_CMD_DOWN_FLAG |0X1F );
		  export const	  MAHU_CMD_DOWN_CHECK_MIC			=( MAHU_CMD_DOWN_FLAG |0X20 );
		  export const	  MAHU_CMD_DOWN_CHECK_MAC			=( MAHU_CMD_DOWN_FLAG |0X21 );

		  export const	  MAHU_CMD_DOWN_IDLE_CPU			=( MAHU_CMD_DOWN_FLAG |0X30 );

		  export const	  MAHU_CMD_DOWN_RESET_CPU			=( MAHU_CMD_DOWN_FLAG |0X31 );
		  export const	  MAHU_CMD_DOWN_READ_CPU_FOUSE	=( MAHU_CMD_DOWN_FLAG |0X32 );
		  export const	  MAHU_CMD_DOWN_WRITE_CPU_FOUSE	=( MAHU_CMD_DOWN_FLAG |0X33 );



		  export const	   MAHU_CMD_DOWN_SERIAL_READ_CPU_FLASH	=( MAHU_CMD_DOWN_FLAG |0X41 );
		  export const	   MAHU_CMD_DOWN_SERIAL_READ_CPU_EPROM	=( MAHU_CMD_DOWN_FLAG |0X42 );
		  export const	   MAHU_CMD_DOWN_SERIAL_READ_EXT_FLASH	=( MAHU_CMD_DOWN_FLAG |0X43 );

		  export const	   MAHU_CMD_DOWN_SERIAL_WRITE_CPU_FLASH	=( MAHU_CMD_DOWN_FLAG |0X51 );
		  export const	   MAHU_CMD_DOWN_SERIAL_WRITE_CPU_EPROM	=( MAHU_CMD_DOWN_FLAG |0X52 );
		  export const	   MAHU_CMD_DOWN_SERIAL_WRITE_EXT_FLASH	=( MAHU_CMD_DOWN_FLAG |0X53 );

		  export const	   MAHU_CMD_DOWN_SERIAL_EARSE_CPU_FLASH	=( MAHU_CMD_DOWN_FLAG |0X61 );
		  export const	   MAHU_CMD_DOWN_SERIAL_EARSE_CPU_EPROM	=( MAHU_CMD_DOWN_FLAG |0X62 );
		  export const	   MAHU_CMD_DOWN_SERIAL_EARSE_EXT_FLASH	=( MAHU_CMD_DOWN_FLAG |0X63 );

		  export const	   MAHU_CMD_DOWN_SPI_READ_CPU_FLASH	=( MAHU_CMD_DOWN_FLAG |0X44 );
		  export const	   MAHU_CMD_DOWN_SPI_READ_CPU_EPROM	=( MAHU_CMD_DOWN_FLAG |0X45 );
		  export const	   MAHU_CMD_DOWN_SPI_READ_EXT_FLASH	=( MAHU_CMD_DOWN_FLAG |0X46 );

		  export const	   MAHU_CMD_DOWN_SPI_WRITE_CPU_FLASH	=( MAHU_CMD_DOWN_FLAG |0X54 );
		  export const	   MAHU_CMD_DOWN_SPI_WRITE_CPU_EPROM	=( MAHU_CMD_DOWN_FLAG |0X55 );
		  export const	   MAHU_CMD_DOWN_SPI_WRITE_EXT_FLASH	=( MAHU_CMD_DOWN_FLAG |0X56 );

		  export const	   MAHU_CMD_DOWN_SPI_EARSE_CPU_FLASH	=( MAHU_CMD_DOWN_FLAG |0X64 );
		  export const	   MAHU_CMD_DOWN_SPI_EARSE_CPU_EPROM	=( MAHU_CMD_DOWN_FLAG |0X65 );
		  export const	   MAHU_CMD_DOWN_SPI_EARSE_EXT_FLASH	=( MAHU_CMD_DOWN_FLAG |0X66 );

		  export const	   MAHU_CMD_DOWN_HELP_CODE				=( MAHU_CMD_DOWN_FLAG |0X71 );
		  export const	   MAHU_CMD_DOWN_BOOT_LOAD_CODE			=( MAHU_CMD_DOWN_FLAG |0X72 );
		  export const	   MAHU_CMD_DOWN_OSC_CODE				=( MAHU_CMD_DOWN_FLAG |0X73 );
		  export const	   MAHU_CMD_DOWN_FILE_SYSTEM			=( MAHU_CMD_DOWN_FLAG |0X74 );
		  export const	   MAHU_CMD_DOWN_CONFIG_SYSTEM			=( MAHU_CMD_DOWN_FLAG |0X75 );



		  export const		  MAHU_CMD_DOWN_NODE_TIME_OUT = 80;

		  export const		  MAHU_CMD_DOWN_NODE_SRIAL_TIME_OUT = 200;

		  export const		  MAHU_CMD_DOWN_NODE_SRIAL_CHECK_EXT_TIME_OUT = 500;
		  export const		  MAHU_CMD_DOWN_NODE_SRIAL_WRITE_EXT_FLASH_TIME_OUT = 500;
		  export const		  MAHU_CMD_DOWN_NODE_SRIAL_CD_TIME_OUT=40;
		  export const		  MAHU_CMD_DOWN_NODE_SRIAL_READ_EXT_FLASH_TIME_OUT = 500;

		  export const		  MAHU_CMD_DOWN_NODE_SRIAL_WRITE_CPU_EPROM_TIME_OUT = 3000;
		  export const		  MAHU_CMD_DOWN_NODE_SRIAL_READ_CPU_EPROM_TIME_OUT = 500;

		  export const	MAHU_CMD_DOWN_WEB_FLAG			=	0XFF00;
		  export const	MAHU_CMD_DOWN_SQL_LIGHT_SEARCH	=	0X0101;

		  export const	MAHU_CMD_DOWN_WEB_LIGHT_MAC		=	0X0102;
		  export const	MAHU_CMD_DOWN_SQL_CLOSE			=	0X0103;




		  export const	   MAHU_CMD_DOWN_LIGHT_TIME_OUT	=	10000;



		  export const	 MAHU_CMD_DOWN_LIGHT_NUM		=0X10;
		  export const	 MAHU_CMD_DOWN_WEB_NUM		=0X20;

		  export const	 MAHU_CMD_DOWN_LIGHT_MAC_Bit47_32=0X017E;
		  export const	 MAHU_CMD_DOWN_LIGHT_MAC_DEFAULT	=0X05040302;



		  export const	 MAHU_DOWN_BOOT_CODE_SIZE	=0X1000;//2k
		  export const	 MAHU_DOWN_OSC_CODE_SIZE	=0X1000;//4k
		  export const	 MAHU_DOWN_HELP_CODE_SIZE	=0X4000;//4k
		  export const	 MAHU_DOWN_CPU_EPROM_SIZE	=0X1000;//4k
		  export const	 MAHU_DOWN_CPU_FLASH_SIZE	=0X20000;//4k



		  export const	 MAHU_DOWN_FONT_SIZE		=0X40000;//4k
		  export const	 MAHU_DOWN_IMAGE_SIZE		=0X30000;//4k


		  export const	 MAHU_DOWN_CPU_FOUSE_3		= 0Xff;//4k
		  export const	 MAHU_DOWN_CPU_FOUSE_2		= 0Xff;//4k

		  export const	 MAHU_DOWN_CPU_FOUSE_1_BOOT		= 0XD2;//4k
		  // OCD :1  JTAG :1 SPI:0  CLK : ?  EESAVE : 1  BOOTSIZE_1:1  BOOTSIZE_0:0	BOOTRST:?
		  export const	 MAHU_DOWN_CPU_FOUSE_0_BOOT		= 0X94;//4k
		  // OCD :1  JTAG :1 SPI:0  CLK : ?  EESAVE : 1  BOOTSIZE_1:1  BOOTSIZE_0:0	BOOTRST:?


		  export const	 MAHU_DOWN_CPU_FOUSE_1_OSC		= 0XD7;//4k
		// OCD :1  JTAG :1 SPI:0  CLK : ?  EESAVE : 1  BOOTSIZE_1:1  BOOTSIZE_0:0	BOOTRST:?
		  export const	 MAHU_DOWN_CPU_FOUSE_0_OSC		= 0X94;//4k


		  export const	 MAHU_DOWN_CPU_FOUSE_1_HELP		= 0XDF;//4k
		// OCD :1  JTAG :1 SPI:0  CLK : ?  EESAVE : 1  BOOTSIZE_1:1  BOOTSIZE_0:0	BOOTRST:?
		  export const	 MAHU_DOWN_CPU_FOUSE_0_HELP		= 0x94;// 0xBE;//0X80;//4k







		  export const	 MAHU_CONIFG_SYSTEM_UPDATE_SEG_ADDR		= 0XF00;
		  export const	 MAHU_CONIFG_SYSTEM_UPDATE_SIZE_ADDR	= 0XF01;
		  export const	 MAHU_CONIFG_SYSTEM_INIT_SEG_ADDR		= 0XF02;
		  export const	 MAHU_CONIFG_SYSTEM_INIT_SIZE_ADDR		= 0XF03;
		  export const	 MAHU_CONIFG_SYSTEM_BOOT_COUNT_ADDR		= 0XF04;
		  export const	 MAHU_CONIFG_SYSTEM_NET_COUNT_ADDR		 =0XF05;
		  export const	 MAHU_CONIFG_WIFI_HELP_ADDR				= 0XF06;
		  export const	 MAHU_CONIFG_WIFI_FW_ADDR		=		 0XF07;

		  export const	 MAHU_CONIFG_PIR_AVG_ADDR		  		   =0XF10;
		  export const	 MAHU_CONIFG_PIR_AVG_MAX_ADDR	  		   =0XF11;
		  export const	 MAHU_CONIFG_PIR_AVG_MIN_ADDR	  		   =0XF13;
		  export const	 MAHU_CONIFG_PIR_AVG_TIME_ADDR	  		   =0XF14;

		  //code  ' addr of flash
		  export const	 MAHU_CONIFG_CPU_FLASH_CODE_SIZE		=	4;

		  export const	 MAHU_CONIFG_MAC_ADDR					=	0X0FF0;

		  export const	   	MAHU_CONFIG_SSID_LOAD_COUNT_ADDR		=0X0F20;
		  export const	   	MAHU_CONFIG_SSID_LOAD_COUNT_DEFAULT		=0X00F7;
		  export const	   	MAHU_CONFIG_LOCAL_IP_ADDR		  		=0X0F22;
		  export const	   	MAHU_CONFIG_SERVER_IP_ADDR		  		=0X0F26;
		  export const	   	MAHU_CONFIG_WEB_ID_ADDR		  			=0X0F2A;
		  export const	   	MAHU_CONFIG_SERVER_MAC_ADDR		  		=0X0F2E;
		  export const	   	MAHU_CONFIG_USER_MAC_ADDR		  		=0X0F30;

		  export const	 	MAHU_CONIFG_OSC_ADDR				=	0XFFF;

		  export const	  	MAHU_CONFIG_SSID_ADDR				=	0X0E00;
		  export const	  	MAHU_SSID_SIZE						=	0X20;
		  export const	  	MAHU_CONFIG_PASSWORD_ADDR			=	MAHU_CONFIG_SSID_ADDR+MAHU_SSID_SIZE;
		  export const	  	MAHU_PASSWORD_SIZE					=	0X20;
		  export const	  	MAHU_CONFIG_PMK_ADDR				=	(MAHU_CONFIG_PASSWORD_ADDR+MAHU_PASSWORD_SIZE);
		  export const	  	MAHU_SSID_PMK_SIZE					=	0X20;

		  export const	  	MAHU_CONFIG_SERVER_NAME_ADDR		=	(MAHU_CONFIG_PMK_ADDR+MAHU_SSID_PMK_SIZE);//0XF50
		  export const	  	MAHU_CONFIG_SERVER_NAME_SIZE		=	(0X20);//0XF60

		  export const	  	MAHU_LIGHT_STATE_ADDR		=	(MAHU_CONFIG_SERVER_NAME_ADDR+MAHU_CONFIG_SERVER_NAME_SIZE);//0XF50
		  export const	  	MAHU_LIGHT_STATE_SIZE		=	(0X40);//0XF60

		  export const	  	MAHU_CONFIG_SSID_BUF_ADDR				=	0X0C00;
		  export const	  	MAHU_CONFIG_PASSWORD_BUF_ADDR				=	(MAHU_CONFIG_SSID_BUF_ADDR+ MAHU_SSID_SIZE);



		  export const MAHU_DOWN_BOOT_LOAD_FILE					=	"wifiBootLamp20W.hex";
		  export const MAHU_DOWN_HELP_FILE						=   "wifiDownHelpLamp20W.hex";
		  export const MAHU_DOWN_OSC_FILE						=  "OscAdj.hex";
		  export const MAHU_DOWN_SYSTEM_FILE						=  "Light.hex";
		  export const MAHU_LIGHT_SYSTEM_FILE					=  "Light.hex";
		  export const MAHU_DOWN_CONFIG_FILE						=  "config.bin";
		  export const MAHU_DOWN_CONFIG_FILE_TIP					=  "config";
		  export const MAHU_DOWN_WIFIHELP_FILE					=  "wifihelp.bin";
		  export const MAHU_DOWN_WIFIFW_FILE						=  "wififw.bin";
		  export const MAHU_DOWN_FONT_FILE						=  "font.bin";
		  export const MAHU_DOWN_IMAGE_FILE						=  "moon.bmp";
		  export const MAHU_SSID_STAR							=	"STAR";
		  export const MAHU_SSID_PASSWORD_STAR					=	"123456";
		  export const MAHU_SERVER_NAME							=	"moons.imwork.net";


		  export const	  MAHU_CMD_DOWN_DATA_SIZE					=	0X100;

		  //down
		  //		clientMac	6
		  // 		cmd  		2
		  //		addr		4
		  // 		dataLen		2
		  //		crc			2
		  //		dataBuf		0X100



	//	  export const	 MAHU_CMD_ACK 	=0X8000;
		  export const	 DISP_COLOR_RED		=(0X001F);
		  export const	 DISP_COLOR_GREEN		=(0X003F<<5);
		  export const	 DISP_COLOR_BLUE		=(0X001F<<11);
		  export const	 DISP_COLOR_GRAY		=((0X0010<<0)|(0X20<<5)|(0X10<<11));
		  export const	 DISP_COLOR_BLACK		=(0X0000);
		  export const	 DISP_COLOR_PIX_1		=(0X0001);
		  export const	 DISP_COLOR_PIX_2		=(0X0002);
		  export const	 DISP_COLOR_PIX_4		=(0X0004);
		  export const	 DISP_COLOR_PIX_16		=(0X0010);
		  export const	 DISP_COLOR_PIX_24		=(0X0018);

		   export const	 DISP_COLOR_WHITE		=(0XFFFF);
		   export const	 DISP_COLOR_U8_WHITE		= (0XFF);
		   export const		 DISP_COLOR_INT_WHITE		= DISP_COLOR_U8_WHITE&0XFF;
		   export const	 DISP_COLOR_U8_GRAY			= (0X80);
		   export const		 DISP_COLOR_INT_GRAY		= DISP_COLOR_U8_GRAY &0XFF;
		   export const	 DISP_COLOR_EDGE_DIFF_MIN		= (0X10);
		   export const	 DISP_COLOR_BLOCK_DIFF_MIN		= (0X10);
		   export const	 DISP_COLOR_U8_BLACK		=(0);
		   export const		 DISP_COLOR_INT_BLACK		= DISP_COLOR_U8_BLACK &0XFF;
		   export const		 DISP_COLOR_INT_MIN_BLACK		= 1;
		   export const		 DISP_COLOR_INT_MAX_WHITE		= 0XFF;
		   export const		 DISP_COLOR_INT_BLACK_SMALL_REF		= 0X60;
		   export const		 OCR_FIGURE_COLOR_INT_DIFF		= 0X30;
		   export const	 MAHU_CMD_DOWN_CPU_FLAG			=	0XA5C3;
		   export const	 MAHU_CMD_DOWN_FLAG_H		=		((MAHU_CMD_DOWN_CPU_FLAG>>8)&0XFF);
		   export const	 MAHU_CMD_DOWN_FLAG_L		=		(MAHU_CMD_DOWN_CPU_FLAG&0XFF);

		   export const	 IMAGE_OCR_CAMER_GEOMETRY_NUM=10;
		   export const	 IMAGE_OCR_GEOMETRY_BOXLINES_NUM=10;

			 export const MAHU_OCR_MM_RATE=10;
			 export const MAHU_OCR_UM_RATE=10;


			export const IMAGE_OCR_SCENCE_PIX_HEIGHT=25;//2.5umm
			export const IMAGE_OCR_SCENCE_PIX_WIDTH=25;//0.25mm



		   export const	 IMAGE_OCR_CAMER_SENSOR_PIX_WIDTH=1*MAHU_OCR_UM_RATE;//1um
		   export const	 IMAGE_OCR_CAMER_SENSOR_PIX_HEIGHT=IMAGE_OCR_CAMER_SENSOR_PIX_WIDTH;//1um
		   export const	 IMAGE_OCR_CAMER_SENSOR_PIX_RADIO=IMAGE_OCR_CAMER_SENSOR_PIX_WIDTH;//1um
		   export const	 IMAGE_OCR_CAMER_SENSOR_LENS_DIST=IMAGE_OCR_CAMER_SENSOR_PIX_WIDTH*390;//1um

			export const MAHU_CAMER_SENSOR_LENS_REF_NUM				=0X10;
			export const MAHU_CAMER_SCENCE_LINE_REF_NUM				=0X10;


		   export const	 IMAGE_OCR_CAMER_SENSOR_TO_OBJECT_DEPATH=1000;

		   export const	 IMAGE_OCR_CAMER_IMG_REF_EDGE=16;


		   export const	 IMAGE_OCR_CAMER_FIGURE_REF_NUM=2;
		   export const	 IMAGE_OCR_CAMER_BLOCK_REF_NUM=0X10;
		   export const	 IMAGE_OCR_CAMER_BLOCK_REF_MOVE_NUM=0X4;
		   export const	 IMAGE_OCR_FIGURE_BLOCK_REF_NUM=0X10;
		   export const	 IMAGE_OCR_CAMER_MOVE_REF_COUNT=0X10;

		   export const 	IMAGE_OCR_CAMER_MOVE_REF_BLOCK_PIXS_COUNT=1024*4;

			export const	 	   MAHU_CMD_DATA_MIN_SIZE		=	0X10;
			export const	 		CPU_OSC_10M_DIV256_1000MS_COUNT =39063 ;

			  export const	 MAHU_CMD_MAC_0	=0X7E ;
			  export const	 MAHU_CMD_MAC_1	=0X7F ;


			  export const	 MAHU_CMD_DOWN_LIGHT_MAC_0	=MAHU_CMD_MAC_0 ;
			  export const	 MAHU_CMD_DOWN_LIGHT_MAC_1	=MAHU_CMD_MAC_1;
			  export const	 MAHU_CMD_DOWN_LIGHT_MAC_2	=0X00;
			  export const	 MAHU_CMD_DOWN_LIGHT_MAC_3	=0X00 ;
			  export const	 MAHU_CMD_DOWN_LIGHT_MAC_4	=0X01 ;
			  export const	 MAHU_CMD_DOWN_LIGHT_MAC_5	=0X01 ;

			  export const	 MAHU_CMD_DOWN_USER_MAC_0	=MAHU_CMD_MAC_0 ;
			  export const	 MAHU_CMD_DOWN_USER_MAC_1	=MAHU_CMD_MAC_1;
			  export const	 MAHU_CMD_DOWN_USER_MAC_2	=0X80;
			  export const	 MAHU_CMD_DOWN_USER_MAC_3	=0X00 ;
			  export const	 MAHU_CMD_DOWN_USER_MAC_4	=0X01 ;
			  export const	 MAHU_CMD_DOWN_USER_MAC_5	=0X01 ;

			  export const	 MAHU_CMD_DOWN_NODE_MAC_0	=MAHU_CMD_MAC_0 ;
			  export const	 MAHU_CMD_DOWN_NODE_MAC_1	=MAHU_CMD_MAC_1;
			  export const	 MAHU_CMD_DOWN_NODE_MAC_2	=0X00;
			  export const	 MAHU_CMD_DOWN_NODE_MAC_3	=0X00 ;
			  export const	 MAHU_CMD_DOWN_NODE_MAC_4	=0X01 ;
			  export const	 MAHU_CMD_DOWN_NODE_MAC_5	=0X01 ;



			  export const	 MAHU_CMD_LIGHT_MAC_0	=MAHU_CMD_MAC_0 ;
			  export const	 MAHU_CMD_LIGHT_MAC_1	=MAHU_CMD_MAC_1;
			  export const	 MAHU_CMD_LIGHT_MAC_2	=0X02;
			  export const	 MAHU_CMD_LIGHT_MAC_3	=0X03 ;
			  export const	 MAHU_CMD_LIGHT_MAC_4	=0X04 ;
			  export const	 MAHU_CMD_LIGHT_MAC_5	=0X05 ;

			  export const	 MAHU_CMD_USER_MAC_0	=MAHU_CMD_MAC_0 ;
			  export const	 MAHU_CMD_USER_MAC_1	=MAHU_CMD_MAC_1;
			  export const	 MAHU_CMD_USER_MAC_2	= 0X82;
			  export const	 MAHU_CMD_USER_MAC_3	=0X03 ;
			  export const	 MAHU_CMD_USER_MAC_4	=0X04 ;
			  export const	 MAHU_CMD_USER_MAC_5	=0X05 ;

			  export const	 MAHU_CMD_SERVER_MAC_0	=MAHU_CMD_MAC_0 ;
			  export const	 MAHU_CMD_SERVER_MAC_1	=MAHU_CMD_MAC_1;
			  export const	 MAHU_CMD_SERVER_MAC_2	= 0X80;
			  export const	 MAHU_CMD_SERVER_MAC_3	=0X00 ;
			  export const	 MAHU_CMD_SERVER_MAC_4	=0X00 ;
			  export const	 MAHU_CMD_SERVER_MAC_5	=0X00 ;

			  export const	 MAHU_DOWN_SERVER_MAC_0	=MAHU_CMD_MAC_0 ;
			  export const	 MAHU_DOWN_SERVER_MAC_1	=MAHU_CMD_MAC_1;
			  export const	 MAHU_DOWN_SERVER_MAC_2	= 0X80;
			  export const	 MAHU_DOWN_SERVER_MAC_3	=0X00 ;
			  export const	 MAHU_DOWN_SERVER_MAC_4	=0X00 ;
			  export const	 MAHU_DOWN_SERVER_MAC_5	=0X01 ;


			  export const	 MAHU_LIGHT_ID_SERVER	=0X00 ;



			  export const		 MAHU_USER_LIGHT_NUM	=0X10 ;


			  export const MAHU_IMAGE_DEFAULT		= "http://localhost/img/starlight.jpg";
			  export const MAHU_CMD_WEB_IMAGE_NUM		= 10;


			  export const 	MAHU_CMD_THREAD_TYPE_NULL	=0x00;
			  export const 	MAHU_CMD_THREAD_TYPE_FILE	=0x01;
			  export const 	MAHU_CMD_THREAD_TYPE_IMAGE	=0x02;
			  export const 	MAHU_CMD_THREAD_TYPE_CONFIG	=0x03;
			  export const 	MAHU_CMD_THREAD_TYPE_STOP	=0xFF;
			  export const 	MAHU_CMD_THREAD_TYPE_TIME_OUT	=(1000*200);


			  export const 	MAHU_CMD_FILE_SEG_STATE_OK	=0X00;
			  export const 	MAHU_CMD_FILE_SEG_STATE_FF	= 0XFF;
			  export const 	MAHU_CMD_FILE_SEG_STATE_EE	= 0XEE;
			  export const 	MAHU_CMD_FILE_SEG_STATE_CC	= 0XCC;

			  export const 	MAHU_CMD_FILE_BLOCK_STATE_OK	= 0X00;
			  export const 	MAHU_CMD_FILE_BLOCK_STATE_FF	= 0XFF;
			  export const 	MAHU_LIGHT_SYSTEM_VERSION_TIP_FLAG	= '$';
			  export const  MAHU_LIGHT_SYSTEM_VERSION_TIP="MahuLight$";
			  export const  MAHU_LIGHT_CONFIG_TIP="MahuLightConfig$";


			  export const 	MAHU_LIGHT_SYSTEM_VERSION_BUF_SIZE	 =0X20;
			  export const 	MAHU_LIGHT_SYSTEM_VERSION_SN_SIZE	 =0X10;
			  export const HexCode = "0123456789ABCDEF";

			 // export const	MAHU_CMD_DATA_EXT_SIZE			=	0x400;
			  export const	MAHU_LIGHT_SYSTEM_VERSION_SIZE			=0x8;

			  export const	MAHU_FILE_LENGTH_SIZE					=0x4;
			  export const	MAHU_FILE_VERSION_OFFSET_SIZE					=0x4;

			  export const	MAHU_LIGHT_ICON_LARGE_WIDTH				=32;
			  export const	MAHU_LIGHT_ICON_LARGE_HEIGHT				=32;


			  export const	MAHU_FILE_NAME_ASCII_START				=0X21;
			  export const	MAHU_FILE_NAME_ASCII_END				=0X7E;

			  export const	MAHU_FILE_LIST_MIN					=	0X10;

			  export const	 	MAHU_SYSTEM_FILE_TYPTE_LIGHT_FILE		=1;
			  export const	 	MAHU_SYSTEM_FILE_TYPTE_LIGHT_OTHER		=2;
			  export const	 	MAHU_SYSTEM_FILE_TYPTE_LIGHT_SYSTEM		=3;
			  export const	 	MAHU_SYSTEM_FILE_TYPTE_LIGHT_MAX		=(0X100-1);

			  export const	 	MAHU_SYSTEM_FILE_TYPTE_USER			=0X200;


			  export const	MAHU_FILE_TYPE_USER						=2;

			  export const	MAHU_FILE_TYPE_IMAGE					=0X30;
			  export const	MAHU_FILE_TYPE_FLASH					=0X20;
			  export const	MAHU_FILE_TYPE_BIN						=0X10;

			  export const	 MAHU_YEAR_365_MS_L32 						=0X57B12C00;
			  export const	 MAHU_YEAR_365_MS_H32 						=0X7;
			  export const	 MAHU_YEAR_366_MS_L32 						=0X5CD78800;
			  export const	 MAHU_YEAR_366_MS_H32 						=0X7;
			  export const	 MAHU_MOON_31_MS_L32							=0X9FA52400;
			  export const	 MAHU_MOON_30_MS_L32							=0X9A7EC800;
			  export const	 MAHU_MOON_29_MS_L32							=0X95586C00;
			  export const	 MAHU_MOON_28_MS_L32							=0X90321000;
			  export const	 MAHU_DAY_MS_L32								=0X05265C00;
			  export const	 MAHU_HOUR_MS_L32							=0X36EE80;
			  export const	 MINUTE_MS_L32							=0XEA60;
			  export const	 MAHU_SECOND_MS_L32							=1000;
			  export const	 SYSTEM_DATE_TIME_SIZE					=8;
			  export const	 SYSTEM_DATE_TIME_MAHU_SECOND_COUNT					=9765;

			  export const	 SYSTEM_CODE_UPDATE_TIME_OUT			=MAHU_DAY_MS_L32;

			  export const	 SYSTEM_CODE_DELETE_TIME_OUT			=MAHU_DAY_MS_L32;

			  export const	 AUX_CODE_DELETE_TIME_OUT				=MAHU_HOUR_MS_L32;

			  export const	 LIGHT_FILE_UPDATE_TIME_OUT				=MAHU_DAY_MS_L32;
			  export const	 LIGHT_FILE_NEW_TIME_OUT				=MAHU_DAY_MS_L32;

			  export const	 LIGHT_SYSTEM_CHECK_TIME_OUT			=MAHU_DAY_MS_L32;
			  export const	 LIGHT_CONFIG_CHECK_TIME_OUT			=MAHU_DAY_MS_L32;

			  export const	 LIGHT_FILE_WRITE_TIME_OUT				=MAHU_HOUR_MS_L32*2;

			  export const	 LIGHT_FILE_WRITE_CHECK_TIME_OUT		=MAHU_DAY_MS_L32;
			  export const	 LIGHT_CONFIG_WRITE_CHECK_TIME_OUT		=MAHU_DAY_MS_L32;

			  export const	 WEB_SERVER_CONNECT_TIME_OUT			=MINUTE_MS_L32*10;
			  export const	 LIGHT_SERVER_CONNECT_TIME_OUT			=MINUTE_MS_L32*10;

			  export const	 LIGHT_DATA_UPDATE_TIME_OUT				=MINUTE_MS_L32*10;
			  export const	 LIGHT_DATA_BACKUP_TIME_OUT				=MAHU_HOUR_MS_L32*4;

			  export const	 LIGHT_FILE_DELAY_TIME					=MINUTE_MS_L32*10;
			  export const	 LIGHT_SYSTEM_CHECK_DELAY_TIME			=MINUTE_MS_L32*10;

			  export const	 LIGHT_CONNECT_TIME_OUT				=MINUTE_MS_L32*2;
			  export const	 LIGHT_SYNC_TIME_OUT				=(LIGHT_CONNECT_TIME_OUT/2);
			  export const	 LIGHT_PACKET_TIME_MIN					=(20);

			  export const	 LIGHT_STATE_DELAY_TIME					=MINUTE_MS_L32;
			  export const	 LIGHT_DISP_DELAY_TIME					=(MINUTE_MS_L32*2);

			  export const	 LIGHT_LOG_ON_TIME_OUT				=MINUTE_MS_L32*10;
			  export const 	MAHU_CMD_LIGHT_OFF_TIMEOUT					=		(LIGHT_STATE_DELAY_TIME*3);
			  export const	 LIGHT_SYSTEM_RESET_DELAY_TIME			=MAHU_HOUR_MS_L32;
			  export const	 WEB_IMAGE_FILE_REFRESH_TIME_OUT	= MAHU_HOUR_MS_L32;

			  export const	 MAHU_CMD_LIGHT_STATE_SLIENCE_TIME_OUT	= MINUTE_MS_L32*2;

			  export const	     MAHU_FILE_BLOCK_SIZE_BIT_NUM			=8;
			  export const	 	MAHU_FILE_PATH_LOCAL_TIP				="LocalHost/";

			  export const	 	MAHU_FILE_PATH_SIZE						=(128);
			  export const	 	MAHU_FILE_DESC_SIZE						=(128);
			  export const	 	LIGHT_NET_STATE_SERVER					=(1<<1);
			  export const	 	LIGHT_NET_STATE_REMOTE					=(1<<2);
			  export const	 	LIGHT_NET_STATE_LOCAL					=(1<<3);


			 export const	 	MAHU_DISP_SEND_LOCAL_TIME_OUT				=50;
			 export const	 	MAHU_DISP_SEND_REMOTE_TIME_OUT				=150;

			 export const	  	MAHU_DISP_LINE_BUF_SIZE		 			=(MAHU_DISP_WINDOW_WIDTH*MAHU_DISP_COLOR_SIZE);
			 export const	 	MAHU_DISP_COLOR16_A	=					(1<<5);
			 export const	 	MAHU_BMP_HEADER_SIZE					=	54;
			 export const	  	MAHU_BMP_HEADER_WIDTH_OFFSET			=	0X12;
			 export const	  	MAHU_BMP_HEADER_HEIGHT_OFFSET			=	0X16;



			 export const	  	IMAGE_TYPE_JPEG				= (1);
			 export const	  	IMAGE_TYPE_PNG				= (2);
			 export const	  	IMAGE_TYPE_BMP_RGAB_5515		= (1<<12)|(5<<8)|(5<<4)|(5<<0);
			 export const	  	IMAGE_TYPE_UPDATE_TIME_OUT		= (MINUTE_MS_L32*5);


			 export const		IMAGE_BMP_COLOR_DEFAULT			=MAHU_DISP_COLOR16_A;
			 export const		IMAGE_BMP_COLOR_DEFAULT_L8			=(IMAGE_BMP_COLOR_DEFAULT&0XFF);
			 export const		IMAGE_BMP_COLOR_DEFAULT_H8			=((IMAGE_BMP_COLOR_DEFAULT>>8)&0XFF);
			 export const		IMAGE_LINE_PIX_MIN_SIZE				=(0X10);
			 export const		MAHU_FILE_PATH_FLAG					=0X2F;// '\'
			 export const		MAHU_FILE_PATH_FLAG2				=0X5C;// '/'
			 export const 	MAHU_TIME_FORMAT 				= "yyyy-MM-dd HH:mm:ss";


			  export const	 	MAHU_SQL_DB_BACKUP_TIME_OUT		=MAHU_HOUR_MS_L32*8;

			  export const  	MAHU_CMD_WEB_ID_NUM= MAHU_FILE_BLOCK_SIZE/ MAHU_FILE_ID_SIZE;



			  export const		LIGHT_FILE_UPDATE_DELETE				=0X10;
			  export const		LIGHT_FILE_UPDATE_REFRESH				=0X20;
			  export const		LIGHT_FILE_UPDATE_NO_CHAGNGE			=0X30;



			  export const  	MAHU_NET_STATE							= ( 0X800);
			  export const  	MAHU_NET_STATE_LOCAL_IP					= ( 0X10| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LOCAL_PORT				= ( 0X11| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_IP					= ( 0X12| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_PORT					= ( 0X13| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_SERVER_IP				= ( 0X14| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_SERVER_PORT				= ( 0X15| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_USER_ID				= ( 0X16| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_LOGON_TIME			= ( 0X17| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_LOGOFF_TIME			= ( 0X18| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LISTEN_PORT				= ( 0X19| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LISTEN_USER_ID			= ( 0X1A| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LISTEN_USER_IP			= ( 0X1B| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LISTEN_USER_PORT			= ( 0X1C| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LISTEN_LOGON_TIME		= ( 0X1D| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LISTEN_LOGOFF_TIME		= ( 0X1E| MAHU_NET_STATE);

			  export const  	MAHU_NET_STATE_LOCAL_LIGHT_ID			= ( 0X20| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LOCAL_LIGHT_IP			= ( 0X21| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LOCAL_LIGHT_PORT			= ( 0X22| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LOCAL_LIGHT_LOGON_TIME 	= ( 0X23| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LOCAL_LIGHT_LOGOFF_TIME	= ( 0X24| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_LOCAL_LIGHT_NUM			= ( 0X25| MAHU_NET_STATE);

			  export const  	MAHU_NET_STATE_WEB_LIGHT_ID				= ( 0X30| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_LIGHT_IP				= ( 0X31| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_LIGHT_PORT			= ( 0X32| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_LIGHT_LOGON_TIME 	= ( 0X33| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_LIGHT_LOGOFF_TIME	= ( 0X34| MAHU_NET_STATE);
			  export const  	MAHU_NET_STATE_WEB_LIGHT_NUM			= ( 0X35| MAHU_NET_STATE);


			  export const 		MAHU_LASER_ROOM_ANGLE_NUM =4096 ;

			  export const 		MAHU_LASER_ROOM_ANGLE_BUF_OFFSET = 2048 ;
			  export const 		MAHU_LASER_ROOM_ANGLE_X_ZERO = 0 ;
			  export const 		MAHU_LASER_ROOM_ANGLE_X_MIN  =-2047 ;
			  export const 		MAHU_LASER_ROOM_ANGLE_X_MAX  = 2047 ;
			  export const 		MAHU_LASER_ROOM_ANGLE_Y_ZERO = 0 ;
			  export const 		MAHU_LASER_ROOM_ANGLE_Y_MIN  = -2047 ;
			  export const 		MAHU_LASER_ROOM_ANGLE_Y_MAX  =  2047 ;

			  export const 		MAHU_LASER_ROOM_POS_X_DEFALUT =0 ;
			  export const 		MAHU_LASER_ROOM_POS_Y_DEFALUT =0 ;
			  export const 		MAHU_LASER_ROOM_POS_Z_DEFALUT =300 ;
			  export const 		MAHU_LASER_ROOM_DIST_MOVE		=0X11;

			  export const 		MAHU_LASER_ROOM_DIST_MIN =10 ;	 //1cm
			  export const 		MAHU_LASER_ROOM_DIST_MAX =20000 ;//30M
			  export const 		LASER_ROOM_MAX_DEFAULT	 =200 ;//1M
			  export const 		LASER_ROOM_MAX_COUNT_DEFAULT =30 ;//1M



			  export const 		MAHU_LASER_ANGLE_STEPX =(4096/128) ;//30M
			  export const 		MAHU_LASER_ANGLE_STEPY =(4096/128) ;//30M


			  export const  MAHU_LASER_LINE_DRAW_POINTS=0X04;

			  export const  MAHU_LASER_ROOM_DRAW_CLEAR=0X01;
			  export const  MAHU_LASER_ROOM_DRAW_POINTS=0X02;

			  export const  MAHU_LASER_ROOM_DRAW_WIDTH=0X100;
			  export const  MAHU_LASER_ROOM_DRAW_HEIGHT=0X100;

			  ////20170718

			  export const  LIGHT_SYSTEM_DOWN_TIME		= 10000;
			  export const  LIGHT_SYSTEM_DOWN_TIME_MIN		= 10000;
			  export const  LIGHT_SYSTEM_DOWN_TIME_MAX		= 60000;
			  //20170719

			  //20170804
			  export const  MAHU_CMD_SYSTEM_USER_ID_GET		= ( MAHU_USER_LAG |0X0067);
			  export const  MAHU_CMD_SYSTEM_USER_NAME		= "star";
			  export const  MAHU_CMD_SYSTEM_USER_PASSWORD		= "123456";
			  export const 	MAHU_CMD_LIGHT_USER_IMAGE_ID_RANDOM	=0X000C;
			//20170814
			  export const 	MAHU_LIGHT_LOG_OFF_TIME_OUT	=MAHU_HOUR_MS_L32;
				//20170825
			  export const 	MAHU_CMD_LIGHT_USER_IMAGE_NUM	=0X0010;
			  //20170918
			  export const 	MAHU_CMD_LIGHT_SYSTEM_VERSION_L32_DEFAULT	=0X89ABCDEF;
			  export const 	MAHU_CMD_LIGHT_SYSTEM_VERSION_H32_DEFAULT	=0X01234567;

			  export const  MAHU_PIC_DATA_SIZE =0x40000;
			   export const  MAHU_PIC_DATA_BUF_SIZE =(MAHU_PIC_DATA_SIZE+0X100);


			  	export const AUDIO_WEB_MIC_FLAG 		= 0X70;//64K
				 export const AUDIO_WEB_SPK_FLAG 		= 0X71;//64K
				 export const CAT_SERVER_ECHO_FLAG 		= 0X75;//64K
				 export const AUDIO_CAT_MIC_FLAG 		= 0X78;//64K
				 export const AUDIO_CAT_SPK_FLAG 		= 0X79;//64K
				 export const  AUDIO_PLAY_BUF_SIZE=0X10000*4;

				 export const  AUDIO_WEB_MIC_BUF_SIZE=0X10000*4;
				 export const  AUDIO_CAT_MIC_BUF_SIZE=0X10000;
				 export const  AUDIO_CAT_PACKET_SIZE=0X400;
				 export const  AUDIO_WEB_PACKET_SIZE=0X1000;


				 export const	MAHU_EPAPER_FLAG					=0X900;

				  export const	MAHU_EPAPER_READ			=(MAHU_EPAPER_FLAG | 0X0001);





				 export const 	   MAHU_FLOWER_WATER			=0XA00;// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_WEB_CMD_FLOWER_WATER	=MAHU_FLOWER_WATER;// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_FLOWER_WATER_CMD_PUT		=0XA01;// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_FLOWER_WATER_CMD_GET		=0XA02;// MAHU_CMD_WEB_FLAG |0X75 );

				 export const 	   MAHU_FLOWER_WATER_ROOT				=0X10;// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_FLOWER_WATER_LEAVES	 			=0X11;
				 export const 	   MAHU_FLOWER_WATER_SOLARIUM 			=0X12;

				 export const  		MAHU_FLOAWER_WATER_ROOT_HUMIDITY		=0X21;
				 export const  		MAHU_FLOAWER_WATER_LEAVES_HUMIDITY		=0X22;
				 export const  		MAHU_FLOAWER_WATER_LUMINANCE			=0X23;
				 export const  		MAHU_FLOAWER_WATER_FERTILIZE			=0X24;
				 export const  		MAHU_FLOAWER_WATER_VIDEO				=0X25;

				 export const 	   MAHU_FLOWER_WATER_STATE_ROOT_TEMPOR				=0X31;
				 export const 	   MAHU_FLOWER_WATER_STATE_ROOT_HUMIDITY 			=0X32;
				 export const 	   MAHU_FLOWER_WATER_STATE_LEAVES_TEMPOR 			=0X33;
				 export const 	   MAHU_FLOWER_WATER_STATE_LEAVES_HUMIDITY			=0X34;
				 export const 	   MAHU_FLOWER_WATER_STATE_ROOT_PH 					=0X35;
				 export const 	   MAHU_FLOWER_WATER_STATE_LEAVES_PH 				=0X36;
				 export const 	   MAHU_FLOWER_WATER_STATE_LUMINANCE	 			=0X37;
				 export const 	   MAHU_FLOWER_WATER_STATE_LIFE			 			=0X3F;


				 export const 	   MAHU_BATS						=0XB00;// MAHU_CMD_WEB_FLAG |0X75 );
				 export const		MAHU_SERVER_ID_MIN				=0x80000000;
				 export const		MAHU_SERVER_ID_MAX				=0x8000FFFF;
				 export const		MAHU_BATS_ELF_SERVER_ID_DEFAULT	=0x80000100;
				 export const		MAHU_BATS_ELF_DEFENSE_ID_DEFAULT	=0x80000101;
				 export const		MAHU_BATS_LISTENER_ID_DEFAULT		=0x80000102;
				 export const		MAHU_BATS_WEB_VIEW_LISTENER_ID_DEFAULT	=0x80000103;

				 export const		MAHU_CAMERS_ELF_SERVER_ID_DEFAULT	=0x80000110;
				 export const		MAHU_CAMERS_ELF_DEFENSE_ID_DEFAULT	=0x80000111;
				 export const		MAHU_CAMERS_LISTENER_ID_DEFAULT		=0x80000112;
				 export const		MAHU_CAMERS_WEB_VIEW_LISTENER_ID_DEFAULT	=0x80000113;
				 export const		MAHU_NODE_ELF_DATA_ID_DEFAULT			=0x80000114;
				 export const		MAHU_NODE_WIFI_ROUTE_ID_DEFAULT			=0x00000115;
				 export const		MAHU_WEB_VIEW_ID_DEFAULT			=0x00000116;


				 export const		MAHU_BOARDCAST_ID_DEFAULT			=0xFFFFFFFF;
				 export const 		MAHU_BATS_NODE_ID_DEFAULT		=	0XE800168C;



				 export const 	   MAHU_BATS_CMD_CHECK_VLOATE_TIME_OUT		=(1000*10 );
				 export const 	   MAHU_NODE_BATS_TIME_OUT					=(1000*60 );

				 export const 	   MAHU_BATS_CMD_PUT_VLOATE				=(0X01 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_GET_VLOATE				=(0X03 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_POWER_GND				=(0X10 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_POWER_BAT				=(0X11 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_POWER_VSS_GND			=(0X12 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_POWER_VPP_VSS			=(0X13 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_VCC33_VLOT				=(0X14 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_VCC33_PWM				=(0X15 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_VCC90_VLOT				=(0X16 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_VCC90_PWM				=(0X17 | MAHU_BATS );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_POWER_OUT				 =(0X18 | MAHU_BATS ); // MAHU_CMD_WEB_FLAG |0X75 )
				 export const 	   MAHU_BATS_CMD_POWER_INN				 =(0X19 | MAHU_BATS ); // MAHU_CMD_WEB_FLAG |0X75 )
				 export const 	   MAHU_BATS_CMD_LOCKER							 =(0X20 | MAHU_BATS ); // MAHU_CMD_WEB_FLAG |0X75 )
				 export const 	   MAHU_BATS_CMD_LOCKER_CMD_PASSWORD =(0X21 | MAHU_BATS ); // MAHU_CMD_WEB_FLAG |0X75 )
				 export const 	   MAHU_BATS_CMD_SWITCH_LIMIT				 =(0X22 | MAHU_BATS ); // MAHU_CMD_WEB_FLAG |0X75 )
				 export const 	   MAHU_BATS_CMD_CURRENT						 =(0X23 | MAHU_BATS ); // MAHU_CMD_WEB_FLAG |0X75 )


				 export const 	   MAHU_BATS_CMD_ELF_DEFENSE_ID_FLAG	=(0X70 );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_CLIENT_ID_FLAG			=(0X71 );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_WIFI_FLAG				=(0X72 );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_GROUP_FLAG				=(0X73 );// MAHU_CMD_WEB_FLAG |0X75 );
				 export const 	   MAHU_BATS_CMD_NODE_FLAG				=(MAHU_CMD_MAC_0 );// MAHU_CMD_WEB_FLAG |0X75 );


				 export const 	   MAHU_BATS_CMD_POWER_VSS_GND_ONN		=0XF0;
				 export const 	   MAHU_BATS_CMD_POWER_VSS_GND_OFF	 	=0X10;
				 export const 	   MAHU_BATS_CMD_POWER_VPP_VSS_ONN		=0XF0;
				 export const 	   MAHU_BATS_CMD_POWER_VPP_VSS_OFF	 	=0X10;

				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_REF_OFFSET						=0;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_VSS_GND_ONN_BAT_OFFSET			=1*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_VPP_VSS_ONN_POWER_VSS_OFFSET	=2*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_PRESS_OFFSET					=3*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_TEMPOR_OFFSET					=4*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_VCC90_OFFSET					=5*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_VCC33_OFFSET    				=6*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_POWER_OUT_VPP_ONN_OFFSET		=7*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_POWER_INN_VPP_ONN_OFFSET	 	=8*2 ;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_CURRENT_INN_OFFSET				=9*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_CURRENT_OUT_OFFSET				=10*2;

				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_VSS_GND_OFF_BAT_OFFSET			=11*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_VPP_VSS_OFF_POWER_VSS_OFFSET	=12*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_POWER_OUT_VPP_OFF_OFFSET		=13*2;
				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_POWER_INN_VPP_OFF_OFFSET		=14*2;

				  export const	MAHU_BATS_STATE_POWER_VSS_GND_OFFSET	=30;
				  export const	MAHU_BATS_STATE_POWER_VPP_VSS_OFFSET	=31;
				  export const	MAHU_BATS_STATE_POWER_VCC90_OFFSET		=32;
				  export const	MAHU_BATS_STATE_POWER_OUT_OFFSET		=33;
				  export const	MAHU_BATS_STATE_POWER_INN_OFFSET		=34;
				  export const	MAHU_BATS_STATE_TIP_LED_OFFSET			=35;


				  export const	MAHU_BATS_STATE_BUF_SIZE					=36;///(MAHU_BATS_STATE_TIP_LED_OFFSET+1)

				 export const 	   MAHU_BATS_CMD_VLOT_CHECK_TYPE_ALL				=0X0F;
				 export const 	   MAHU_BATS_CMD_VCC33_ONN			    	=0XF0;
				 export const 	   MAHU_BATS_CMD_VCC33_OFF			    	=0X10;
				 export const 	   MAHU_BATS_CMD_VCC90_ONN			    	=0XF0;
				 export const 	   MAHU_BATS_CMD_VCC90_OFF			    	=0X10;
				 export const 	   MAHU_BATS_CMD_VCC90_VLOT_MAX		    	=15000;
				 export const 	   MAHU_BATS_CMD_VCC90_OFFSET_MAX		    =1000;
				 export const 	   MAHU_BATS_CMD_BATS_VLOT_DEFAULT	    	=3300;
				 export const 	   MAHU_BATS_CMD_REF_V1200_DEFAULT	    	=1200;
				 export const 	   MAHU_BATS_CMD_REF_V1200_ADC_DEFAULT	   	=(MAHU_BATS_CMD_REF_V1200_DEFAULT*1024)/MAHU_BATS_CMD_BATS_VLOT_DEFAULT;//384= 1200*1024 /3300
				 export const 	   MAHU_BATS_CMD_VCC90_ADC_MAX				=(0X400-0X20);
				 export const 	   MAHU_BATS_CMD_VCC90_CHECK_ADJUST_LOW		=	1;//10K
				 export const 	   MAHU_BATS_CMD_VCC90_CHECK_ADJUST_HIGH	=	5;//50K
				 export const 	   MAHU_BATS_CMD_BAT_CHECK_ADJUST_LOW		=	1;//10K
				 export const 	   MAHU_BATS_CND_BAT_CHECK_ADJUST_HIGH		=	1;//50K
				 export const 	   MAHU_BATS_CMD_POWER_CHECK_ADJUST_LOW		=	1;//10K
				 export const 	   MAHU_BATS_CMD_POWER_CHECK_ADJUST_HIGH	=	1;//50K

				 export const  	  MAHU_SENSOR_ADC									=0XC00;// MAHU_CMD_WEB_FLAG |0X75 );
								 export const 	  MAHU_SENSOR_ADC_ELF_SERVER_ID_DEFAULT			=0x80000100;
								 export const 	  MAHU_SENSOR_ADC_ELF_DEFENSE_ID_DEFAULT			=0x80000101;
								 export const 	  MAHU_SENSOR_ADC_LISTENER_ID_DEFAULT				=0x80000102;
								 export const 	  MAHU_SENSOR_ADC_WEB_VIEW_LISTENER_ID_DEFAULT	=0x80000103;

								 export const 	   MAHU_SENSOR_ADC_CMD_PUT_VLOATE				=(0X01 | MAHU_SENSOR_ADC );// MAHU_CMD_WEB_FLAG |0X75 );
								 export const 	   MAHU_SENSOR_ADC_CMD_GET_VLOATE				=(0X03 | MAHU_SENSOR_ADC );// MAHU_CMD_WEB_FLAG |0X75 );

				 export const 		RF_COM_DATA_SIZE  =(RF_COM_BUF_SIZE-MAHU_CMD_HEADER_SIZE);
				 export const  RF_COM_CODE_SEG_FLAG_0  =0XB;
				 export const  RF_COM_CODE_SEG_FLAG_1  =0XD;
				 export const  RF_COM_CODE_SEG_FLAG_2  =0X5;
				 export const  RF_COM_CODE_SEG_FLAG_3  =0X6;
				 export const  RF_COM_CODE_SEG_FLAG_4  =0X9;
				 export const  RF_COM_CODE_SEG_FLAG_5  =0XA;

				 //code export const u8 RF_CODE_DATA_FLAG ={RF_COM_CODE_SEG_FLAG_0,RF_COM_CODE_SEG_FLAG_1,RF_COM_CODE_SEG_FLAG_2,RF_COM_CODE_SEG_FLAG_3,RF_COM_CODE_SEG_FLAG_4,RF_COM_CODE_SEG_FLAG_5};
				 export const  RF_COM_START_FLAG_0  	= ((RF_COM_CODE_SEG_FLAG_0<<4)|(RF_COM_CODE_SEG_FLAG_0));
				 export const  RF_COM_START_FLAG_1  	= ((RF_COM_CODE_SEG_FLAG_0<<4)|(RF_COM_CODE_SEG_FLAG_1));
				 export const  RF_COM_STOP_FLAG_0  	= ((RF_COM_CODE_SEG_FLAG_1<<4)|(RF_COM_CODE_SEG_FLAG_1));
				 export const  RF_COM_STOP_FLAG_1  	= ((RF_COM_CODE_SEG_FLAG_1<<4)|(RF_COM_CODE_SEG_FLAG_0));
				 export const RF_COM_RI_CHECK_SIZE  	=(RF_COM_CODE_FLAG_SIZE*3);

				 export const RF_CODE_DATA_BIT0_FLAG=0X01;
				 export const RF_CODE_DATA_BIT1_FLAG=0X02;


				 export const RF_CODE_DATA_0_FLAG=(RF_CODE_DATA_BIT0_FLAG<<0) | (RF_CODE_DATA_BIT0_FLAG<<2) | (RF_CODE_DATA_BIT0_FLAG<<4) | (RF_CODE_DATA_BIT0_FLAG<<6);
				 export const RF_CODE_DATA_1_FLAG=(RF_CODE_DATA_BIT1_FLAG<<0) | (RF_CODE_DATA_BIT0_FLAG<<2) | (RF_CODE_DATA_BIT0_FLAG<<4) | (RF_CODE_DATA_BIT0_FLAG<<6);
				 export const RF_CODE_DATA_2_FLAG=(RF_CODE_DATA_BIT0_FLAG<<0) | (RF_CODE_DATA_BIT1_FLAG<<2) | (RF_CODE_DATA_BIT0_FLAG<<4) | (RF_CODE_DATA_BIT0_FLAG<<6);
				 export const RF_CODE_DATA_3_FLAG=(RF_CODE_DATA_BIT1_FLAG<<0) | (RF_CODE_DATA_BIT1_FLAG<<2) | (RF_CODE_DATA_BIT0_FLAG<<4) | (RF_CODE_DATA_BIT0_FLAG<<6);
				 export const RF_CODE_DATA_4_FLAG=(RF_CODE_DATA_BIT0_FLAG<<0) | (RF_CODE_DATA_BIT0_FLAG<<2) | (RF_CODE_DATA_BIT1_FLAG<<4) | (RF_CODE_DATA_BIT0_FLAG<<6);
				 export const RF_CODE_DATA_5_FLAG=(RF_CODE_DATA_BIT1_FLAG<<0) | (RF_CODE_DATA_BIT0_FLAG<<2) | (RF_CODE_DATA_BIT1_FLAG<<4) | (RF_CODE_DATA_BIT0_FLAG<<6);
				 export const RF_CODE_DATA_6_FLAG=(RF_CODE_DATA_BIT0_FLAG<<0) | (RF_CODE_DATA_BIT1_FLAG<<2) | (RF_CODE_DATA_BIT1_FLAG<<4) | (RF_CODE_DATA_BIT0_FLAG<<6);
				 export const RF_CODE_DATA_7_FLAG=(RF_CODE_DATA_BIT1_FLAG<<0) | (RF_CODE_DATA_BIT1_FLAG<<2) | (RF_CODE_DATA_BIT1_FLAG<<4) | (RF_CODE_DATA_BIT0_FLAG<<6);
				 export const RF_CODE_DATA_8_FLAG= ((RF_CODE_DATA_BIT0_FLAG<<0) | (RF_CODE_DATA_BIT0_FLAG<<2) | (RF_CODE_DATA_BIT0_FLAG<<4) | (RF_CODE_DATA_BIT1_FLAG<<6));
				 export const RF_CODE_DATA_9_FLAG= ((RF_CODE_DATA_BIT1_FLAG<<0) | (RF_CODE_DATA_BIT0_FLAG<<2) | (RF_CODE_DATA_BIT0_FLAG<<4) | (RF_CODE_DATA_BIT1_FLAG<<6));
				 export const RF_CODE_DATA_A_FLAG= ((RF_CODE_DATA_BIT0_FLAG<<0) | (RF_CODE_DATA_BIT1_FLAG<<2) | (RF_CODE_DATA_BIT0_FLAG<<4) | (RF_CODE_DATA_BIT1_FLAG<<6));
				 export const RF_CODE_DATA_B_FLAG= ((RF_CODE_DATA_BIT1_FLAG<<0) | (RF_CODE_DATA_BIT1_FLAG<<2) | (RF_CODE_DATA_BIT0_FLAG<<4) | (RF_CODE_DATA_BIT1_FLAG<<6));
				 export const RF_CODE_DATA_C_FLAG= ((RF_CODE_DATA_BIT0_FLAG<<0) | (RF_CODE_DATA_BIT0_FLAG<<2) | (RF_CODE_DATA_BIT1_FLAG<<4) | (RF_CODE_DATA_BIT1_FLAG<<6));
				 export const RF_CODE_DATA_D_FLAG= ((RF_CODE_DATA_BIT1_FLAG<<0) | (RF_CODE_DATA_BIT0_FLAG<<2) | (RF_CODE_DATA_BIT1_FLAG<<4) | (RF_CODE_DATA_BIT1_FLAG<<6));
				 export const RF_CODE_DATA_E_FLAG= ((RF_CODE_DATA_BIT0_FLAG<<0) | (RF_CODE_DATA_BIT1_FLAG<<2) | (RF_CODE_DATA_BIT1_FLAG<<4) | (RF_CODE_DATA_BIT1_FLAG<<6));
				 export const RF_CODE_DATA_F_FLAG= ((RF_CODE_DATA_BIT1_FLAG<<0) | (RF_CODE_DATA_BIT1_FLAG<<2) | (RF_CODE_DATA_BIT1_FLAG<<4) | (RF_CODE_DATA_BIT1_FLAG<<6));


				 export const MAHU_CMD_CRC_OK_FLAG	=0X0000;



				 //code export const u8 RF_CODE_DATA_FLAG ={RF_COM_CODE_SEG_FLAG_0,RF_COM_CODE_SEG_FLAG_1,RF_COM_CODE_SEG_FLAG_2,RF_COM_CODE_SEG_FLAG_3,RF_COM_CODE_SEG_FLAG_4,RF_COM_CODE_SEG_FLAG_5};
				 export const RF_CODE_DATA_FLAG={	RF_CODE_DATA_0_FLAG,RF_CODE_DATA_1_FLAG,RF_CODE_DATA_2_FLAG,RF_CODE_DATA_3_FLAG,
						 											RF_CODE_DATA_4_FLAG,RF_CODE_DATA_5_FLAG,RF_CODE_DATA_6_FLAG,RF_CODE_DATA_7_FLAG,
						 											RF_CODE_DATA_8_FLAG,RF_CODE_DATA_9_FLAG,RF_CODE_DATA_A_FLAG,RF_CODE_DATA_B_FLAG,
						 											RF_CODE_DATA_C_FLAG,RF_CODE_DATA_D_FLAG,RF_CODE_DATA_E_FLAG,RF_CODE_DATA_F_FLAG,

				 												};
				//code export const u8 RF_CODE_DATA_FLAG

				 export const RF_COM_START_FLAG ={  RF_COM_START_FLAG_0,  RF_COM_START_FLAG_1};
				//code  export const u8 RF_COM_START_FLAG

				 export const RF_COM_STOP_FLAG ={ RF_COM_STOP_FLAG_0, RF_COM_STOP_FLAG_1};
				//code  export const u8


				 //extern export const  u8 UART_COM_START_FLAG ;
				 //extern export const  u8 UART_COM_STOP_FLAG ;
				 export const UART_COM_START_FLAG_SIZE  =6;
				 export const  UART_COM_START_FLAG_0 =  0XBB;
				 export const  UART_COM_START_FLAG_1  = 0XBD;
				 export const  UART_COM_START_FLAG_2  = 0XBC;
				 export const  UART_COM_START_FLAG_3  = 0XB3;
				 export const  UART_COM_START_FLAG_4  = 0XBA;
				 export const  UART_COM_START_FLAG_5  = 0XB5;

				 export const UART_COM_STOP_FLAG_SIZE  =6;
				 export const  UART_COM_STOP_FLAG_0 =  0XDD;
				 export const  UART_COM_STOP_FLAG_1 =  0XDB;
				 export const  UART_COM_STOP_FLAG_2 =  0XDC;
				 export const  UART_COM_STOP_FLAG_3 =  0XD3;
				 export const  UART_COM_STOP_FLAG_4 =  0XDA;
				 export const  UART_COM_STOP_FLAG_5 =  0XD5;

				 export const UART_COM_START_FLAG ={ UART_COM_START_FLAG_0, UART_COM_START_FLAG_1,UART_COM_START_FLAG_2,UART_COM_START_FLAG_3,UART_COM_START_FLAG_4,UART_COM_START_FLAG_5};
				 export const UART_COM_START_FLAGE ={ UART_COM_START_FLAG_5, UART_COM_START_FLAG_4,UART_COM_START_FLAG_3,UART_COM_START_FLAG_2,UART_COM_START_FLAG_1,UART_COM_START_FLAG_0};//	 #define UART_COM_START_FLAGE  { UART_COM_START_FLAG_5, UART_COM_START_FLAG_4,UART_COM_START_FLAG_3,UART_COM_START_FLAG_2,UART_COM_START_FLAG_1,UART_COM_START_FLAG_0}
				 export const UART_COM_STOP_FLAG  ={ UART_COM_STOP_FLAG_0,UART_COM_STOP_FLAG_1,UART_COM_STOP_FLAG_2,UART_COM_STOP_FLAG_3,UART_COM_STOP_FLAG_4,UART_COM_STOP_FLAG_5};
				 export const  UART_CMD_DATA_SIZE =RF_COM_BUF_SIZE;
				 export const  UART_COM_HEADER_SIZE=	UART_COM_START_FLAG_SIZE+ UART_COM_STOP_FLAG_SIZE;
				 export const UART_COM_BUF_SIZE	=(UART_COM_START_FLAG_SIZE+UART_CMD_DATA_SIZE + UART_COM_STOP_FLAG_SIZE);

				 export const BATS_GROUP_NODE_NUM=0X20;
				 export const BATS_GROUP_CONNECT_NUM=0X4;
				 export const BATS_GROUP_NODE_ACK_TIME_BUF_SIZE=0X10;
				 export const  SOCKET_DATA_RCV_TIME_OUT =3000;
				 export const  BATS_NODE_TO_WEB_VIEW_BUF_NUM	=0X10;
				 export const  BATS_NODE_TO_WEB_VIEW_BUF_SIZE  =0X100;

				// export const MAHU_CAMER_NODES_NUM=0X20;
				 export const  CAMERS_GROUP_NODE_NUM		=MAHU_WEB_NODE_NUM;
				 export const  CAMERS_GROUP_VIEW_NUM  	=MAHU_WEB_VIEW_NUM;

				 export const CAMERS_GROUP_NODE_ACK_TIME_BUF_SIZE=0X10;
				 export const  CAMERS_NODE_TO_WEB_VIEW_BUF_NUM	=0X10;
				 export const  CAMERS_NODE_TO_WEB_VIEW_BUF_SIZE  =0X100;

				 export const  CAMERS_NODE_PIC_BUF_NUM  =0X10;
				 export const  CAMERS_VIEW_PIC_BUF_NUM  =0X10;
				 export const  CAMERS_NODE_PIC_BUF_SIZE  =( CAMERS_NODE_PIC_BUF_NUM*MAHU_PIC_DATA_BUF_SIZE) ;
				 export const  CAMERS_NODE_PIC_BUF_TIME_OUT  =( 2000) ;
				 export const  CAMERS_VIEW_PIC_BUF_SIZE  =( CAMERS_VIEW_PIC_BUF_NUM*MAHU_PIC_DATA_BUF_SIZE) ;
				 export const  CAMERS_VIEW_PIC_BUF_TIME_OUT  =( 2000) ;

				 export const MAHU_CMAER_CONNECT_NUM=0X4;
				 export const MAHU_ELF_CMAER_JPEG_BUF_SIZE = (MAHU_PIC_DATA_BUF_SIZE * CAMERS_GROUP_NODE_NUM);
				 export const    SERVER_CAMER_PIC_FILE_PATH="R:\\cy";
				 export const    SERVER_CAMER_PIC_FILE_TAIL=".jpg";
				 //20200203
				 export const MAHU_BATS_NODE_CMD_TIME_OUT	= 1000*2;
				 export const MAHU_BATS_NODE_CMD_TIME_OUT_MIN	= 400;
				 export const MAHU_BATS_NODE_CMD_TIME_OUT_OFFSET= 100;
				// export const MAHU_CMD_BUF_NUM=BATS_GROUP_NODE_NUM;
				 export const MAHU_BATS_CMD_RESEND_COUNT=4;
				 export const MAHU_LOGON_SYNC_LONG_TIME=10*1000;
				 export const MAHU_LOGON_SYNC_TIME	   =10*1000;

				 export const MAHU_CAMERS_NODE_CMD_TIME_OUT	= 1000*2;
				 export const MAHU_CAMERS_NODE_CMD_TIME_OUT_MIN	= 400;
				 export const MAHU_CAMERS_NODE_CMD_TIME_OUT_OFFSET= 100;
				// export const MAHU_CMD_BUF_NUM=CAMERS_GROUP_NODE_NUM;
				 export const MAHU_CAMERS_CMD_RESEND_COUNT=4;


				 export const IMAGE_OCR_WIDTH	=640;
				 export const IMAGE_OCR_HEIGHT	=480;
				 export const IMAGE_OCR_DEPATH	=IMAGE_OCR_HEIGHT;

				 export const IMAGE_OCR_FIGURE_WIDTH	=IMAGE_OCR_WIDTH;
				 export const IMAGE_OCR_FIGURE_HEIGHT=IMAGE_OCR_HEIGHT;
				 export const IMAGE_OCR_FIGURE_DEPATH=IMAGE_OCR_DEPATH;
				 export const IMAGE_OCR_FIGURE_CENTERX=IMAGE_OCR_WIDTH/2;
				 export const IMAGE_OCR_FIGURE_CENTERY=IMAGE_OCR_FIGURE_HEIGHT/2;
				 export const IMAGE_OCR_FIGURE_CENTERZ=IMAGE_OCR_FIGURE_DEPATH/2;

				 export const IMAGE_OCR_WINDOW_EDGE_SIZE=4;
				 export const IMAGE_OCR_WINDOW_LINE_WIDTH=3;
				export const  IMAGE_OCR_WINDOW_DRAW_GRID_WIDTH=40;
				export const  IMAGE_OCR_WINDOW_DRAW_GRID_HEIGHT=40;
				 export const   IMAGE_OCR_WINDOW_FIGURE_GRID_X_NUM =IMAGE_OCR_FIGURE_WIDTH/ IMAGE_OCR_WINDOW_DRAW_GRID_HEIGHT;
				  export const  IMAGE_OCR_WINDOW_FIGURE_GRID_Y_NUM =IMAGE_OCR_FIGURE_HEIGHT/ IMAGE_OCR_WINDOW_DRAW_GRID_WIDTH;


				  export const IMAGE_OCR_REMOTE_FIGURE_ID_NUM=256;
				  export const IMAGE_OCR_LINE_INNER_PIXS_RATE_FLAG	=200;
				 export const IMAGE_OCR_LINE_LIKE_SEG_NUM=16;
				 export const IMAGE_OCR_LINE_DIST_NUM=(4);
				 export const IMAGE_OCR_LINE_LIKE_REF_STEP=4;
				 export const IMAGE_OCR_LINE_ANGLE_NUM=(8);
				 export const IMAGE_OCR_LINE_LOOK_TABLE_SIZE=(IMAGE_OCR_LINE_ANGLE_NUM*IMAGE_OCR_LINE_DIST_NUM);
				 export const IMAGE_OCR_FIGURE_DIST_NUM=(8);
				 export const IMAGE_OCR_FIGURE_ANGLE_NUM=(8);
				 export const IMAGE_OCR_FIGURE_LOOK_TABLE_SIZE=(IMAGE_OCR_FIGURE_ANGLE_NUM*IMAGE_OCR_FIGURE_DIST_NUM);
				export const IMAGE_OCR_ROAD_NULL_FLAG=0X00;
				export const IMAGE_OCR_LINE_ID_MAX_FLAG=-1;
				export const IMAGE_OCR_ROAD_ENDGE_FLAG=0X01;
				export const IMAGE_OCR_ROAD_SELF_FLAG=0X10;
				export const IMAGE_OCR_ROAD_BLOCK_FLAG=0X20;
				export const IMAGE_OCR_ROAD_SAMPLE_BLOCK_FLAG=0X21;
				export const IMAGE_OCR_ROAD_SAMPLE_REMOTE_BLOCK_FLAG=0X22;
				export const IMAGE_OCR_ROAD_SAMPLE_LOCAL_REMOTE_BLOCK_FLAG=0X23;
				export const IMAGE_OCR_ROAD_SAMPLE_REF_BLOCK_FLAG=0X24;
				export const IMAGE_OCR_ROAD_SAMPLE_REF_COLOR_BLOCK_FLAG=0X25;
				export const IMAGE_OCR_ROAD_SAMPLE_DEBUG_PIXS_FLAG=0X26;
				export const IMAGE_OCR_ROAD_SAMPLE_DEBUG_LINE_PIXS_FLAG=0X27;
				export const IMAGE_OCR_ROAD_STEP_FLAG=0X30;
				export const IMAGE_OCR_ROAD_TIP_FLAG=0X31;
				export const IMAGE_OCR_ROAD_LINE_CROSS_FLAG=0X40;
				export const IMAGE_OCR_ROAD_AREA_FLAG=0X50;
				export const IMAGE_OCR_ROAD_ISLAND_FLAG=0X60;
				export const IMAGE_OCR_ROAD_CLOSE_LINE_FLAG=0X64;
				export const IMAGE_OCR_ROAD_SLICE_FLAG=0X68;

				export const IMAGE_OCR_BLOCK_EDGE=8;
				export const IMAGE_OCR_BLOCK_WIDTH=IMAGE_OCR_BLOCK_EDGE*2+1;
				export const IMAGE_OCR_BLOCK_HEIGHT=IMAGE_OCR_BLOCK_WIDTH;
				export const IMAGE_OCR_BLOCK_MINI_DENSITY_COUNT= IMAGE_OCR_BLOCK_EDGE/2;
				export const IMAGE_OCR_BLOCK_STEP_NUM=1024;
				export const IMAGE_OCR_COLOR_BLOCK_OUTLINE_PIXS_NUM=1024;
				export const IMAGE_OCR_COLOR_BLOCK_OUTLINE_MATRIX_EDGE_PIXS_NUM=512;
				export const IMAGE_OCR_FIRGURE_AREA_DIFF_NUM=0X10;
				export const IMAGE_OCR_FRAMES_NUM=10;
				export const  IMAGE_OCR_FIRGURE_CAMER_NUM=4;
				export const IMAGE_OCR_COLOR_BLOCK_NUM=16;

				export const IMAGE_OCR_COLOR_BLOCK_CMP_WIGHT_MAX =0X100000;
				export const IMAGE_OCR_COLOR_BLOCK_REF_SIZE=0X80*0X80;//128*128
				export const IMAGE_OCR_COLOR_BLOCK_CMP_OFFSET_X_MAX=IMAGE_OCR_WIDTH/2;
				export const IMAGE_OCR_COLOR_BLOCK_CMP_OFFSET_Y_MAX=IMAGE_OCR_HEIGHT/2;
				export const IMAGE_OCR_FIRGURE_AREA_BLOCK_PIXS_NUM=1024;
				export const IMAGE_OCR_COLOR_BLOCK_CMP_RATE_OK =200;

				export const IMAGE_OCR_FIGURE_LINE_CURVATURE_MAX=0X10000;
				export const IMAGE_OCR_FIGURE_LINE_CURVATURE_REF_MAX=IMAGE_OCR_FIGURE_LINE_CURVATURE_MAX/2;
				export const IMAGE_OCR_FIGURE_LINE_CURVATURE_DIFF_MAX=0X6000;
			//	export const IMAGE_OCR_FIGURE_LINE_CURVATURE_MAX=0X10000;




				 export const IMAGE_OCR_WINDOW_FIGURE_OFFSETX	=IMAGE_OCR_FIGURE_WIDTH/4;
				 export const IMAGE_OCR_WINDOW_FIGURE_OFFSETY	=IMAGE_OCR_FIGURE_HEIGHT/4;

					//export const  IMAGE_OCR_WINDOW_DRAW_EDGE =0X10;

				//	export const  IMAGE_OCR_WINDOW_DRAW_GRID_WIDTH=40;
				//	export const  IMAGE_OCR_WINDOW_DRAW_GRID_HEIGHT=40;
					export const  IMAGE_OCR_WINDOW_DRAW_WIDTH =IMAGE_OCR_FIGURE_WIDTH+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE;
					export const  IMAGE_OCR_WINDOW_DRAW_HEIGHT =IMAGE_OCR_FIGURE_HEIGHT+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE;

				 export const IMAGE_OCR_LINE_LIKE_RATE_CURVATURE=32;
				 export const IMAGE_OCR_LINE_LIKE_RATE_PIX_NUM=128-32;
				 export const IMAGE_OCR_LINE_LIKE_RATE_LENGTH=40;
				 export const IMAGE_OCR_LINE_LIKE_RATE_DIST=10;
				 export const IMAGE_OCR_LINE_LIKE_RATE_DIR=10;
				 export const IMAGE_OCR_LINE_LIKE_FIGURE=(IMAGE_OCR_LINE_LIKE_RATE_CURVATURE+IMAGE_OCR_LINE_LIKE_RATE_PIX_NUM) ;

				 export const IMAGE_OCR_LINES_EQUAL_FLAG=204;
				 export const IMAGE_OCR_WINDOW_LINE_PIXS_NUM=1024;
				 export const IMAGE_OCR_WINDOW_LINES_NUM=1024;
				 export const IMAGE_OCR_FIND_LINE_LIKES_NUM=256;
				 export const IMAGE_OCR_FIND_LINES_NUM =128;//FindLineSNum
				 export const IMAGE_OCR_FIND_LINE_PIX_NUM=512;// FindLinePixNum
				 export const IMAGE_OCR_FIND_LINE_PIXS_NUM_MIN=16;//
				 export const IMAGE_OCR_FIGURE_LINE_PIXS_NUM_MIN=(IMAGE_OCR_FIND_LINE_PIXS_NUM_MIN+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE);
				 export const IMAGE_OCR_LINES_END_AREA_VALUE= (4<<8);
				 export const IMAGE_OCR_FIND_LINE_CROSS_SEG_PIXS_NUM_MIN=6;

				 export const IMAGE_OCR_FIGURE_LINES_SEG_NUM=16;
				 export const IMAGE_OCR_FIGURE_LINES_NUM=256;
				 export const IMAGE_OCR_FIGURE_LINE_PIXS_NUM=512;
				 export const IMAGE_OCR_FIGURE_LINE_LIKE_WIGHT_NUM=16;
				 export const IMAGE_OCR_FIGURE_LINES_SEG_NUM_MAX=128;
				 export const IMAGE_OCR_FIGURE_LINES_NEAR_NUM=16;
				 export const IMAGE_OCR_FIGURE_LINE_LIKE_NUM=64;
				 export const IMAGE_OCR_FIGURE_LIKE_WIGHT_LINE_NUM=10;
				 export const IMAGE_OCR_FIGURE_LIKE_WIGHT_PIXS_NUM=10;
				 export const IMAGE_OCR_FIGURE_LIKE_WIGHT_SCALE_WIDTH=10;
				 export const IMAGE_OCR_FIGURE_LIKE_WIGHT_SCALE_HEIGHT=10;
				 export const IMAGE_OCR_FIGURE_LOOK_WIGHT=10;
				 export const IMAGE_OCR_FIGURE_LIKE_WIGTH_UNION_LINE=10;
				 export const IMAGE_OCR_REMOTE_FIGURE_UNION_LINE_NUM=16;
				 export const IMAGE_OCR_FIGURE_MOVE_REF_PIXS_NUM=16;
				 export const IMAGE_OCR_FIGURE_LINE_UNION_WIGHT_MAX=1024;

				 export const IMAGE_OCR_CONNECT_BLOCK_HEIGHT=3;
				 export const IMAGE_OCR_CONNECT_BLOCK_WIDTH=3;
				 export const IMAGE_OCR_CONNECT_BLOCK_ROAD_NUM =IMAGE_OCR_CONNECT_BLOCK_WIDTH*IMAGE_OCR_CONNECT_BLOCK_HEIGHT;

				export const IMAGE_OCR_WINDOW_WIDTH=IMAGE_OCR_WIDTH+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_WINDOW_HEIGHT=IMAGE_OCR_HEIGHT+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_WINDOW_DEPATH=IMAGE_OCR_DEPATH+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE;



				export const IMAGE_OCR_WINDOW_EDGE_LEFTX=IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_WINDOW_EDGE_RIGHTX=IMAGE_OCR_WINDOW_WIDTH-IMAGE_OCR_WINDOW_EDGE_SIZE-1;
				export const IMAGE_OCR_WINDOW_CENTERX=IMAGE_OCR_WINDOW_WIDTH/2;

				export const IMAGE_OCR_WINDOW_EDGE_UPY=IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_WINDOW_EDGE_DOWNY=IMAGE_OCR_WINDOW_HEIGHT-IMAGE_OCR_WINDOW_EDGE_SIZE-1;
				export const IMAGE_OCR_WINDOW_CENTERY =IMAGE_OCR_WINDOW_HEIGHT/2;


				export const IMAGE_OCR_WINDOW_EDGE_NEARZ=IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_WINDOW_EDGE_FARZ=IMAGE_OCR_WINDOW_DEPATH -IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_WINDOW_CENTERZ=IMAGE_OCR_WINDOW_DEPATH/2;


				export const IMAGE_OCR_SCENCE_SEG_WIDTH=IMAGE_OCR_WIDTH*1+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_SCENCE_SEG_HEIGHT=IMAGE_OCR_HEIGHT*1+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_SCENCE_SEG_DEPTH=IMAGE_OCR_DEPATH*1+IMAGE_OCR_WINDOW_EDGE_SIZE+IMAGE_OCR_WINDOW_EDGE_SIZE;

				export const IMAGE_OCR_SCENCE_EDGE_LEFTX=IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_SCENCE_EDGE_RIGHTX=IMAGE_OCR_SCENCE_SEG_WIDTH-IMAGE_OCR_WINDOW_EDGE_SIZE-1;
				export const IMAGE_OCR_SCENCE_CENTERX=IMAGE_OCR_SCENCE_SEG_WIDTH/2;

				export const IMAGE_OCR_SCENCE_EDGE_UPY=IMAGE_OCR_WINDOW_EDGE_SIZE;
				export const IMAGE_OCR_SCENCE_EDGE_DOWNY=IMAGE_OCR_SCENCE_SEG_HEIGHT-IMAGE_OCR_WINDOW_EDGE_SIZE-1;
				export const IMAGE_OCR_SCENCE_CENTERY=IMAGE_OCR_SCENCE_SEG_HEIGHT/2;


				export const MAHU_OCR_SCENCE_DEAPTH_DEFAULT			=1000	*MAHU_OCR_MM_RATE;//1000mm
				export const IMAGE_OCR_SCENCE_EDGE_NEARZ				=MAHU_OCR_SCENCE_DEAPTH_DEFAULT/10		;
				export const IMAGE_OCR_SCENCE_EDGE_FARZ				=MAHU_OCR_SCENCE_DEAPTH_DEFAULT*10   	;
				export const IMAGE_OCR_SCENCE_CENTERZ=MAHU_OCR_SCENCE_DEAPTH_DEFAULT/2;






				export const IMAGE_OCR_WINDOW_SLICE_NUM =256;
				export const IMAGE_OCR_WINDOW_SLICE_PIXS_NUM=32*32;
				export const IMAGE_OCR_WINDOW_SLICE_PIXS_NUM_MIN=32;
				export const IMAGE_OCR_WINDOW_SLICE_DESITY_NUM=16;
				export const IMAGE_OCR_WINDOW_SLICE_SEG_LINE_FLAG=6;
				export const IMAGE_OCR_WINDOW_SLICE_STATE_KLINE_VALUE=12*256;
				export const IMAGE_OCR_WINDOW_SLICE_STATE_KBLOCK_VALUE=6*256;
				export const IMAGE_OCR_WINDOW_SLICE_STATE_DENSITY_BLOCK_VALUE=160;
				export const IMAGE_OCR_WINDOW_SLICE_STATE_BUF_SIZE= 0X40;

				export const  IMAGE_OCR_LINE_COL_PIXS_NUM=5;
				export const  IMAGE_OCR_PIX_ROAD_DIR_NUM =5;

				export const  IMAGE_OCR_PIX_ROAD_OFFSET_MIN=1;
				export const  IMAGE_OCR_PIX_ROAD_STEP_NUM=64;

				export const  SYSTEM_RATE_DEFAULT_MAX	=256;
				export const  IMAGE_OCR_BLOCK_COL_PIXS_NUM=5;
				export const  IMAGE_OCR_BLOCK_PIX_ROAD_DIR_NUM =5;
				export const  IMAGE_OCR_BLOCK_ROAD_OFFSET_MIN=1;
				export const  IMAGE_OCR_BLOCK_PIX_ROAD_STEP_NUM=64;
				export const  IMAGE_OCR_BLOCK_COLOR_AREA_LIKE_RATE=2;
				export const  IMAGE_OCR_BLOCK_COLOR_AREA_NULL_RATE=1;
				export const  IMAGE_OCR_BLOCK_COLOR_AREA_DIFF_RATE=1;
				export const  IMAGE_OCR_BLOCK_COLOR_WIDHT_MAX=1024;///SYSTEM_RATE_DEFAULT_MAX;

				export const IMAGE_OCR_FIGURE_EDGE_LEFTX=0;
				export const IMAGE_OCR_FIGURE_EDGE_RIGHTX=IMAGE_OCR_FIGURE_WIDTH-1;
				export const IMAGE_OCR_FIGURE_EDGE_UPY=0;
				export const IMAGE_OCR_FIGURE_EDGE_DOWNY=IMAGE_OCR_FIGURE_HEIGHT-1;
				export const IMAGE_OCR_FIGURE_EDGE_NEARZ=0;
				export const IMAGE_OCR_FIGURE_EDGE_FARZ=IMAGE_OCR_FIGURE_DEPATH-1;

				export const  IMAGE_OCR_FIGURE_FIND_BLOCK_ROADS_PIXS_NUM=128;
				export const  IMAGE_OCR_FIGURE_FIND_BLOCK_PIXS_NUM=128*4;
				export const  IMAGE_OCR_FIGURE_FIND_BLOCK_SEG_PIXS_NUM=32;
				export const  IMAGE_OCR_FIGURE_FIND_BLOCK_PIXS_DIST_X_MAX=80;
				export const  IMAGE_OCR_FIGURE_FIND_BLOCK_PIXS_DIST_Y_MAX=80;
				export const  IMAGE_OCR_FIGURE_FIND_BLOCK_PIXS_DIST_POW2_MAX=(IMAGE_OCR_FIGURE_FIND_BLOCK_PIXS_DIST_X_MAX*IMAGE_OCR_FIGURE_FIND_BLOCK_PIXS_DIST_X_MAX)+(IMAGE_OCR_FIGURE_FIND_BLOCK_PIXS_DIST_Y_MAX*IMAGE_OCR_FIGURE_FIND_BLOCK_PIXS_DIST_Y_MAX);

				export const  IMAGE_OCR_FIGURE_FIND_BLOCK_COLOR_WHITE_BIT_FLAG =0X01;
				export const  IMAGE_OCR_FIGURE_FIND_BLOCK_COLOR_BLACK_BIT_FLAG =0X02;
				export const  IMAGE_OCR_PIX_DIST_MAX =0X100000;

				export const   IMAGE_FIGURE_FIND_BLOCK_STEP_MAX=32;
				export const   IMAGE_FIGURE_FIND_BLOCK_STEP_MIN=8;
				export const   IMAGE_FIGURE_FIND_BLOCK_TINY_STEP_MAX=8;
				export const   IMAGE_FIGURE_FIND_BLOCK_TINY_STEP_MIN=2;
				export const   IMAGE_FIGURE_FIND_BLOCK_STEP_NUM=(IMAGE_FIGURE_FIND_BLOCK_STEP_MAX/IMAGE_FIGURE_FIND_BLOCK_STEP_MIN)*2+1;
				export const   IMAGE_FIGURE_FIND_BLOCK_STEP_MATRIX_NUM=IMAGE_FIGURE_FIND_BLOCK_STEP_NUM*IMAGE_FIGURE_FIND_BLOCK_STEP_NUM;

				export const   IMAGE_FIGURE_FIND_BLOCK_PIXS_NUM=32*32;
				export const   IMAGE_FIGURE_FIND_BLOCK_COLOR_GRAY_DIFF_MAX=20*256;

				export const   IMAGE_FIGURE_FIND_PIX_OFFSET=IMAGE_FIGURE_FIND_BLOCK_STEP_MIN;
				export const   IMAGE_FIGURE_FIND_PIX_TINY_OFFSET=IMAGE_FIGURE_FIND_BLOCK_TINY_STEP_MIN;

				  export const  IMAGE_OCR_CLOSE_LINE_NUM = 64;
				  export const  IMAGE_OCR_CLOSE_LINE_PIXS_NUM  = 200;//256;
				  export const  IMAGE_OCR_CLOSE_LINE_LENGTH =0X10;
				  export const  IMAGE_OCR_CLOSE_LINE_WIDTH=IMAGE_OCR_CLOSE_LINE_PIXS_NUM/2;
				  export const  IMAGE_OCR_CLOSE_LINE_HEIGHT=IMAGE_OCR_CLOSE_LINE_PIXS_NUM/2;
				  export const  IMAGE_OCR_CLOSE_LINE_PIX_EDGE_FLAG=0X20;
				  export const  IMAGE_OCR_CLOSE_LINE_PIX_INNER_FLAG=0X30;
				  export const  IMAGE_OCR_CLOSE_LINE_PIX_OUTER_FLAG=0X10;
				  export const  IMAGE_OCR_CLOSE_LINE_PIX_OUTER_UNKOWN=0X0;

				   export const PI_ANGLE_NUM = 180/3.14;

				  export const  IMAGE_OCR_SCALE_SEG_RATE =32;
				  export const  IMAGE_OCR_SCALE_SEG_PIX_NUM =IMAGE_OCR_SCALE_SEG_RATE*4;
				  export const  IMAGE_OCR_SCALE_RATE_MAX=16;
				  export const  IMAGE_OCR_SCALE_RATE_MIN=16;
				  export const  IMAGE_OCR_SCALE_RATE_MAX_256=IMAGE_OCR_SCALE_RATE_MAX*256;
				  export const  IMAGE_OCR_SCALE_RATE_MIN_256=256/IMAGE_OCR_SCALE_RATE_MAX;
				  export const  IMAGE_OCR_ROTATE_AGLE_MAX_180=180;
				  export const  IMAGE_OCR_ROTATE_AGLE_MIN_180=-79;


				  export const MAHU_DB_RAM_FILE_PATH= "r:/Mahu.db";
				  export const MAHU_DB_FILE_PATH_BACKUP= "s:/kfweb/MahuLightData/MahuBackup.db";
				  export const MAHU_DB_FILE_TEMP_PATH= "r:/kfweb/MahuLightData/Mahu.db-journal";
				  export const MAHU_DB_DISK_FILE_PATH= "s:/kfweb/MahuLightData/Mahu.db";
				  export const WEB_CAT_DB_DISK_FILE_PATH= "s:/kfweb/MahuLightData/webcat.db";
				  export const MAHU_BATS_DB_DISK_FILE_PATH= "s:/kfweb/MahuLightData/MahuBats.db";
				  export const MAHU_CAMERS_DB_DISK_FILE_PATH= "s:/kfweb/MahuLightData/MahuCamers.db";

				  export const MAHU_JPEG_NET_BUF_SIZE = 0X20000;//64K
				    export const CAMER_JPEG_NET_ACK_SIZE 	=0X1000;
				    export const MAHU_ADDR_SEG			 	=0X10000;
				    export const MAHU_ADDR_MAX			 		=(0XFFF0-1);
				    export const MAHU_ADDR_INVALID		 		=(0XFFF0);
					export const MAHU_ADDR_EXT_FLAG				=0XA000;
					export const MAHU_READ_LEN_EXT_FLAG			=0XB000;
					export const MAHU_WRITE_LEN_EXT_FLAG		=0XC000;
					export const MAHU_CMD_EXT_FLAG				=0XD000;
					export const MAHU_ADDR_EXT_BIT_NUM			=12;
					export const MAHU_ADDR_EXT_MASK				=((1<<MAHU_ADDR_EXT_BIT_NUM)-1);//0XFFF	;
					export const MAHU_ADDR_NO_SEG_MAX			 =MAHU_ADDR_EXT_MASK;
					export const MAHU_ADDR_EXT_FLAG_MASK		=((~MAHU_ADDR_EXT_MASK)&0XFFFF);
				    export const MAHU_SEG_SIZE			 	=0X8000;
				    export const MAHU_SEG_BUF_SIZE				=(MAHU_SEG_SIZE+0X100);
				   // export const MAHU_SEG_MASK			 	=(MAHU_SEG_SIZE-1);
				    export const MAHU_SEG_FLAG			 	=(0X8000);
				    export const MAHU_SEG_BIT_SHIFT		 	=(5);
				    export const MAHU_SEG_RATE			 	=(1<<MAHU_SEG_BIT_SHIFT);
				    export const MAHU_SEG_SIZE_TAIL 		=(MAHU_SEG_RATE-1);
				    export const MAHU_SEG_SIZE_MASK		 	=(~MAHU_SEG_SIZE_TAIL);
				 //   export const MAHU_SEG_MASK			=~(MAHU_SEG_RATE-1);
				    export const MAHU_FRAME_SIZE		 	=(1<<(MAHU_ADDR_EXT_BIT_NUM+MAHU_ADDR_EXT_BIT_NUM));

				    export const MAHU_SEG_WRITE_SIZE	 	=(MAHU_SEG_SIZE);
				    export const MAHU_SEG_READ_TIME_OUT	 	=(100);

				    export const WEB_CLIENT_PIC_BUF_NUM	 	=(4);
				    export const WEB_LISTEN_PIC_BUF_NUM	 	=(4);

				    export const  SERVER_CAMER_PIC_TIME_OUT =1000;
				    export const  WEB_LISTEN_CAMER_PIC_TIME_OUT =800;




				    export const  MAHU_NODE_TYPE_USER=0X01;
				    export const  MAHU_NODE_TYPE_DATA_SERVER=0X02;
				    export const  MAHU_NODE_TYPE_ELF_DEFENSE_SERVER=0X03;
				    export const  MAHU_NODE_TYPE_ELF_DEFENSE=0X04;
				    export const  MAHU_NODE_TYPE_WEB=0X05;

				    export const  MAHU_ELF_GROUP_NODE_NUM=0X20;

				    export const  MAHU_NODE_ELF_DEFENSE_PORT_START=32000;
				    export const  MAHU_NODE_ELF_DEFENSE_PORT_STOP =(50000-1);
				    export const  MAHU_NODE_ELF_DEFENSE_PORT_NUM =(MAHU_NODE_ELF_DEFENSE_PORT_STOP-MAHU_NODE_ELF_DEFENSE_PORT_START);

				    export const  MAHU_NODE_ELF_DEFENSE_BUF_NUM=0X1000;

				    export const  MAHU_ELE_DEFENSE_STATE_INIT=1;
				    export const  MAHU_ELE_DEFENSE_STATE_SLEEP=2;



				    export const MAHU_CMD_JAVA_CADE_C51 =0X1000;
				    export const MAHU_CMD_WEB_MAIN_TYPE_MASK	=0XFF00;
				    export const MAHU_CMD_WEB_SUB_TYPE_MASK		=((~MAHU_CMD_WEB_MAIN_TYPE_MASK)&0XFFFF);

				    export const  	MAHU_CAR_WEB_FLAG				=			0X1000;
				    export const  	MAHU_CAR_WEB_OFF_FLAG			=	(0x01 | MAHU_CAR_WEB_FLAG) ;
				    export const  	MAHU_CAR_WEB_ONN_FLAG			=	(0x02 | MAHU_CAR_WEB_FLAG) ;
				    export const      MAHU_WEB_TO_SERVER_TIME_OUT =100;


				    export const  	MAHU_FLOWER_AIR_WEB_FLAG				=		0X1100;
				    export const  	MAHU_FLOWER_AIR_WEB_OFF_FLAG			=	(0x01 | MAHU_FLOWER_AIR_WEB_FLAG) ;
				    export const  	MAHU_FLOWER_AIR_WEB_FLOWER_ONN_FLAG		=	(0x02 | MAHU_FLOWER_AIR_WEB_FLAG) ;
				    export const  	MAHU_FLOWER_AIR_WEB_AIR_ONN_FLAG		=	(0x03 | MAHU_FLOWER_AIR_WEB_FLAG) ;

				    export const  	MAHU_TIME_DAY_MINUTE_NUM		=		24*60;////minute

				    export const  	MAHU_FLOWER_AIR_ONN_TIME_DEFAULT		=		10;//minute
				    export const  	MAHU_FLOWER_AIR_OFF_TIME_DEFAULT		=		10;//minute

				    export const  	MAHU_FLOWER_FLOWER_ONN_TIME_DEFAULT		=		10;//minute
				    export const  	MAHU_FLOWER_FLOWER_OFF_TIME_DEFAULT		=		10;//minute
				    export const  	MAHU_LIGHT_COM_CLOCK_TIME		=		(3*1000/60);//minute

			//	    export const  	 MAHU_UART_CMD_HEADER_SIZE = 6;
			//	    export const  	 MAHU_UART_CMD_TAIL_SIZE = 6;
			//	    export const  	 MAHU_UART_CMD_DATA_SIZE  =0X40;
			//	    export const  	 MAHU_UART_CMD_DATA_SIZE_MIN = 0X4;
			//	    export const  	 MAHU_UART_CMD_BUF_SIZE 	= (MAHU_UART_CMD_HEADER_SIZE+ MAHU_UART_CMD_DATA_SIZE+ MAHU_UART_CMD_TAIL_SIZE);

		//		    export const UART_COM_START_FLAG  ={  0XAA, 0XAA, 0XA1, 0XB2,0X53,0X34};//UART_CMD_HEADER_SIZE
		//		    export const UART_COM_START_FLAGE  ={0X34, 0X53, 0XB2, 0XA1, 0XAA, 0XAA,};//UART_CMD_HEADER_SIZE

		//		    export const UART_COM_STOP_FLAG  	={0X55,0X55,  0XA5,0X5A, 0XB4,0X4B};//UART_CMD_TAIL_SIZE
					 //export const	 	MAHU_BMP_HEADER_DEFAULT   =	{
								//0x42, 0x4D, 0x36,  0xA3, 0x02, 0x00, 0x00, 0x00, 0x00, 0x00, 0x36, 0x00, 0x00, 0x00, 0x28, 0x00,
								//0x00, 0x00,  0xF0, 0x00, 0x00, 0x00,  0xF0, 0x00, 0x00, 0x00, 0x01, 0x00, 0x10, 0x00, 0x00, 0x00,
						//		0x00, 0x00, 0x00,  0xA3, 0x02, 0x00,  0xC4, 0x0E, 0x00, 0x00,  0xC4, 0x0E, 0x00, 0x00, 0x00, 0x00,
							//	0x00, 0x00, 0x00, 0x00, 0x00, 0x00
								//};

//sync 20201018 Am9:32
					export const   MAHU_SYSTEM_TASK_DATE_TIME_SIZE  	=(0X10);

					  export const 	MAHU_LUX_CODE_TYPE_FLOWER		=(0x0);
					  export const 	MAHU_LUX_CODE_TYPE_AIR			=(0x1);
					  export const 	MAHU_LUX_CODE_TYPE_LIGHT		=(0x2);
					  export const 	MAHU_LUX_CODE_TYPE_DAYTIME		=(0x3);

					  export const 	MAHU_MOTO_FLOWER_CONFIG_TYPE_BITS_NUM   	=(2);
					  export const 	MAHU_MOTO_FLOWER_CONFIG_DAY_BITS_NUM   		=(7);
					  export const 	MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_BITS_NUM =(2);// 0: 1minute , 1: 30M , 2: 1h, 3: 2h
					  export const 	MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_BITS_NUM 	=(7);// 1 ~64 * 60s = 0~63 minute
					  export const 	MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_ONN_BITS_NUM 	=(2);// 0: 1minute , 1: 30M , 2: 1h, 3: 2h
					  export const 	MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_OFF_BITS_NUM 	=(2);// 0: 1minute , 1: 30M , 2: 1h, 3: 2h
					  export const 	MAHU_MOTO_FLOWER_CONFIG_CRC_BITS_NUM 		=(2);// 0: 1minute , 1: 30M , 2: 1h, 3: 2h
						export const 	LUX_CODE_CMD_BITS_SIZE  						=(33);

						export const  	MAHU_MOTO_FLOWER_CONFIG_TYPE_BIT_OFFSET 		=(0);//=(LUX_CODE_CMD_BITS_SIZE-MAHU_MOTO_FLOWER_CONFIG_TYPE_BITS_NUM);
						export const  	MAHU_MOTO_FLOWER_CONFIG_DAY_BITS_OFFSET 		=(MAHU_MOTO_FLOWER_CONFIG_TYPE_BIT_OFFSET+MAHU_MOTO_FLOWER_CONFIG_TYPE_BITS_NUM);
						export const 	MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_BITS_OFFSET	 =(MAHU_MOTO_FLOWER_CONFIG_DAY_BITS_OFFSET+MAHU_MOTO_FLOWER_CONFIG_DAY_BITS_NUM);
						export const  	MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_BITS_OFFSET	=(MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_BITS_OFFSET+MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_BITS_NUM);
						export const  	MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_ONN_BITS_OFFSET	=(MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_BITS_OFFSET+MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_BITS_NUM);
						export const  	MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_OFF_BITS_OFFSET	=(MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_ONN_BITS_OFFSET+MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_ONN_BITS_NUM);
						export const  	MAHU_MOTO_FLOWER_CONFIG_CRC_OFFSET				=(MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_OFF_BITS_OFFSET+MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_OFF_BITS_NUM);//=(MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_BITS_OFFSET+MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_BITS_NUM);



						export const  	MAHU_MOTO_FLOWER_CONFIG_TYPE_BIT_MASK				=((1<<MAHU_MOTO_FLOWER_CONFIG_TYPE_BITS_NUM)-1);
						export const  	MAHU_MOTO_FLOWER_CONFIG_DAY_BIT_MASK				=((1<<MAHU_MOTO_FLOWER_CONFIG_DAY_BITS_NUM)-1);
						export const  	MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_BIT_MASK			=((1<<MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_BITS_NUM)-1);
						export const  	MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_BIT_MASK			=((1<<MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_BITS_NUM)-1);
						export const  	MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_ONN_BIT_MASK			=((1<<MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_ONN_BITS_NUM)-1);
						export const  	MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_OFF_BIT_MASK			=((1<<MAHU_MOTO_FLOWER_CONFIG_PWM_TIME_OFF_BITS_NUM)-1);
						export const  	MAHU_MOTO_FLOWER_CONFIG_CRC_BIT_MASK			=((1<<MAHU_MOTO_FLOWER_CONFIG_CRC_BITS_NUM)-1);

						export const  	  MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_NOW_BIT_FLAG   =(0);
						export const  	  MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_30_BIT_FLAG   	=(1);
						export const  	  MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_60_BIT_FLAG   	=(2);
						export const  	  MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_180_BIT_FLAG   =(3);

						export const  MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_0   =(1);
						export const  MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_1   =(30);
						export const  MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_2   =(60);
						export const  MAHU_MOTO_FLOWER_CONFIG_DELAY_TIME_3   =(180);

						export const  MAHU_MOTO_FLOWER_CONFIG_DAY_0   =(1*MAHU_TIME_DAY_MINUTE_NUM);
						export const  MAHU_MOTO_FLOWER_CONFIG_DAY_1   =(2*MAHU_TIME_DAY_MINUTE_NUM);
						export const  MAHU_MOTO_FLOWER_CONFIG_DAY_2   =(3*MAHU_TIME_DAY_MINUTE_NUM);
						export const  MAHU_MOTO_FLOWER_CONFIG_DAY_3   =(7*MAHU_TIME_DAY_MINUTE_NUM);
						export const  MAHU_MOTO_FLOWER_CONFIG_DAY_ALL  =(0X7F);


						 export const	MAHU_MOTO_FLOWER_CONFIG_RUN_TIME_TICKET 	=  (30);


						  export const	MAHU_MOTO_FLOWER_ONN				=  (0XF6);
						  export const	MAHU_MOTO_AIR_ONN					=  (0XF7);
						  export const	MAHU_MOTO_FLOWER_AIR_ONN			=  (0XF8);
						  export const	MAHU_MOTO_TYPTE_AIR_FLAG	 		=  (0XF9);
						  export const	MAHU_MOTO_TYPTE_FLOWER_FLAG			 	=  (0XFA);
						  export const	MAHU_MOTO_FLOWER_AIR_OFF			 =  (0x00);
						  export const	MAHU_MOTO_FLOWER_AIR_ONN_TIME_OUT	=  (2000);

				 		   export const	MAHU_SYSTEM_TASK_NUM					=  (4);

						   export const	MAHU_SYSTEM_TASK_TYPE_MOTO_FLOWER	=  (0X0);
						   export const	MAHU_SYSTEM_TASK_TYPE_MOTO_AIR		=  (0X1);
						   export const	MAHU_SYSTEM_TASK_TYPE_MOTO_LIGHT	=  (0X2);


						   export const	MAHU_SYSTEM_TASK_ACTION_NULL		=  (0);
				 		   export const	MAHU_SYSTEM_TASK_ACTION_START		=  (5);

						   export const	MAHU_SYSTEM_TASK_ACTION_END			=  (10);
						   export const	MAHU_SYSTEM_TASK_DATE_TIME_NULL		=  (0XFF);



						   export const	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_NUM	=  (4);
						   export const	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_BUF_SIZE	 =  (MAHU_SYSTEM_TASK_DATE_TIME_SIZE* MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_NUM);

						   export const	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR	=  (0X4000);
						   export const	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_END_YEAR_OFFSET  =  (6);

						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_START_YEAR_OFFSET			=(0);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_START_MONTH_OFFSET 		=(1);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_START_DAY_OFFSET 			=(2);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_START_HOUR_OFFSET	 		=(3);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_START_MINUTE_OFFSET 		=(4);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_START_SECOND_OFFSET 		=(5);

						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_END_YEAR_OFFSET 			=(6);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_END_MONTH_OFFSET 			=(7);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_END_DAY_OFFSET 			=(8);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_END_HOUR_OFFSET	 		=(9);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_END_MINUTE_OFFSET 		=(10);
						   export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_END_SECOND_OFFSET 		=(11);

							export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_TASK_ONN_NUM_OFFSET	=(12);
							export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_TASK_OFF_NUM_OFFSET	=(13);
							export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_TASK_SECOND_OFFSET	=(14);
						    export const 	MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR_CRC_FLAG_OFFSET		=(15);


						   export const	MAHU_SYSTEM_CONFIG_ADDR_MOTO_AIR_TASK_ADDR		=  (MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_ADDR+MAHU_SYSTEM_TASK_DATE_TIME_SIZE *MAHU_SYSTEM_CONFIG_ADDR_MOTO_FLOWER_TASK_NUM);

				/*
				typedef struct
				{
					   byte		dstMac[MAHU_MAC_SIZE] ;//MahuConfig.MAHU_LIGHT_MAC_SIZE]
					   byte		srcMac[MAHU_MAC_SIZE] ;//MahuConfig.MAHU_LIGHT_MAC_SIZE]
					   u16		cmd ;
					   u16		addr ;
					   u16		writeLen ;
					   u16     	readLen ;
					   u16		crc ;
					   byte		dataBuf[MAHU_CMD_DATA_SIZE] ;//MAHU_CMD_DOWN_DATA_SIZE]
				} 	MahuCmdStruct;

					   	typedef struct
					{
						u8 year;	 //0
						u8 month;
						u8 day;
						u8 week;
						u8 hour;
						u8 minute;
						u8 second;

						}SystemDateTime;

					   export constMAHU_DATE_TIME_SECOND_U16MAX  0xFFF0
				 	typedef struct
					{
						u8 startYear;	 //0
						u8 startMonth;
						u8 startDay;
						u8 startHour;
						u8 startMinute;
						u8 startSecond;
						u8 endYear;
						u8 endMonth;
						u8 endDay;
						u8 endHour;
						u8 endMinute;
						u8 endSecond;//11
						u8 taskOnnNum;
						u8 taskOffNum;
						u8 taskSecond;
						u8 crc;//15

					}	 		 SystemTaskDateTime;

					   	   	typedef struct
					{
						u16 type:;
						u16 day:2;	 //0
						u16 runTime:6;
						u16 delayTime:6;
						}LuxCmdConfig;
						//*/

				 //L2107	sync	15:27 2020/11/2
					 export const	MAHU_CMD_TEMPERATURE_ADC10_VALUE_DIFF =30;
					 export const	MAHU_CMD_TEMPERATURE_SAVE_TIME_OUT=60*1000;
					  export const MAHU_TEMPERATURE_DB_RAM_FILE_PATH= "r:/Mahu_TEMPERATURE.db";
					  export const MAHU_TEMPERATURE_DB_FILE_PATH_BACKUP= "s:/kfweb/MahuLightData/Mahu_TEMPERATURE_Backup.db";
					  export const MAHU_TEMPERATURE_DB_FILE_TEMP_PATH= "r:/kfweb/MahuLightData/Mahu_TEMPERATURE.db-journal";
					  export const MAHU_TEMPERATURE_DB_DISK_FILE_PATH= "s:/kfweb/MahuLightData/Mahu_TEMPERATURE.db";

					  	 export const	MAHU_CMD_LOCKER_DEBUG_ADC10_VALUE_DIFF =30;
						 export const	MAHU_CMD_LOCKER_DEBUG_SAVE_TIME_OUT=60*1000;
						  export const MAHU_LOCKER_DEBUG_DB_RAM_FILE_PATH= "r:/Mahu_LOCKER_DEBUG.db";
						  export const MAHU_LOCKER_DEBUG_DB_FILE_PATH_BACKUP= "s:/kfweb/MahuLightData/Mahu_LOCKER_DEBUG_Backup.db";
						  export const MAHU_LOCKER_DEBUG_DB_FILE_TEMP_PATH= "r:/kfweb/MahuLightData/Mahu_LOCKER_DEBUG.db-journal";
						  export const MAHU_LOCKER_DEBUG_DB_DISK_FILE_PATH= "s:/kfweb/MahuLightData/Mahu_LOCKER_DEBUG.db";

							 export const	MAHU_CMD_SI_BAT_ADC10_VALUE_DIFF =30;
							 export const	MAHU_CMD_SI_BAT_SAVE_TIME_OUT=60*1000;
							  export const MAHU_SI_BAT_DB_RAM_FILE_PATH= "r:/Mahu_SI_BAT.db";
							  export const MAHU_SI_BAT_DB_FILE_PATH_BACKUP= "s:/kfweb/MahuLightData/Mahu_SI_BAT_Backup.db";
							  export const MAHU_SI_BAT_DB_FILE_TEMP_PATH= "r:/kfweb/MahuLightData/Mahu_SI_BAT.db-journal";
							  export const MAHU_SI_BAT_DB_DISK_FILE_PATH= "s:/kfweb/MahuLightData/Mahu_SI_BAT.db";

/*
					 export const MAHU_NTC10K_3950 = {
//温度	最小阻值	标称阻值	最大阻值
-40,373804.6,343632.6,315106.2,
-39,349255.3,321280.9,294808.3,
-38,326483.9,300533.9,275954.9,
-37,305349.9,281266,258434,
-36,285725.2,263362.4,242142.9,
-35,267492.5,246717.7,226987.5,
-34,250544.2,231235.5,212881.3,
-33,234781.8,216827.3,199745.2,
-32,220114.7,203411.8,187506.3,
-31,206460.2,190914.4,176097.8,
-30,193741.9,179266.6,165458.1,
-29,181889.9,168405.3,155530.6,
-28,170839.9,158272.6,146263.3,
-27,160532.5,148815.1,137608.2,
-26,150913.4,139983.7,129521.1,
-25,141932.3,131733.2,121961.2,
-24,133543.2,124021.6,114891,
-23,125703.3,116810.7,108275.8,
-22,118373.5,110064.8,102083.5,
-21,111517.4,103751.2,96284.6,
-20,105101.6,97839.6,90851.7,
-19,99095.2,92302,85759.6,
-18,93469.6,87112.4,80984.7,
-17,88198.4,82247.1,76505.5,
-16,83257.3,77683.7,72302,
-15,78623.6,73401.8,68355.4,
-14,74276.3,69382.3,64648.7,
-13,70196.2,65607.7,61165.8,
-12,66365.2,62061.6,57891.9,
-11,62766.6,58728.8,54813.3,
-10,59385.1,55595.3,51917.2,
-9,56206.3,52648,49191.6,
-8,53216.8,49874.7,46625.7,
-7,50404.3,47264.3,44209.1,
-6,47757.4,44806.2,41932.3,
-5,45265.3,42490.6,39786.3,
-4,42918.1,40308.6,37763.1,
-3,40706.6,38251.6,35854.7,
-2,38622.1,36311.7,34054.1,
-1,36656.7,34481.7,32354.6,
0,34802.9,32754.7,30749.9,
1,33053.7,31124.3,29234.3,
2,31402.7,29584.7,27802.2,
3,29843.7,28130.1,26448.7,
4,28371.2,26755.6,25168.9,
5,26979.9,25456.2,23958.5,
6,25664.9,24227.4,22813.3,
7,24421.5,23065,21729.4,
8,23245.5,21965,20703.2,
9,22132.9,20923.9,19731.4,
10,21079.8,19938,18810.8,
11,20082.9,19004.1,17938.4,
12,19138.7,18119.3,17111.4,
13,18244.3,17280.7,16327.1,
14,17396.7,16485.7,15583.3,
15,16593.3,15731.7,14877.5,
16,15831.5,15016.4,14207.7,
17,15108.9,14337.6,13571.8,
18,14423.3,13693.3,12967.8,
19,13772.6,13081.6,12394.2,
20,13154.9,12500.5,11849,
21,12568.3,11948.5,11330.9,
22,12011.1,11423.9,10838.3,
23,11481.6,10925.2,10369.8,
24,10978.4,10451,9924.1,
25,10500,10000,9500,
26,10053.9,9570.9,9088.4,
27,9629.1,9162.6,8696.8,
28,9224.6,8773.8,8324.3,
29,8839.3,8403.7,7969.7,
30,8472.1,8051.2,7632.1,
31,8122.2,7715.4,7310.6,
32,7788.6,7395.3,7004.4,
33,7470.5,7090.3,6712.7,
34,7167,6799.5,6434.6,
35,6877.6,6522.1,6169.6,
36,6601.3,6257.6,5916.9,
37,6337.6,6005.1,5675.8,
38,6085.8,5764.2,5445.9,
39,5845.4,5534.2,5226.5,
40,5615.7,5314.6,5017.1,
41,5396.3,5104.9,4817.1,
42,5186.5,4904.5,4626.2,
43,4986.1,4713,4443.8,
44,4794.4,4530,4269.6,
45,4611,4355.1,4103.1,
46,4435.7,4187.8,3944,
47,4267.9,4027.8,3791.8,
48,4107.3,3874.8,3646.3,
49,3953.6,3728.3,3507.2,
50,3806.4,3588.2,3374,
51,3665.5,3454,3246.6,
52,3530.5,3325.5,3124.7,
53,3401.1,3202.5,3007.9,
54,3277.2,3084.6,2896.1,
55,3158.4,2971.7,2789.1,
56,3044.5,2863.5,2686.5,
57,2935.3,2759.7,2588.2,
58,2830.5,2660.3,2494,
59,2730,2564.9,2403.7,
60,2633.6,2473.4,2317.1,
61,2541.1,2385.6,2234.1,
62,2452.3,2301.4,2154.5,
63,2367,2220.6,2078.1,
64,2285.1,2143.1,2004.8,
65,2206.5,2068.6,1934.4,
66,2130.9,1997,1866.9,
67,2058.4,1928.3,1802,
68,1988.6,1862.3,1739.7,
69,1921.6,1798.9,1679.9,
70,1857.1,1738,1622.5,
71,1795.1,1679.4,1567.2,
72,1735.5,1623.1,1514.2,
73,1678.2,1568.9,1463.1,
74,1623,1516.8,1414.1,
75,1569.9,1466.7,1366.9,
76,1518.8,1418.5,1321.5,
77,1469.7,1372.2,1277.9,
78,1422.3,1327.5,1235.9,
79,1376.7,1284.5,1195.5,
80,1332.8,1243.1,1156.6,
81,1290.5,1203.3,1119.2,
82,1249.7,1164.9,1083.1,
83,1210.5,1127.9,1048.4,
84,1172.6,1092.3,1015,
85,1136.1,1058,982.8,
86,1100.9,1024.9,951.7,
87,1067,993,921.8,
88,1034.3,962.3,893,
89,1002.8,932.6,865.2,
90,972.3,904,838.4,
91,942.9,876.4,812.6,
92,914.6,849.8,787.7,
93,887.2,824.1,763.6,
94,860.8,799.4,740.4,
95,835.3,775.4,718.1,
96,810.7,752.3,696.5,
97,786.9,730,675.6,
98,763.9,708.5,655.5,
99,741.7,687.7,636.1,
100,720.2,667.6,617.3,
101,699.5,648.2,599.2,
102,679.5,629.5,581.7,
103,660.1,611.3,564.8,
104,641.4,593.8,548.4,
105,623.2,576.9,532.6,
106,605.7,560.5,517.3,
107,588.8,544.7,502.6,
108,572.4,529.3,488.3,
109,556.5,514.5,474.5,
110,541.2,500.2,461.2,
111,526.3,486.3,448.3,
112,511.9,472.9,435.8,
113,498,459.9,423.7,
114,484.5,447.4,412,
115,471.5,435.2,400.7,
116,458.9,423.4,389.7,
117,446.6,412,379.1,
118,434.7,400.9,368.8,
119,423.3,390.2,358.9,
120,412.1,379.9,349.3,
121,401.3,369.8,339.9,
122,390.9,360.1,330.9,
123,380.7,350.6,322.1,
124,370.9,341.5,313.7,
125,361.4,332.6,305.4,
126,352.1,324,297.5,
127,343.1,315.7,289.7,
128,334.4,307.6,282.2,
129,326,299.8,275,
130,317.8,292.2,267.9,
131,309.9,284.8,261.1,
132,302.2,277.6,254.5,
133,294.7,270.7,248,
134,287.4,264,241.8,
135,280.4,257.4,235.7,
136,273.5,251.1,229.9,
137,266.9,244.9,224.2,
138,260.4,238.9,218.6,
139,254.1,233.1,213.3,
140,248,227.4,208,
141,242.1,222,203,
142,236.3,216.6,198.1,
143,230.7,211.4,193.3,
144,225.3,206.4,188.6,
145,220,201.5,184.1,
146,214.9,196.8,179.7,
147,209.9,192.2,175.5,
148,205,187.7,171.3,
149,200.3,183.3,167.3,
150,195.7,179.1,163.4,
151,191.3,174.9,159.6,
152,186.9,170.9,155.9,
153,182.7,167,152.3,
154,178.6,163.2,148.8,
155,174.6,159.5,145.4,
156,170.7,155.9,142.1,
157,166.9,152.4,138.9,
158,163.2,149,135.8,
159,159.6,145.7,132.7,
160,156.1,142.5,129.7,
161,152.7,139.4,126.9,
162,149.4,136.3,124,
163,146.2,133.3,121.3,
164,143,130.4,118.6,
165,140,127.6,116.1,
166,137,124.9,113.5,
167,134.1,122.2,111.1,
168,131.2,119.6,108.7,
169,128.5,117,106.3,
170,125.8,114.6,104.1,
171,123.2,112.1,101.9,
172,120.6,109.8,99.7,
173,118.1,107.5,97.6,
174,115.7,105.3,95.6,
175,113.3,103.1,93.6,
176,111,101,91.6,
177,108.8,98.9,89.7,
178,106.6,96.9,87.9,
179,104.4,94.9,86.1,
180,102.3,93,84.3,
181,100.3,91.1,82.6,
182,98.3,89.3,80.9,
183,96.4,87.5,79.3,
184,94.5,85.8,77.7,
185,92.6,84.1,76.2,
186,90.8,82.4,74.6,
187,89,80.8,73.2,
188,87.3,79.2,71.7,
189,85.6,77.7,70.3,
190,84,76.2,68.9,
191,82.4,74.7,67.6,
192,80.8,73.3,66.3,
193,79.3,71.9,65,
194,77.8,70.5,63.8,
195,76.4,69.2,62.5,
196,74.9,67.9,61.4,
197,73.5,66.6,60.2,
198,72.2,65.4,59.1,
199,70.9,64.2,58,
200,69.6,63,56.9
};//*/
export const MAHU_NTC10K_3950_START=-40;
export const MAHU_NTC10K_3950_END=200;

export const MAHU_NODE_CMD_BUF_NUM=0X20;
export const MAHU_VIEW_CMD_BUF_NUM=0X40;
export const MAHU_VIEW_CMD_DATA_BUF_SIZE=0X200;
export const MAHU_DOWN_BATS_CPU_FLASH_SIZE	=0X200;//4k
export const MAHU_CMD_DOWN_BATS_DATA_SIZE	=0X20;
export const MAHU_CMD_DOWN_BATS_DATA_SIZE_MASK	=(MAHU_CMD_DOWN_BATS_DATA_SIZE-1);
export const MAHU_CMD_DOWN_BATS_SEG_SIZE	=0X200;
export const MAHU_CMD_DOWN_RESEND_NUM		=4;
export const MAHU_CMD_NO_ACK=0;
export const MAHU_CMDLIGHT_FILE_NUM =0X10;
export const MAHU_CMD_DOWN_READ_BUF_FLAG=-2;
export const MAHU_CMD_DOWN_EARSE_BLOCK_FLAG=-1;
export const MAHU_CMD_DOWN_ADDR_START_FLAG=-3;
export const MAHU_ELF_DEFENSE_DATA_SEND_TIME_OUT_OFFSET=10;
export const MAHU_SI_BAT_SYSTEM_FILE					=  "sibatbootload.hex";
export const MAHU_SI_BAT_BOOT_UPDATE_SYSTEM_FILE		=  "sibatbootupdatesystem.hex";

export const	MAHU_BATS_CHECK_STATE_MES			=( MAHU_USER_LAG |0X0003 );


//  export const MAHU_CMD_BATS_DATA_BUF_SIZE	=0X40;
					 /*
						export const	 FILE_NAME_SIZE 0X10
							struct FileDir
						{
							char fileName[FILE_NAME_SIZE];
							u32   fileStartAddr;
							u32   fileSize;
							u8    id;
							u8    year;
							u8   month;
							u8   day;
							u16  dataNum;
							u16  offset;
							//u8   rsv0;
							//u8   rsv1;
							//u8   rsv2;
							//u8   crc;
						} ;
						//*/



					  //u8  fileName[FILE_NAME_SIZE];
					//	u32   fileStartAddr;
					//	u32   fileSize;
					//	u32   offset;
					//	u8    id ;
					//	u8   year;
					//	u8   month;
					//	u8   day;
				    /*
				    export const WIFI_SEND_SEG_TIME	2 //20ms

				    export const UART_RCV_FRAME_TIME_MIN   5//50ms
				    export const CAMER_JPEG_NET_BUF_SIZE 0X4000
				    export const UART_RCV_BUF_SIZE 		CAMER_JPEG_NET_BUF_SIZE
				    export const UART1_RCV_BUF_MASK (UART_RCV_BUF_SIZE -1)
				    export const CAMER_JPEG_NET_SEG_SIZE 0X1000


				    export const CAMER_JPEG_NET_ACK_SIZE 0X1000
				    export const CAMER_WEB_LOGON_TIME_OUT 10000

				    	export const CAMER_JPEG_NET_ACK_WAIT_MAX (CAMER_JPEG_NET_BUF_SIZE-CAMER_JPEG_NET_ACK_SIZE)


				    export const CAMER_UART_WAIT_TIME_OUT 4000


				    export const CAMER_JPEG_NET_ACK_SIZE 	  				0X1000
				    export const MAHU_SEG_SIZE			 					0X8000
				    export const MAHU_SEG_MASK			 					(MAHU_SEG_SIZE-1)
				    export const MAHU_SEG_FLAG			 					(0X8000)
				    export const MAHU_SEG_BIT_SHIFT		 					(5)
				    export const MAHU_SEG_RATE			 					(1<<MAHU_SEG_BIT_SHIFT)
				    export const MAHU_FRAME_SIZE		 						(MAHU_SEG_SIZE * MAHU_SEG_RATE)
				    export const MAHU_SEG_TAIL_SIZE			 				(1<<MAHU_SEG_BIT_SHIFT)
				    export const MAHU_SEG_TAIL_MASK			 				(MAHU_SEG_TAIL_SIZE-1)

				    export const   MAHU_CMD_PRID_SIZE  (MAHU_SEG_TAIL_SIZE)
				    export const   MAHU_CMD_TAIL_SIZE  (MAHU_SEG_TAIL_SIZE)
				    export const UART_RCV_TIME_OUT  50//10ms *500


				    export const UART_RCV_SEG_SIZE	0X400//10ms *500
				    export const UART_RCV_SEG_TIME	(UART_RCV_SEG_SIZE/0X200)//500= 1ms

				    //export const UART_RCV_SEG_TIME		1//((( (UART_RCV_SEG_SIZE) / (UART_BUAND_RATE/10000)) / SYSTEM_TITCK)  |1)
				    										//115200   1000/(115/10)  /10  =  90  = 9
				    										//1M	   1000/(1k/10)   /10  =  1
				    										//4M	   1000/(4k/10)   /10  =  1


				    int UartSendOver(int cmd,int readLen);

				    export const CAMER_JPEG_BUF_PRE_SIZE 0X40

				    struct CamerBuf
				    {
				    u8   CamerJpegNetBufPre[CAMER_JPEG_BUF_PRE_SIZE];
				    u8   CamerJpegNetBuf[CAMER_JPEG_NET_BUF_SIZE];
				    u8   CamerJpegNetBufTail[0X20];
				    };
				    //*/
				/*
				 export const  MahuCmdSet(byte sendBuf ,byte srcBuf ,byte dstBuf ,int cmd,int addr, int writeLen, int readLen)
				 {
					 int ii;
					 int sndCount=0,intFlag;
						for(ii=0; ii<MahuConfig.MAHU_MAC_SIZE; ii++)sendBuf[sndCount+ii]=dstBuf[ii];
						sndCount = MahuConfig.MAHU_MAC_SIZE;
						for(ii=0; ii<MahuConfig.MAHU_MAC_SIZE; ii++)sendBuf[sndCount+ii]=srcBuf[ii];
						sndCount += MahuConfig.MAHU_MAC_SIZE;

						//cmd
						intFlag =cmd ;
						sendBuf[sndCount + 0] =   (intFlag & 0XFF);	sendBuf[sndCount + 1] =   ((intFlag >> 8) & 0XFF);
						sndCount += MahuConfig.MAHU_CMD_LENGTH_SIZE;

						//addr
						intFlag =  addr;//xx
						sendBuf[sndCount + 0] =   (intFlag & 0XFF);	sendBuf[sndCount + 1] =   ((intFlag >> 8) & 0XFF);
						sndCount += MahuConfig.MAHU_CMD_ADDR_SIZE;

						//writeLen
						intFlag =  writeLen;
						sendBuf[sndCount + 0] =   (intFlag & 0XFF);	sendBuf[sndCount + 1] =   ((intFlag >> 8) & 0XFF);
						sndCount += MahuConfig.MAHU_CMD_LENGTH_SIZE;

						//readLen
						intFlag =  readLen;
						sendBuf[sndCount + 0] =   (intFlag & 0XFF);	sendBuf[sndCount + 1] =   ((intFlag >> 8) & 0XFF);
						sndCount += MahuConfig.MAHU_CMD_LENGTH_SIZE;

						//crc
						intFlag = 0;
						sendBuf[sndCount + 0] =   (intFlag & 0XFF);	sendBuf[sndCount + 1] =   ((intFlag >> 8) & 0XFF);
						sndCount += MahuConfig.MAHU_CMD_CRC_SIZE;

					 return 0;
				 }
				 */
				 export function IdToMac(macBuf, offset,id)
	 {
	 	 macBuf[offset+0]=MAHU_CMD_MAC_0;
	         macBuf[offset+1]=MAHU_CMD_MAC_1;
        	 macBuf[offset+2]=(id>>24)&0XFF;
	         macBuf[offset+3]=(id>>16)&0XFF;
	         macBuf[offset+4]=(id>>8)&0XFF;
	         macBuf[offset+5]=(id)&0XFF;
	 }

			export function 		MacToId(macBuf,offset)
					{
						let id=0;
						let rcvByte=macBuf[offset+2];
						rcvByte&=0XFF;
						id=rcvByte;

						id<<=8;
						rcvByte=macBuf[offset+3];
						rcvByte&=0XFF;
						id|=rcvByte;

						id<<=8;
						rcvByte=macBuf[offset+4];
						rcvByte&=0XFF;
						id|=rcvByte;

						id<<=8;
						rcvByte=macBuf[offset+5];
						rcvByte&=0XFF;
						id|=rcvByte;

						return id;
						}

			export function Int16ToByte(ipBuf,offset, id)
				 {

				   	ipBuf[offset+0]= (id&0XFF);
				 	id>>=8;
			        ipBuf[offset+1]= (id&0XFF);
				 	//id>>=8;
					return MAHU_INT16_SIZE;
				 }
			export function IntToByte(ipBuf,offset, id)
				 {

				   	ipBuf[offset+0]= (id&0XFF);
				 	id>>=8;
			        ipBuf[offset+1]= (id&0XFF);
				 	id>>=8;
				    ipBuf[offset+2]= (id&0XFF);
				 	id>>=8;
				    ipBuf[offset+3]= (id&0XFF);
				 	//id>>=8;
					return MAHU_INT32_SIZE;
				 }
			export function ByteToInt(ipBuf, offset)
			{
				let id=0;
					id =ipBuf[offset+3]&0XFF;
					id<<=8;
					id|=ipBuf[offset+2]&0XFF;
					id<<=8;
					id|=ipBuf[offset+1]&0XFF;
					id<<=8;
					id|=ipBuf[offset+0]&0XFF;

				return id;
			}
			export function ByteToInt16(ipBuf, offset)
			{
				let id=0;
					id|=ipBuf[offset+1]&0XFF;
					id<<=8;
					id|=ipBuf[offset+0]&0XFF;

				return id;
			}
		  export function 		IntToHexCode(id)
		  {
			  let hexBuf="";
			  let i=0,id4=0,ch=0;
			  for(i=0; i<8; i++)
			  {
				  id4= id>>((8-i)*4);
				  if(id4<10) ch='0'+id4;
				  else  ch='A'+(id4-10);
				  hexBuf+=ch;
			  }
			  return hexBuf;
		  }
     export function ByteToTime(timeByteBuf,offset)
	 {
		 	let time=0;
	 	let i;
	 	let timeByte=0;
	 	for(i=MAHU_LONG_SYSTEM_TIME_SIZE-1; i>=0; i--)
		{
			timeByte=timeByteBuf[offset+i];
			time *=256;
			time+=timeByte;
		}
		return time;
	 }
     export function TimeToByte(timeByteBuf,offset, time )
     {
      //   let  time=0;
         let i;
         let timeByte=0;
         //for(i=6; i<MAHU_LONG_SYSTEM_TIME_SIZE-1; i++)//48bit-64
		 //{
          //   timeByteBuf[i]=0;
		 //}
         timeByteBuf[MAHU_LONG_SYSTEM_TIME_SIZE-1]=0;
		 for(i=0; i<MAHU_LONG_SYSTEM_TIME_SIZE-1; i++)//52bit/8
         {
             timeByte=time%256;//timeByteBuf[offset+i];
             timeByteBuf[i]=timeByte;
             time /=  256;

         }
         return MAHU_LONG_SYSTEM_TIME_SIZE;
     }

     export function Int64ToByte(ipBuf,offset, id)
				 {
				 	let int64Str =String(id);
					let length= int64Str.length;
					let i=0,j=0,addNum=0,dig=0;
					for(j=0; j<MAHU_INT64_SIZE; j++)
					{
						ipBuf[offset+j]=0;
					}

					for(i=0; i<length; i++)
					{
						dig= int64Str.charAt(i);
						dig-='0';
						if(dig!=0)break;
					}
					for(   ; i<length; i++)
					{
						dig= int64Str.charAt(i);
						dig-='0';
						dig&=0XF;
							for(j=0; j<MAHU_INT64_SIZE; j++)
							{
								addNum=ipBuf[offset+j];
								addNum*=10;
								addNum+=dig;
								dig= addNum>>8;
								addNum&=0XFF;
								ipBuf[offset+j]=addNum;
							}
					}




					return MAHU_INT64_SIZE;
				 }
				 	export function StringToBytes ( str ) {
  let ch, st, re = 0 ;
  for (let i = 0; i < str.length; i++ ) {
    ch = str.charCodeAt(i);  // get char
    st = "" ;                 // set up "stack"
    do {
      st.push( ch & 0xFF );  // push byte to stack
      ch = ch >> 8;          // shift value down by 1 byte
    }
    while ( ch );
    // add stack contents to result
    // done because chars have "wrong" endianness
    re = re.concat( st.reverse() );
  }
  // return an array of bytes
  return re;
}

				 export const  MAHU_CMD_WEB_LIGHT_IMAGE_UPDATE_TIME_OUT= MINUTE_MS_L32*2;

     export function MahuCmdCrc(dataBuf, sndCount)
     {
         let crc16=0;
         let i=0;
         let int8Flag=0;
		 let  int16Flag=0;

         for(i=0;i<sndCount; i+=2)
         {
             int8Flag = dataBuf[i+1];
             int8Flag &=0XFF;
             int16Flag=int8Flag;
             int16Flag<<=8;
            // int16Flag&=0xff00;
             int8Flag = dataBuf[i+0];
             int8Flag &=0XFF;

             int16Flag+=int8Flag;
          //   int16Flag&=0xffff;
             crc16 += int16Flag;
             crc16&=0xffff;
         }
         crc16 =0x10000-crc16;
         crc16&=0xffff;
         return crc16;
     }

     export function MahuCmdCrcCheck(dataBuf, sndCount)
     {
         let crc16=0;
         let i=0;
         let int8Flag=0;
		 let int16Flag=0;
         for(i=0;i<sndCount; i+=2)
         {
             int8Flag = dataBuf[i+1];
             int8Flag &=0XFF;
             int16Flag=int8Flag;
             int16Flag<<=8;

             int8Flag = dataBuf[i+0];
             int8Flag &=0XFF;

             int16Flag+=int8Flag;
             int16Flag&=0XFFFF;
             crc16 += int16Flag
			 crc16 &=0XFFFF;
         }

         crc16 &=0XFFFF;
		 return crc16;
         //if(crc16==0)return SYSTEM_OK;
         //return  SYSTEM_FAIL;
     }
