import { createApp } from 'vue'
//import { createRouter } from 'vue-router'
import Vue from 'vue'
import App from './App.vue'

import NodeMessage from './components/NodeMessage.vue'
import MotoDuoji from './components/MotoDuoji.vue'
import ClearRobot from './components/ClearRobotSnow.vue'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import Route from 'element-plus'
import axios from './js/axiosInstance.js'
import markerDetail from './components/makerdetail.vue'
import  * as MahuConfig from './js/mahuconfig.js'

// for vue2

// Vue.component('markerDetail', markerDetail)
//Vue.use(markerDetail)
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
  ElCheckbox,
ElCheckboxButton,
ElCheckboxGroup
} from '@element-plus/icons-vue'

//import   * as  MahuConfig from "./js/mahuConfig"
//*
import  { WebClient,WebClientConnect,
                    WEB_MAHU_USER_LOG_ON_,
                    MahuCmdLogRecoder,
                    WebNodeSystemFilePut,
                    WebNodeSystemFileGet,
                    MahuCmdLockerDebugVlotCheck,
                    MahuCmdLockerDebugWake,
                    MahuCmdLogPlay,
                    MahuCmdLogView,
                    MahuCmdLogViewSmall,
                    WebMotoLockerTurn,
                    WebTouchRefCheck,
                    WebTouchKeyCheck,
                    WebMkwheelsTest,
                    WebSetNodeCurId,
                    WebToLightByNodeId,
                    WebMkwheelsConfig,
                  WebMotoDuojiCtrl,
                  WebSetNodeCurIdNo ,
                  WebSetNodeElfPort ,
                  WebCmdByJson,
                  WebCmdBufByJson
              } from './js/NetWebSocket'
import  './js/NetWebSocket.js'
  //*/
var  MessageTimerFreq=1000;
var LocalApp =null;



//http://test-h5-iov.pvrdsclean.cn/?id=SNOW112233445566
/*
function GetRequest() {
   const url = location.search; //获取url中"?"符后的字串
   let theRequest = new Object();
   if (url.indexOf("?") != -1) {
      let str = url.substr(1);
      strs = str.split("&");
      for(let i = 0; i < strs.length; i ++) {
         theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
      }
   }
   return theRequest;
}
var Request = GetRequest();
//*/

function getQueryVariable(variable){
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       for (let i=0;i<vars.length;i++) {
               let pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}


var WebClient2=WebClient;


//createApp(App).mount('#app')
var MessageTimeCount=0;
  console.log(" win" +window);//.webContents.session);
//const Serialport = window.require("serialport");
var GroupNodeIdBuf=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
function  MAHU_CMD_WEB_LIGHT_ID(idBuf,idCount){
let id;
  for(let i=0; i<idCount; i++){
    id=idBuf[i*4+0]&0XFF;
    id<<=8;
    id|=(idBuf[i*4+1]&0XFF) ;
    id<<=8;
    id|=(idBuf[i*4+2]&0XFF);
    id<<=8;
    id|=(idBuf[i*4+3]&0XFF);

    GroupNodeIdBuf[i]=id;
    //console.log("main " +i+":"+id.toString(16));
  }

  LocalApp.MAHU_CMD_WEB_LIGHT_ID(GroupNodeIdBuf)
}
var LaserDistCount=0;
function  MAHU_CMD_LASER_DIST(idBuf,idCount){
let id;
let pixDataOffset=0;
let frameMagic = idBuf[pixDataOffset+0]&0XFF;

console.log("frameMigc:"+frameMagic.toString(16));

// idBuf[1]=0;
let frameLen = idBuf[pixDataOffset+2]&0XFF;
console.log("frameLen:"+frameMagic.toString(16));

let version = idBuf[pixDataOffset+3]&0XFF;
let frameType = idBuf[pixDataOffset+4]&0XFF;

let cmd = idBuf[pixDataOffset+5]&0XFF;
console.log("cmd:"+cmd.toString(16));

// idBuf[6]=0;
let dataLen = idBuf[pixDataOffset+7]&0XFF;
console.log("dataLen:"+dataLen.toString(16));
//let pixCount=dataLen
pixDataOffset+=8;
let rotate = idBuf[pixDataOffset+0]&0XFF;
let rotateAngle=rotate*5;
console.log("rotate:"+rotate.toString(16)+" angle "+rotateAngle);

// zeroOffset=idBuf[10];
let zeroOffset=idBuf[pixDataOffset+2];

let startAngle=idBuf[pixDataOffset+3]&0XFF;
  startAngle<<=8;
  startAngle |=idBuf[pixDataOffset+4]&0XFF;

console.log("start angle :"+startAngle);

pixDataOffset+=5;

let pixCount= dataLen-5;
   pixCount = parseInt(pixCount/3);

//console.log("pix :"+let);
let distMes="pix "+pixCount;
let i,jj=pixCount;
if(jj>20)jj=20;

let sign=0,dist=0;
let angle =0;
let angleBuf=new Uint16Array(0X40);
let distBuf=new Uint16Array(0X40);

for(i=0; i<pixCount; i++,pixDataOffset+=3){
    sign=idBuf[pixDataOffset+0]&0XFF;
    angle=startAngle+ i *2250/pixCount ;
    angle=parseInt(angle);
    dist=idBuf[pixDataOffset+1]&0XFF;
    dist<<=8;
    dist|=idBuf[pixDataOffset+2]&0XFF;
    //dist/=4;
    dist>>=2;
    if(i<20)distMes+="("+angle+","+dist+")";
    angleBuf[i]=angle;
    distBuf[i]=dist;
  }
  console.log(distMes);

  if((LaserDistCount&0XffFF)==0){
  LocalApp.LaserDist(angleBuf,distBuf,0);
}else{
  LocalApp.LaserDist(angleBuf,distBuf,pixCount);
}
LaserDistCount++;
}

var RobotMoveDirFlag=1;
var RobotMoveDistFlag=100;
var RobotMoveAutoFlag=0;
var NodeStatePutVloteCount=0;
function MkwheelsTest(wheelNoId,dir,angle){
  if(wheelNoId>3)return ;
  if(GroupNodeIdBuf[wheelNoId]==0){
      console.log(" id =null")
      GroupNodeIdBuf[wheelNoId]=0X11;
    //return ;
  }
  if(GroupNodeIdBuf[wheelNoId]==0)return ;
  /*
  if(RobotMoveAutoFlag>0){

     if(dir==0X00){
       RobotMoveAutoFlag=0;
       LocalApp.NodeRoadAutoFlagSet(RobotMoveAutoFlag);
       WebMkwheelsTest(GroupNodeIdBuf[wheelNoId],dir,angle);
       console.log(" auto --> stop ")
     }else{
       console.log(" auto ...")
       LocalApp.NodeRoadAutoFlagSet(RobotMoveAutoFlag);
     }
     return ;
  }
  if(dir==0XF8){
    RobotMoveDirFlag=1;
  }
  if(dir==0X84){
    RobotMoveDirFlag=-1;
  }
  if( dir==0XF1 ){
      //    RobotMoveAutoFlag=4;
    //      LocalApp.NodeRoadAutoFlagSet(RobotMoveAutoFlag);
          console.log(" auto start ")
      //    return ;
  }//MAHU_LIGHT_LIGHT_AUTO
  if(dir==0){
    RobotMoveDirFlag=0;
  }
*/

  WebMkwheelsTest(GroupNodeIdBuf[wheelNoId],dir,angle);
}
function  SetNodeCurIdNo(nodeCurIdNo){
    console.log(" SetNodeCurIdNo "+nodeCurIdNo)
   WebSetNodeCurIdNo(nodeCurIdNo);
}
var MotoDuojiCtrlTimeCount=0;
const 	MOTO_AUTO_FLAG_OFFSET					=15;
const 	MOTO_AUTO_DIR_FLAG_OFFSET			=13;
const 	MOTO_AUTO_LIMIT_FLAG_OFFSET		=12;
const 	MOTO_AUTO_STOP_FLAG_OFFSET		=11;
const 	MOTO_AUTO_FAULT_FLAG_OFFSET		=10;
const 	MOTO_WEB_MESSAGE_TYPE_ALL 			=0;
const 	MOTO_WEB_MESSAGE_TYPE_CURRENT 	=2;
const 	MOTO_WEB_MESSAGE_TYPE_CURRENT_NEG 	=3;

var ElfCurrentConfig=0;
var ElfPortSetFlag=0;
var ElfPort=0;
function MotoDuojiCtrl(duojiNoId,dir,angle,bodyId){
    console.log("main moto duoji "+duojiNoId+" dir "+dir+" angle :"+angle+"body :"+bodyId)

    WebMotoDuojiCtrl(GroupNodeIdBuf[duojiNoId],dir,angle,bodyId);
    MotoDuojiCtrlTimeCount=0;
}
function SystemWebMesRcv(cmd,addr, writeLen,readLen, dataBuf , dataLen){

  console.log(" SystemWebMesRcv cmd "+cmd.toString(16)+" addr:"+addr.toString(16)+" wlen:"+writeLen.toString(16)+" rlen:"+readLen.toString(16));
  if(cmd!=MahuConfig.MAHU_CMD_JSON){
    return ;
}

 let jsonString="";
 for(let ii=0; ii<writeLen; ii++){
     jsonString+=String.fromCharCode(dataBuf[ii]);
 }
//jsonStringBuf.toString();
 console.log("rcv :"+jsonString);


 let yunCmd  =JSON.parse(jsonString);
 if(yunCmd.command=="DeviceList"){
     console.log(" list "+yunCmd.paras);
     let idList= JSON.parse(yunCmd.paras);
     let count=idList.length;
  //  LocalApp  . NodeListRefresh(idList,count);

 }
 if( yunCmd.command=="MOTO_RUN_LOG"){
   let mes=decodeURIComponent(yunCmd.paras);
   /*
   let i;
   let ch,chCode;
   for(i=0; i<mes.length; i++){
     ch= mes.charAt(i);
     if((ch=='\\')&&(mes.charAt(i+1)=='u')){
       chCode==0;;
       ch= mes.charAt(i+2);
       if((ch>='0')&& (ch<='9')) chCode+= (ch-'0');
       if((ch>='A')&& (ch<='F')) chCode+=(10+  (ch-'A'));
       if((ch>='a')&& (ch<='f')) chCode+=(10+  (ch-'a'));
       chCode<<=4;

       ch= mes.charAt(i+3);
       if((ch>='0')&& (ch<='9')) chCode+= (ch-'0');
       if((ch>='A')&& (ch<='F')) chCode+=(10+  (ch-'A'));
       if((ch>='a')&& (ch<='f')) chCode+=(10+  (ch-'a'));
       chCode<<=4;

       ch= mes.charAt(i+4);
       if((ch>='0')&& (ch<='9')) chCode+= (ch-'0');
       if((ch>='A')&& (ch<='F')) chCode+=(10+  (ch-'A'));
       if((ch>='a')&& (ch<='f')) chCode+=(10+  (ch-'a'));
       chCode<<=4;

       ch= mes.charAt(i+5);
       if((ch>='0')&& (ch<='9')) chCode+= (ch-'0');
       if((ch>='A')&& (ch<='F')) chCode+=(10+  (ch-'A'));
       if((ch>='a')&& (ch<='f')) chCode+=(10+  (ch-'a'));

       i+=6;
     }
   }
   //*/
   console.log("en log ",mes)
  LocalApp  . NodeRunLog(mes)
 }
 if( yunCmd.command=="MAHU_LIGHT_AUTO"){

       LocalApp  . NodeAutoRunCount=parseInt(yunCmd.paras);
      LocalApp  . NodeAutoRunNum=parseInt(yunCmd.state);
       return ;
  //    console.log("auto "+ auto[0]+" num "+auto[1]);
 }
 if(yunCmd.command=="MotoSetStopCurrent"){
     ElfCurrentConfig++;
     console.log("MotoSetStopCurrent  "+ElfCurrentConfig)
       LocalApp  . MkwheelsConfigGet(yunCmd.paras);
       return ;
 }
 if(yunCmd.command=="MotoRunTime")
{
  let runTimeAll=parseInt(yunCmd.paras);
  let runTime =parseInt(yunCmd.commandId);

   LocalApp  . MotoRunTime(yunCmd. state,runTimeAll,runTime);
}
 if(yunCmd.command=="ElfIpPortSet"){

     ElfPort =parseInt(yunCmd.state);
     if(ElfPort!=0){
       ElfPortSetFlag++;
     }
     console.log("MotoSetStopCurrent  "+ElfCurrentConfig)
       LocalApp  . MkwheelsConfigGet(yunCmd.paras);
        return ;
}
if(yunCmd.command=="NodeTemperCur"){
     console.log("NodeTemperCur  "+yunCmd.state)
     let tempC= parseInt(yunCmd.state)
   LocalApp  .NodeSnowSensorTemperatureCurSet(   tempC);
   return ;
}
  if(yunCmd.command=="MotoState"){
      console.log(" list "+yunCmd.paras);

      let curValue=   parseInt(yunCmd.paras);

      let state= MahuConfig.MAHU_LIGHT_OFF;
      let switchState=MahuConfig.MAHU_LIGHT_OFF;

    if(yunCmd. state=="on"){
      LocalApp  .  MotoStateRefresh2(MahuConfig.MAHU_LIGHT_ON,MahuConfig.MAHU_LIGHT_OFF,curValue)
        return ;
    }
    if(yunCmd. state=="neg"){

      LocalApp  .  MotoStateRefresh2(MahuConfig.MAHU_LIGHT_NEG,MahuConfig.MAHU_LIGHT_OFF,curValue);
      return ;
    }

    if(yunCmd. commandId=="MAHU_LIGHT_ON")  switchState= MahuConfig.MAHU_LIGHT_ON;
   if(yunCmd. commandId=="MAHU_LIGHT_NEG")  switchState= MahuConfig.MAHU_LIGHT_NEG;
   if(yunCmd. commandId=="MAHU_CURRENT_ZERO") switchState= MahuConfig.MAHU_CURRENT_ZERO;
   if(yunCmd. commandId=="MAHU_CURRENT_REST")switchState= MahuConfig.MAHU_CURRENT_REST;
  LocalApp  .  MotoStateRefresh2(MahuConfig.MAHU_LIGHT_OFF,switchState,curValue)
  //   LocalApp  . NodeListRefresh(idList,count);
  return ;
  }


 if(cmd== MahuConfig.MAHU_CMD_WEB_LIGHT_ID){
  MAHU_CMD_WEB_LIGHT_ID(dataBuf , addr);
  //LocalApp.MAHU_CMD_WEB_LIGHT_ID(dataBuf , addr);
  return ;
}
if(cmd== MahuConfig.MAHU_CONFIG_FILE_SET){
  if(writeLen<10) return;
  let   offset=0;
  console.log("value: ", dataBuf)
  let MotoFaultCurrentOver= (dataBuf[offset++]&0XFF)<<8;
      MotoFaultCurrentOver|=(dataBuf[offset++]&0XFF)<<0;
      MotoFaultCurrentOver*=8.5;
      MotoFaultCurrentOver=parseInt(MotoFaultCurrentOver);

  let MotoFaultTimeOver= (dataBuf[offset++]&0XFF)<<8;
      MotoFaultTimeOver|=(dataBuf[offset++]&0XFF)<<0;
  let MotoFaultNegCurrentOver= (dataBuf[offset++]&0XFF)<<8;
      MotoFaultNegCurrentOver|=(dataBuf[offset++]&0XFF)<<0;
      MotoFaultNegCurrentOver*=8.5;
      MotoFaultNegCurrentOver=parseInt(MotoFaultNegCurrentOver);

  let MotoStopCurrentOver= (dataBuf[offset++]&0XFF)<<8;
      MotoStopCurrentOver|=(dataBuf[offset++]&0XFF)<<0;
      MotoStopCurrentOver*=8.5;
      MotoStopCurrentOver=parseInt(MotoStopCurrentOver);

  let MotoStopTimeOver= (dataBuf[offset++]&0XFF)<<8;
      MotoStopTimeOver|=(dataBuf[offset++]&0XFF)<<0;
  let MotoAutoRunCount= (dataBuf[offset++]&0XFF)<<0;
  let MotoAutoRunCycleTime=(dataBuf[offset++]&0XFF)<<0;

   LocalApp  .MotoFaultCurrentOver=MotoFaultCurrentOver;
   LocalApp  .MotoFaultTimeOver=MotoFaultTimeOver;
   LocalApp  .MotoFaultNegCurrentOver=MotoFaultNegCurrentOver;
   LocalApp  .MotoStopCurrentOver=MotoStopCurrentOver;
   LocalApp  .MotoStopTimeOver=MotoStopTimeOver;
   LocalApp  .MotoAutoRunCount=MotoAutoRunCount;
   LocalApp  .MotoAutoRunCycleTime=MotoAutoRunCycleTime;

    LocalApp  .MkwheelsConfigSetStopLine(MotoStopCurrentOver);
  return ;
}
 if(cmd== MahuConfig.MAHU_BATS_CMD_PUT_VLOATE){

   addr, writeLen,readLen
    /*
   NodeStatePutVloteCount++;

   var dateCur= new Date();
   let minute=dateCur.getMinutes();
   if(minute<10)minute="0"+minute;
   LocalApp.SystemDateCur= dateCur.getMonth()+"."+dateCur.getDate()+","+dateCur.getHours()+":"+minute  ;

   let currentL =  dataBuf [ 8*2+1]&0XFF;
   currentL<<=8;
   currentL |= dataBuf [ 8*2+0]&0XFF;
   let currentR =  dataBuf [ 9*2+1]&0XFF;
   currentR<<=8;
   currentR |= dataBuf [ 9*2+0]&0XFF;
   currentR*=10;
   currentL*=10;
   if(currentL>currentR){

   LocalApp  .NodeCurrentNegFlag=currentR ;
   LocalApp  .MkwheelsDrawCurrent(1,currentL);
   */
   var dateCur= new Date();
   LocalApp.SystemDateCur= dateCur.getDate()+","+dateCur.getHours()+":"+dateCur.getMinutes()  ;

   LocalApp.NodeAutoRunState=addr;
   LocalApp.NodeSwitchLimitState=readLen;
   if(writeLen>2){
     let current =  dataBuf [ 0*2+1]&0XFF;
     current<<=8;
     current |= dataBuf [ 0*2+0]&0XFF;
    LocalApp  .MkwheelsDrawCurrent(1,current);
  }

 }

}

var TailAngleStart=new Uint16Array(4);//30;
var TailDir       =new Uint16Array(4);
var MotoDuojiId=0;

const MOTO_DUOJI_ANGLE_RATE_BIT_NUM =6;
const MOTO_DUOJI_ANGLE_RATE =(1<<MOTO_DUOJI_ANGLE_RATE_BIT_NUM);//64
const MOTO_DUOJI_ANGLE_90=90*MOTO_DUOJI_ANGLE_RATE;

const MOTO_DUOJI_DIR_91_135   =1;
const MOTO_DUOJI_DIR_135_91   =3;
const MOTO_DUOJI_DIR_91_89    =5;
const MOTO_DUOJI_DIR_89_45    =7;
const MOTO_DUOJI_DIR_45_89    =9;
const MOTO_DUOJI_DIR_89_91    =11;

//
var MotoDuojiAngleMinBuf= new Uint16Array(4);//{ (90-25)*MOTO_DUOJI_ANGLE_RATE,(90-30)*MOTO_DUOJI_ANGLE_RATE,(90-40)*MOTO_DUOJI_ANGLE_RATE,(90-45)*MOTO_DUOJI_ANGLE_RATE};
MotoDuojiAngleMinBuf[0]=(90-25)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMinBuf[1]=(90-30)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMinBuf[2]=(90-40)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMinBuf[3]=(90-45)*MOTO_DUOJI_ANGLE_RATE;

//var MotoDuojiAngleMaxBuf={ (90+25)*MOTO_DUOJI_ANGLE_RATE,(90+30)*MOTO_DUOJI_ANGLE_RATE,(90+40)*MOTO_DUOJI_ANGLE_RATE,(90+45)*MOTO_DUOJI_ANGLE_RATE};
var MotoDuojiAngleMaxBuf= new Uint16Array(4);
MotoDuojiAngleMaxBuf[0]=(90+25)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMaxBuf[1]=(90+30)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMaxBuf[2]=(90+40)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMaxBuf[3]=(90+45)*MOTO_DUOJI_ANGLE_RATE;


//45 -->

var MotoDuojiAngleStep=[64,64,64,64];

var AutoMoveTime=0;
var MotoDuojiAddr=0;
var AutoMoveDir=0;
var MotoDuoWriteLenRetry=0;
 var moveBuf =new Uint8Array(256*3);

function WebMotoDuojiCtrlAuto(){

  var curTime= new Date().getTime();
  let writeLen=256;
  curTime&=0X7FFFFFFF;

  if(AutoMoveTime < curTime){
    AutoMoveDir=MOTO_DUOJI_DIR_89_91;
  }
  if(MotoDuoWriteLenRetry>0){
    i =  WebToLightByNodeId(GroupNodeIdBuf[0],MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST , MotoDuojiAddr, MotoDuoWriteLenRetry, 1, moveBuf, MotoDuoWriteLenRetry, 0);
    if(i== MahuConfig.SYSTEM_OK){
      MotoDuoWriteLenRetry=0;
    }
    console.log("retry send "+MotoDuojiAddr +" next :"+AutoMoveDir+" wlen:"+MotoDuoWriteLenRetry/4);
    return ;
  }

  AutoMoveTime= curTime+20*1000;
  MotoDuojiAddr++;
  MotoDuojiAddr&=0XFFFF;
// AutoMoveDir    1         2           3       4         5       6       1
//                91->135   135->91   91->89    89->45    45->89  89->91
if(AutoMoveDir<MOTO_DUOJI_DIR_91_135  || AutoMoveDir>MOTO_DUOJI_DIR_89_91){
  AutoMoveDir=MOTO_DUOJI_DIR_89_91
}

let dir = AutoMoveDir;

let i,id =0;
let maxCountFlag=0;
let angleBuf=new Uint16Array(4);

if(dir==MOTO_DUOJI_DIR_91_135){

  angleBuf[0]= MOTO_DUOJI_ANGLE_90;
  angleBuf[1]= MOTO_DUOJI_ANGLE_90;
  angleBuf[2]= MOTO_DUOJI_ANGLE_90;
  angleBuf[3]= MOTO_DUOJI_ANGLE_90;

  for(i=0; i<128; i++){
    for(id=0; id<4; id++) {
      angleBuf[id] +=MotoDuojiAngleStep[id];
      if(angleBuf[id]>MotoDuojiAngleMaxBuf[id]){
        angleBuf[id]=MotoDuojiAngleMaxBuf[id];
        maxCountFlag|=(1<<id);
      }
      //angle /=60;--> angle /=64;

     moveBuf[i*4+id]=angleBuf[id]>>MOTO_DUOJI_ANGLE_RATE_BIT_NUM;//+tailAngleStep;
   }
   if(maxCountFlag ==0XF)break;
  }
  writeLen = i*4;
   if(maxCountFlag ==0XF){
     AutoMoveDir=MOTO_DUOJI_DIR_135_91;
   }

}

if(dir==MOTO_DUOJI_DIR_135_91){

  angleBuf[0]= MotoDuojiAngleMaxBuf[0];
  angleBuf[1]= MotoDuojiAngleMaxBuf[1];
  angleBuf[2]= MotoDuojiAngleMaxBuf[2];
  angleBuf[3]= MotoDuojiAngleMaxBuf[3];

  for(i=0; i<128; i++){
    for(id=0; id<4; id++) {
      angleBuf[id] -=MotoDuojiAngleStep[id];
      if(angleBuf[id]<MOTO_DUOJI_ANGLE_90){
        angleBuf[id]=MOTO_DUOJI_ANGLE_90;
        maxCountFlag|=(1<<id);
      }
      //angle /=60;--> angle /=64;

     moveBuf[i*4+id]=angleBuf[id]>>MOTO_DUOJI_ANGLE_RATE_BIT_NUM;//+tailAngleStep;
   }
   if(maxCountFlag ==0XF)break;
  }
  writeLen = i*4;
   if(maxCountFlag ==0XF){
     AutoMoveDir=MOTO_DUOJI_DIR_91_89;
   }
}

if(dir==MOTO_DUOJI_DIR_91_89){
    angleBuf[0]=MOTO_DUOJI_ANGLE_90;
    angleBuf[1]=MOTO_DUOJI_ANGLE_90;
    angleBuf[2]=MOTO_DUOJI_ANGLE_90;
    angleBuf[3]=MOTO_DUOJI_ANGLE_90;
    AutoMoveDir=MOTO_DUOJI_DIR_89_45;

    for(i=0; i<32*4; i++){
     moveBuf[i]=90;
   }
      writeLen = i ;

}

if(dir==MOTO_DUOJI_DIR_89_45){
angleBuf[0]= MOTO_DUOJI_ANGLE_90;
angleBuf[1]= MOTO_DUOJI_ANGLE_90;
angleBuf[2]= MOTO_DUOJI_ANGLE_90;
angleBuf[3]= MOTO_DUOJI_ANGLE_90;

for(i=0; i<128; i++){
  for(id=0; id<4; id++) {
    angleBuf[id] -=MotoDuojiAngleStep[id];
    if(angleBuf[id]<MotoDuojiAngleMinBuf[id]){
      angleBuf[id]=MotoDuojiAngleMinBuf[id];
      maxCountFlag|=(1<<id);
    }
   moveBuf[i*4+id]=angleBuf[id]>>MOTO_DUOJI_ANGLE_RATE_BIT_NUM;//+tailAngleStep;
 }
 if(maxCountFlag ==0XF)break;
}
writeLen = i*4;
 if(maxCountFlag ==0XF){
   AutoMoveDir=MOTO_DUOJI_DIR_45_89;
 }

}


if(dir==MOTO_DUOJI_DIR_45_89){
angleBuf[0]= MotoDuojiAngleMinBuf[0];
angleBuf[1]= MotoDuojiAngleMinBuf[1];
angleBuf[2]= MotoDuojiAngleMinBuf[2];
angleBuf[3]= MotoDuojiAngleMinBuf[3];

for(i=0; i<128; i++){
  for(id=0; id<4; id++) {
    angleBuf[id] +=MotoDuojiAngleStep[id];
    if(angleBuf[id]>MOTO_DUOJI_ANGLE_90){
      angleBuf[id]=MOTO_DUOJI_ANGLE_90;
      maxCountFlag|=(1<<id);
    }
   moveBuf[i*4+id]=angleBuf[id]>>MOTO_DUOJI_ANGLE_RATE_BIT_NUM;//+tailAngleStep;
 }
 if(maxCountFlag ==0XF)break;
}
writeLen = i*4;
 if(maxCountFlag ==0XF){
   AutoMoveDir=MOTO_DUOJI_DIR_89_91;
}

}


if(dir==MOTO_DUOJI_DIR_89_91){
   angleBuf[0]=MOTO_DUOJI_ANGLE_90;
   angleBuf[1]=MOTO_DUOJI_ANGLE_90;
   angleBuf[2]=MOTO_DUOJI_ANGLE_90;
   angleBuf[3]=MOTO_DUOJI_ANGLE_90;
   AutoMoveDir=MOTO_DUOJI_DIR_91_135;

   for(i=0; i<32*4; i++){
    moveBuf[i]=90;
  }
     writeLen = i;
}
TailAngleStart[0]=angleBuf[0];
TailAngleStart[1]=angleBuf[1];
TailAngleStart[2]=angleBuf[2];
TailAngleStart[3]=angleBuf[3];

//MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST 0X429

i =  WebToLightByNodeId(GroupNodeIdBuf[0],MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST , MotoDuojiAddr, writeLen, 1, moveBuf, writeLen, 0);
console.log("duojiAuto:"+MotoDuojiAddr.toString(16) +" next :"+AutoMoveDir+" wlen:"+writeLen/4+ "res :"+i);
if(i== MahuConfig.SYSTEM_OK){
    MotoDuoWriteLenRetry=0;
}  else{
    MotoDuoWriteLenRetry=writeLen;
  }


  LocalApp.BodyBoxAngleSetByAuto(TailAngleStart[0]>>MOTO_DUOJI_ANGLE_RATE_BIT_NUM,TailAngleStart[1]>>MOTO_DUOJI_ANGLE_RATE_BIT_NUM,TailAngleStart[2]>>MOTO_DUOJI_ANGLE_RATE_BIT_NUM,TailAngleStart[3]>>MOTO_DUOJI_ANGLE_RATE_BIT_NUM);
}
function MkwheelsConfig(config,configFlag){
  console.log("main",configFlag.toString(16));
  /*
  let  config={"MotoFaultCurrentOver":MotoFaultCurrentOver,
              "MotoFaultTimeOver":MotoFaultTimeOver,
              "MotoFaultNegCurrentOver":MotoFaultNegCurrentOver,
              "MotoStopCurrentOver":MotoStopCurrentOver,
              "MotoStopTimeOver":MotoStopTimeOver,
              "MotoAutoRunCount":MotoAutoRunCount,
              "MotoAutoRunCycleTime":MotoAutoRunCycleTime
             };
  */
  //let curValue = config.MotoFaultCurrentOver;
  //curValue/=8.5;
  //curValue = parseInt(curValue);

  config.MotoFaultCurrentOver = parseInt(config.MotoFaultCurrentOver/8.5);
  config.MotoFaultNegCurrentOver= parseInt(config.MotoFaultNegCurrentOver/8.5);
  config.MotoStopCurrentOver= parseInt(config.MotoStopCurrentOver/8.5);
  console.log("MotoFaultCurrentOver:",config.MotoFaultCurrentOver);
  console.log("MotoFaultTimeOver:",config.MotoFaultTimeOver);
  console.log("MotoFaultNegCurrentOver:",config.MotoFaultNegCurrentOver);
  console.log("MotoStopCurrentOver:",config.MotoStopCurrentOver);
  console.log("MotoStopTimeOver:",config.MotoStopTimeOver);
  console.log("MotoAutoRunCount:",config.MotoAutoRunCount);
  console.log("MotoAutoRunCycleTime:",config.MotoAutoRunCycleTime);

  let configBuf=new Uint8Array(0X20);

  let offset=0;
  if(config.MotoStopCurrentOver==0){
      WebMkwheelsConfig(GroupNodeIdBuf[0],configBuf,0,configFlag)
  }else{
  configBuf[offset++]=(config.MotoFaultCurrentOver>>8)&0XFF;
  configBuf[offset++]=(config.MotoFaultCurrentOver>>0)&0XFF;
  configBuf[offset++]=(config.MotoFaultTimeOver>>8)&0XFF;
  configBuf[offset++]=(config.MotoFaultTimeOver>>0)&0XFF;
  configBuf[offset++]=(config.MotoFaultNegCurrentOver>>8)&0XFF;
  configBuf[offset++]=(config.MotoFaultNegCurrentOver>>0)&0XFF;
  configBuf[offset++]=(config.MotoStopCurrentOver>>8)&0XFF;
  configBuf[offset++]=(config.MotoStopCurrentOver>>0)&0XFF;
  configBuf[offset++]=(config.MotoStopTimeOver>>8)&0XFF;
  configBuf[offset++]=(config.MotoStopTimeOver>>0)&0XFF;
  configBuf[offset++]=(config.MotoAutoRunCount>>0)&0XFF;
  configBuf[offset++]=(config.MotoAutoRunCycleTime>>0)&0XFF;
  WebMkwheelsConfig(GroupNodeIdBuf[0],configBuf,offset,configFlag)
  let  config ={

  }
}
}
function WebMotoDuojiCtrlRefresh(){
  MotoDuojiCtrlTimeCount++;
  if(MotoDuojiCtrlTimeCount>10){
    //    WebMotoDuojiCtrlAuto ();
  }
}

  var LocalDeviceSn="11";
  var LocalConfigButtonFlag=0;
function MessageTimeRefresh(){
let ret;
  MessageTimeCount++;

  if(MessageTimeCount<2){

    let mes=getQueryVariable("params"); //"%7B%22id%22%3A%22Snow_F7CCC3C10640%22%2C%22sn%22%3A0%2C%22show%22%3Atrue%2C%22devName%22%3A%22%E8%9A%8C%E5%9F%A0%20001%22%2C%22projectName%22%3A%22%E5%9B%BD%E8%83%BD%E8%9A%8C%E5%9F%A0%E4%B8%AD%E8%81%94%E6%B0%B4%E6%B3%A5%22%2C%22psName%22%3A%22%E4%B8%AD%E8%81%94%E6%B0%B4%E6%B3%A52%E8%BD%A6%E9%97%B4%22%7D";
    if( mes!=false ){
   let mesJson = JSON.parse(decodeURIComponent(mes));
      console.log(" mes decode "+mesJson)
      /*
      {
      id: '1234'
      sn: 0,
      show: true,
      devName: ‘设备名称’,
      projectName: ‘项目名称’,
      psName: ‘电站名称’
    }
    */
      if(mesJson.show==true){
          LocalApp.ConfigButtonSet(true)
      }
      LocalDeviceSn=mesJson.id
      LocalApp.  DeviceSnSet(LocalDeviceSn);
      LocalApp.projectName = mesJson.projectName
      LocalApp.psName = mesJson.psName
      LocalApp.devName = mesJson.devName

    }else{
    LocalDeviceSn=  getQueryVariable("id");
    console.log("id ="+ LocalDeviceSn);
    LocalApp.  DeviceSnSet(LocalDeviceSn);
    let aa= getQueryVariable("show");
    if(aa=="true"){
        LocalApp.ConfigButtonSet(true)
    }
}
  //  return ;
  }
    if(MessageTimeCount<100){
     ret = WebClientConnect(LocalApp,SystemWebMesRcv);
     if(ret==0)    return ;
     MessageTimeCount=100;
  }
if(MessageTimeCount<200){
  if(ElfPort==0){
        WebSetNodeElfPort(LocalDeviceSn);
        console.log(" send set node elf port ")
          return ;
      }else{
          MessageTimeCount=200;

      }
      return ;
 }


    if(MessageTimeCount<205){
        console.log(" send logon  to  elf port ")
      WEB_MAHU_USER_LOG_ON_();
      return ;
    }


    if(MessageTimerFreq==1000)  {
        MessageTimerFreq=2000;
        clearInterval(MessageTimer);
        MessageTimeCount=300;
       MessageTimer=  setInterval(  MessageTimeRefresh, MessageTimerFreq);
    }
//   if((MessageTimeCount>10) && (ElfCurrentConfig==0)){
 if(ElfCurrentConfig<5) {
          WebCmdBufByJson("MotoSetStopCurrent",null,0);
          return ;
  }
  if( (MessageTimeCount &0X07)==0){
      ret = WEB_MAHU_USER_LOG_ON_( );
}
    console.log("MessageTimeRefresh "+MessageTimeCount );
  //if(WebClient.ConnectRcvCount==0) {

  //   ClockCount=0;

  //} else   {
  //  console.log("WebClientConnect "+MessageTimeCount );
//  }

//  console.log("MessageTimeRefresh" +MessageTimeCount);

    if(LocalApp==null){
      MotoDuojiCtrlTimeCount=0;
      return;
    }
  //  if(MessageTimeCount2){
      //  LocalApp.ThreeJsInit();
//    }
  //  let xx= 29+2*Math.random();
  //  let yy= 29+2*Math.random();
  let xx= Math.random()-0.5;
  let yy= Math.random()-0.5;

  if(RobotMoveDirFlag==1){
      RobotMoveDistFlag+=10;
      if(RobotMoveDistFlag>600)RobotMoveDirFlag=-1;
  }else {
    if(RobotMoveDirFlag==-1){
        RobotMoveDistFlag-=10;
        if(RobotMoveDistFlag<10)RobotMoveDirFlag=1;
    }
  }

  LocalApp.StateMove(RobotMoveDirFlag,RobotMoveDistFlag);
//  LocalApp.SetAppEntry(ThisApp,LocalApp)
  //  LocalApp.MarkerTest((MessageTimeCount  &0X0F ),xx,yy);
}
//store.commit('Init');
console.log("app init ")
//const  ThisApp  = createApp(NodeMessage)
//const  ThisApp  = createApp(MotoDuoji)
const  ThisApp  = createApp(ClearRobot)

ThisApp.use(ElementPlus)
/*
const router = VueRouter.createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: VueRouter.createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})

ThisApp.use(router)
*/
ThisApp.component('markerDetail', markerDetail)

ThisApp.config.globalProperties.$axios=axios;
//ThisApp.config.globalProperties.$WebToNodeMessage =  WebToNodeMessage
ThisApp.config.globalProperties.$MkwheelsTest=MkwheelsTest;
ThisApp.config.globalProperties.$MotoDuojiCtrl=MotoDuojiCtrl;
ThisApp.config.globalProperties.$MkwheelsConfig=MkwheelsConfig;

ThisApp.config.globalProperties.$WebSetNodeCurIdNo=SetNodeCurIdNo;
ThisApp.config.globalProperties.$WebCmdByJson=WebCmdByJson;
ThisApp.config.globalProperties.$WebCmdBufByJson=WebCmdBufByJson;


LocalApp =ThisApp.mount('#app')

var  MessageTimer=  setInterval(  MessageTimeRefresh, MessageTimerFreq);

//const MessageAutoTimer=  setInterval(  MessageAuto, 30000);


        //      setInterval(  WebMotoDuojiCtrlRefresh, 2000);
