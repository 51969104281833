import  * as MahuConfig from './mahuconfig.js'

var  WebSendDataBuf=new Uint8Array( MahuConfig.MAHU_BUF_SIZE);
var ServerDataBuf=new Uint8Array( MahuConfig.MAHU_BUF_SIZE);
var  WebToServerSendTime=0;
var ServerCmd=0;
var ServerCmdAddr=0;
var ServerDataSendCount=0;
 var WebClient={
    ConnectSuccessCount:0,
    ConnectCount:0,
    ConnectRcvCount:0,
    socket : null,
    ServerAckTime	:0,
};
var LightSwitch2State=false;
var SystemApp=null;

var  MahuLight=
    {
        localSaveTime:0,
        localId:0X40000012	,
        localLogonTime:0,
        localIp:0XC0A80010	,
        webServer:0X00000000	,
        webServerId:MahuConfig.MAHU_CAMERS_ELF_SERVER_ID_DEFAULT	,
        webServerIp:0X00000000	,

        lightServer:0,
        lightServerId:0,

        lightCurId:0X00010001,
        lightCurIp:0X00000102,
        localSearchTime:0,
        lightIdBuf:[0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0,]	,
        lightIpBuf:[0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0,]	,
        lightTimeBuf:[0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0,]	,

    };

    var NetUserId=Math.floor((Math.random()*100)+1);
  var DemoMessage =
  	{
  			  id :0,
  		//	var mes=	new String(message);
  			time:0,
  			length:0 ,

  			 messbuf:[0,1,2,3,4,5,6,7,  0,1,2,3,4,5,6,7,  0,1,2,3,4,5,6,7,  0,1,2,3,4,5,6,7] //=new Uint8Array(0X20);

  	};
var SystemWebMesRcv=null;
  function WebClientConnect( systemApp,systemWebMesRcv ) {
    SystemApp=systemApp;
    SystemWebMesRcv=systemWebMesRcv;
     if(WebClient.ConnectRcvCount!=0){

        return WebClient.ConnectRcvCount;
      }
         if(typeof(WebSocket) == "undefined") {
          console.log("您的浏览器不支持WebSocket");
      }else{
          console.log("您的浏览器支持WebSocket");
        }
    //  var socketUrl="ws://www.mahuled.com/mahulight/MahuFenyaoWebViewListener";
      //  var socketUrl="ws://192.168.9.9/mahulight/MahuFenyaoWebViewListener";
        var host = location.hostname;
        //if(host=="localhost"){
          //   host ="120.53.226.42:5978";
          //  host ="127.0.0.1:5978";
        //120.53.226.42
      //  host="192.168.88.106:30000";
        //   host ="localhost";
        if(host=="localhost"){  host ="www.mahulight.com"; }

          if(host.search("pvrdsclean")!=-1){ 
                host = "120.53.226.42:5978";
            }
      //  let  socketUrl="ws://"+host+"/mahulight/MahuClearMotoDc30302WebViewListener";
  //     let  socketUrl="ws://"+host+"/mahulight/MahuClearMotoDc30302WebViewListener";
        let  socketUrl="ws://"+host+"/mahulight/MahuClearRobotWebViewListener";
      //  let  socketUrl="ws://"+"www.mahulight.com"+"/mahulight/MahuClearMotoAvcWebViewListener";
      //let  socketUrl="ws://192.168.20.9/mahulight/MahuMkwheelsListener";
      //  let  socketUrl="wss://"+"www.mahulight.com"+"/mahulight/MahuClearMotoAvcWebViewListener";
        //}
        //let socketUrl = location.protocol === 'https:' 	? 'wss://'+host+':8080/mahulight/MahuSiBatWebViewListener' :  'ws://'+host+'/mahulight/MahuSiBatWebViewListener';
        //var socketUrl="ws://mahuled.com/mahulight/MahuSiBatWebViewListener";
        //var socketUrl="ws://"+ MahuConfig.MAHU_SERVER_NAME+"/mahulight/MahuVoiceDistWebViewListener";
        console.log("url :"+socketUrl+"host "+host);
        if(WebClient.socket!=null)
                       {
                           WebClient.socket.close();
                           WebClient.socket=null ;
                       }
        WebClient.socket = new WebSocket(socketUrl);
        WebClient.socket.onopen = WebCientOnOpen;
        WebClient.socket.onclose = WebCientOnClose;
        WebClient.socket.onmessage = WebCientOnMessage;
        WebClient.socket.binaryType = "arraybuffer";//"arrayBuffer";
        return WebClient.ConnectRcvCount;
      }


    function WebCientOnOpen()
    {
              console.log("Info: WebSocket connection opened."+ WebClient.ConnectSuccessCount);
              WebClient.ConnectSuccessCount++;
              WebClient.ConnectRcvCount=1;

              WEB_MAHU_USER_LOG_ON_();
    }

    function WebCientOnClose()
  {

          console.log('Info: WebSocket closed.');
        //  WebToLight  ( MahuConfig.MAHU_LIGHT_DISP_DELAY,0,0,1,0,0,0);
        //  LocalToLight( MahuConfig.MAHU_LIGHT_DISP_DELAY,0,0,1,0,0,0);
          WebClient.socket=null;
          WebClient.ConnectRcvCount=0;
      //    WebClientRunDelAllTask();
      //    WebClientRunAddTask(WebClientConnect, 0,30000);

    }
    function  WebCientOnMessage(message)
    {
      var messageType =0;
        //console.log(" message typeOf(message.data) : "+typeOf(message));
        //console.log(" message typeOf(message.data) : "+message);
      //  console.log(" message typeOf(message.data) : "+message.data);
      //  console.log(" message typeOf(message.data) : "+typeOf message.data);
    //  if(typeOf message.data === String) {
      //   console.log("Received data string");

    // }



          var rcvId;
           var  aDataArray=  new Uint8Array(message.data);//message.data;

          let reader = new FileReader();
      //  var  aDataArray=  reader.readAsArrayBuffer(message.data);

          //aDataArray = aDataArray.concat(Array.from(new Uint8Array(message.data)));//= new Uint8Array(message.data);
          var length =aDataArray.length;
          var jpgLength=0,audioLength=0;
          var jpgOffset=0,audioOffset=0;
          var ii,jj;
          var rcvByte,rcvByte2;
          var clientMac;
          var cmd,cmdAck;
          var addr;
          var writeLen;
          var readLen;
          var rcvCount=0;
            var crc=0;// MahuConfig.MahuCmdCrcCheck(aDataArray,length);
          //  rcvByte
      //     console.log("aDataArray ???:"+aDataArray.toString() +" rcv en :"+length+": ");
           if(length<  MahuConfig.MAHU_CMD_HEADER_SIZE )  {
             return ;
           }
          var mes ="rcv"+length+":";
          jj=length;
          if(jj>=0X30)jj=0X30;
          for(ii=0; ii<jj; ii++)
            {
              mes+=aDataArray[ii].toString(16)+" ";
            }
            if(aDataArray[0]==123){
              console.log(" json mess "+message.data);
               SystemWebMesRcv( MahuConfig.MAHU_CMD_JSON,0,length,0,aDataArray,length);
              return ;
            }
        //    console.log(mes);
          //  SystemApp.commit('WebRcvMessage',aDataArray);
            //  WebRcvMessage(aDataArray);
            ii=1;
            if((aDataArray[0]==0X7E)&&(aDataArray[1]==0X7F))
            {
              ii=0;
            }
            if(ii==1)
            {
              return ;
            }
            rcvId=MahuConfig.MacToId(aDataArray, MahuConfig.MAHU_CMD_SRC_MAC_OFFSET);
            if(rcvId==MahuLight.localId)
            {
              //return ;
            }
                  cmd =  aDataArray [ MahuConfig.MAHU_CMD_CMD_OFFSET+1]&0XFF;
                  cmd<<=8;
                  cmd |= aDataArray [ MahuConfig.MAHU_CMD_CMD_OFFSET+0]&0XFF;

                  addr =  aDataArray [ MahuConfig.MAHU_CMD_ADDR_OFFSET+1]&0XFF;
                  addr<<=8;
                  addr |= aDataArray [ MahuConfig.MAHU_CMD_ADDR_OFFSET+0]&0XFF;

                  writeLen =  aDataArray [ MahuConfig.MAHU_CMD_WRITE_LENGTH_OFFSET+1]&0XFF;
                  writeLen<<=8;
                  writeLen |= aDataArray [ MahuConfig.MAHU_CMD_WRITE_LENGTH_OFFSET+0]&0XFF;

                  readLen =  aDataArray [ MahuConfig.MAHU_CMD_READ_LENGTH_OFFSET+1]&0XFF;
                  readLen<<=8;
                  readLen |= aDataArray [ MahuConfig.MAHU_CMD_READ_LENGTH_OFFSET+0]&0XFF;
            //      console.log(" rcv  cmaer:"+rcvId.toString(16) +" addr:" +addr.toString(16) +" writelen:"+writeLen.toString(16)+" readLen:"+readLen.toString(16));
                  cmd&= MahuConfig.MAHU_CMD_MASK;
                   if(writeLen<readLen)
                   {
                       return ;
                   }
                   if((cmd== MahuConfig.MAHU_BATS_CMD_SWITCH_LIMIT) || (cmd== MahuConfig.MAHU_BATS_CMD_CURRENT) ||(cmd== MahuConfig.MAHU_BATS_CMD_PUT_VLOATE)||(cmd== MahuConfig.MAHU_CONFIG_FILE_SET))
                   {
                     let dataLen=length -MahuConfig.MAHU_CMD_HEADER_SIZE;

                     if(dataLen>0){
                       let dataBuf=new Uint8Array(dataLen);
                       for(let i=0; i<dataLen; i++){
                         dataBuf[i]=aDataArray[MahuConfig.MAHU_CMD_HEADER_SIZE+i];
                       }
                       SystemWebMesRcv(cmd,addr, writeLen,readLen, dataBuf , dataLen);
                     }else{
                       SystemWebMesRcv(cmd,addr, writeLen,readLen, null , 0);
                     }
                      return ;
                   }
                   if((cmd== MahuConfig.MAHU_CMD_WEB_LIGHT_ID) || (cmd== MahuConfig.MAHU_CMD_LASER_DIST))
                   {
                     let dataLen=length -MahuConfig.MAHU_CMD_HEADER_SIZE;

                     if(dataLen>0){
                       let dataBuf=new Uint8Array(dataLen);
                       for(let i=0; i<dataLen; i++){
                         dataBuf[i]=aDataArray[MahuConfig.MAHU_CMD_HEADER_SIZE+i];
                       }
                       SystemWebMesRcv(cmd,addr, writeLen,readLen, dataBuf , dataLen);
                     }else{
                       SystemWebMesRcv(cmd,addr, writeLen,readLen, null , 0);
                     }

                     return ;
                   }

                   if(cmd== MahuConfig.MAHU_CMD_WEB_CHECK_LUX)
                   {
                     let vlotAdcValue=addr;
                     if(writeLen>0) {
                       vlotAdcValue= aDataArray[ MahuConfig.MAHU_CMD_HEADER_SIZE+1]&0XFF;
                       vlotAdcValue<<=8;
                       vlotAdcValue|= (aDataArray[ MahuConfig.MAHU_CMD_HEADER_SIZE+0]&0XFF);
                     }
                     SystemApp.commit('MahuLuxLightCheckAdcCur',vlotAdcValue);
                     SystemApp.commit('MahuLuxLightCheckCur',vlotAdcValue*7);
                     //SystemApp.commit('MahuLuxLightCheckRefreshtime');
                       return ;
                   }
                   if(cmd== MahuConfig.MAHU_CMD_WEB_CHECK_VOICE_DIST)
                   {
                      let vlotAdcValue= aDataArray[ MahuConfig.MAHU_CMD_HEADER_SIZE+1]&0XFF;
                      vlotAdcValue<<=8;
                      vlotAdcValue|= (aDataArray[ MahuConfig.MAHU_CMD_HEADER_SIZE+0]&0XFF);
                      let MahuVoiceDistCur= vlotAdcValue *0.34/2;
                      MahuVoiceDistCur=Math.floor(MahuVoiceDistCur);
                      SystemApp.commit('MahuVoiceDistCur',MahuVoiceDistCur);
                      SystemApp.commit('MahuVoiceDistTimeClockCountCur',vlotAdcValue);
                      //SystemApp.commit('MahuVoiceDistRefreshtime');
                        return ;
                   }

                   if(cmd== MahuConfig.MAHU_BATS_CMD_PUT_VLOATE)
                   {
                      let BatCheckStateBuf= aDataArray. slice( MahuConfig.MAHU_CMD_HEADER_SIZE, writeLen+ MahuConfig.MAHU_CMD_HEADER_SIZE);
                      SystemApp.commit('MahuConfig.MAHU_BATS_CMD_PUT_VLOATE',BatCheckStateBuf);
                      //SystemApp.commit('MahuBatVlotAdcRefreshtime');
                        return ;
                   }
                   if(cmd== MahuConfig.MAHU_CMD_WEB_CHECK_TEMPOR)
                   {
                     //v33  : 1023
                     //
                      let vlotAdcValue= aDataArray[ MahuConfig.MAHU_CMD_HEADER_SIZE+1]&0XFF;
                      vlotAdcValue<<=8;
                      vlotAdcValue|= (aDataArray[ MahuConfig.MAHU_CMD_HEADER_SIZE+0]&0XFF);


                    //  vlotValue = Vcc33 *vlotAdcValue/ 1023;
                      //tempR:10K = (Vcc33-vlotValue): vlotValue:
                      //tempR           vlotValue             1
                    //-------   =     ----------------  =   -------------------------    --
                    //  10K             Vcc33-vlotValue    Vcc33:vlotAdcValue-   1


                      //tempR =  10K*1/(Vcc33/vlotValue -1)
                      //tempR =  10K*   (Vcc33-vlotValue) /vlotValue
                      //tempR =  10K*   (Vcc33-Vcc33*1023/vlotAdcValue) / ( vcc33 *1023/vlotAdcValue)
                      //tempR =  10K*   (1-1023/vlotAdcValue) / ( 1023/vlotAdcValue)
                      //tempR =  10K*   (vlotAdcValue-1023/vlotAdcValue) / ( 1023/vlotAdcValue)

                      if(vlotAdcValue>10) {

                        let  tempR = 10000/(1023/vlotAdcValue -1);
                        let tempRi= Math.floor(tempR);
                        let j=0;
                        let tempC= MahuConfig.MAHU_NTC10K_3950_START;
                        for(j=0,tempC= MahuConfig.MAHU_NTC10K_3950_START; tempC< MahuConfig.MAHU_NTC10K_3950_END; j++,tempC++)
                        {
                            //if( ( tempR<=  MahuConfig.MAHU_NTC10K_3950[j*4+2] ) && (tempR> MahuConfig.MAHU_NTC10K_3950[j*4+4+2]))break;
                        }

                          SystemApp.commit('MahuTempertureCur',tempC);

                        }
                  //    console.log(" cmd :"+cmd.toString(16)+" vlot: "+vlot.toString(10));
                        SystemApp.commit('MahuTempertureAdcCur',vlotAdcValue);
                        SystemApp.commit('MahuTempertureAdcRefreshtime');
                          return ;
                   }
                   if(cmd== MahuConfig.MAHU_LIGHT_MES_PUT) {
                     let jsonStringBuf= aDataArray. slice( MahuConfig.MAHU_CMD_HEADER_SIZE, writeLen+ MahuConfig.MAHU_CMD_HEADER_SIZE);
           let jsonString="";
          for (ii = 0; ii < writeLen; ii++) {
            jsonString += String.fromCharCode(jsonStringBuf[ii]);
          }
          //jsonStringBuf.toString();
          console.log("rcv :"+jsonString);
        //  var ElfDefenseMessage = JSON.parse(jsonString);
        //  for(var pp in ElfDefenseMessage){
        //  console.log("key  :"+pp+" value: "+ElfDefenseMessage[pp]);
        //  SystemApp.commit(pp,ElfDefenseMessage[pp]);
        //}
        //  console.log("mes22:"+ElfDefenseMessage.BatPosGps);
              return ;
                   }
           //if(cmd==MahuConfig.MAHU_BATS_CHECK_STATE_MES)
           if(cmd==0X503)
           {
                     let jsonStringBuf= aDataArray. slice( MahuConfig.MAHU_CMD_HEADER_SIZE, writeLen+ MahuConfig.MAHU_CMD_HEADER_SIZE);
                    let jsonString="";
                    for (ii = 0; ii < writeLen; ii++) {
                      jsonString += String.fromCharCode(jsonStringBuf[ii]);
                    }
          //jsonStringBuf.toString();
                  console.log(" MahuConfig.MAHU_BATS_CHECK_STATE_MES rcv :"+jsonString);
                  if(  MahuLight.  lightCurId!=rcvId){
                  MahuLight.  lightCurId=rcvId;
                }
                  let  mes = JSON.parse(jsonString);
                  console.log("rcvId id :"+rcvId.toString(16));
                    SystemApp.commit('MahuConfig.MAHU_BATS_CHECK_STATE_MES',mes);

                    return ;
              }
              if(cmd==MahuConfig.MAHU_SENSOR_ADC_CMD_PUT_VLOATE){
                  const adcTime =ByteToTime(aDataArray,MahuConfig.MAHU_CMD_HEADER_SIZE);
                  let adcValue=0;
                  writeLen-=MahuConfig.MAHU_LONG_SYSTEM_TIME_SIZE;
                  for(i=0; i<writeLen; i++){
                      adcValue+=aDataArray[i+MahuConfig.MAHU_CMD_HEADER_SIZE+MahuConfig.MAHU_LONG_SYSTEM_TIME_SIZE];
                  }
                  adcValue/=writeLen;
                  console.log("["+adcTime.toString(16)+"]:"+adcValue+"("+writeLen+")");
                  SystemApp.NodeSystemTimerSet(adcValue);
      }

                   if(cmd== MahuConfig.MAHU_USER_ELF_DEFENSE_STATE_OK){
                     var jsonStringBuf= aDataArray. slice( MahuConfig.MAHU_CMD_HEADER_SIZE, writeLen+ MahuConfig.MAHU_CMD_HEADER_SIZE);
  			    var jsonString="";
  			   for (ii = 0; ii < writeLen; ii++) {
  				   jsonString += String.fromCharCode(jsonStringBuf[ii]);
  			   }
  			   //jsonStringBuf.toString();
  			 //  console.log("rcv :"+jsonString);
  			   var ElfDefenseMessage = JSON.parse(jsonString);
  			   //console.log("mes  :"+ElfDefenseMessage.nodeIdCur);
  		//	   console.log("mes  :"+ElfDefenseMessage.nodeIdCount+"[0]:"+ElfDefenseMessage.nodeIdBuf[0].toString(16)+"[1]:" +ElfDefenseMessage.nodeIdBuf[1].toString(16));
            return ;
                   }
                   if(cmd==MahuConfig.MAHU_CMD_WEB_MESSAGE){
                     console.log(" rcv :");
                   }
                   if(cmd==MahuConfig.MAHU_CMD_JSON){

                     let dataLen=length -MahuConfig.MAHU_CMD_HEADER_SIZE;
                     if(dataLen==0)return ;

                       let dataBuf=new Uint8Array(writeLen);
                       for(let i=0; i<writeLen; i++){
                         dataBuf[i]=aDataArray[MahuConfig.MAHU_CMD_HEADER_SIZE+i];
                       }
                SystemWebMesRcv(cmd,addr, writeLen,readLen, dataBuf , dataLen);
                   }

    }

      function WebToServer(cmd, addr, writeLen,readLen, dataBuf, dataLen, ackFlag)
      		{
      					var i,j,int8Flag,int16Flag,crc16;
      			var sndCount=0;
      			var crc;
                  if(WebClient.ConnectRcvCount==0)
                  {
                      return MahuConfig.SYSTEM_FAIL;
                  }
                  if(WebClient.socket==null)
                  {
                      WebClient.ConnectRcvCount=0;
                      return MahuConfig.SYSTEM_FAIL;
                  }
      				  var myDate = new Date();
      		   var curTime =  myDate.getTime();
      		   	// if(ServerCmd!=0)
      				// {
      				// 	if(curTime<	WebToServerSendTime)
      				// 	{
      				// 		return MahuConfig.SYSTEM_FAIL;
      				// 	}
      				// 	ServerCmd=0;
      				// }
      			if(ackFlag>0)
      			{
      				ServerCmd=cmd;
      				ServerCmdAddr=addr;
      			}
      			WebToServerSendTime= curTime +  MahuConfig.MAHU_WEB_TO_SERVER_TIME_OUT;

      			 for(i=0;i< MahuConfig.MAHU_CMD_HEADER_SIZE; i++)
      			 {
      				 WebSendDataBuf[i]=0;
      			 }


               MahuConfig.IdToMac(WebSendDataBuf,0,MahuLight.webServerId);
              MahuConfig.IdToMac(WebSendDataBuf, MahuConfig.MAHU_MAC_SIZE,MahuLight.localId);

      			 sndCount=  MahuConfig.MAHU_MAC_SIZE*2;

      			 WebSendDataBuf[sndCount+0]=(cmd&0XFF);
      			 WebSendDataBuf[sndCount+1]=((cmd>>8)&0XFF);
      			 sndCount+= MahuConfig.MAHU_CMD_SIZE;

      			 WebSendDataBuf[sndCount+0]=(addr&0XFF);
      			 WebSendDataBuf[sndCount+1]=((addr>>8)&0XFF);
      			 sndCount+= MahuConfig.MAHU_CMD_ADDR_SIZE;

      			 WebSendDataBuf[sndCount+0]=(writeLen&0XFF);
      			 WebSendDataBuf[sndCount+1]=((writeLen>>8)&0XFF);
      			 sndCount+= MahuConfig.MAHU_CMD_LENGTH_SIZE;

      			 WebSendDataBuf[sndCount+0]=(readLen&0XFF);
      			 WebSendDataBuf[sndCount+1]=((readLen>>8)&0XFF);
      			 sndCount+= MahuConfig.MAHU_CMD_LENGTH_SIZE;
                  WebSendDataBuf[sndCount+0]=0;
                  WebSendDataBuf[sndCount+1]=0;
      	 		sndCount+= MahuConfig.MAHU_CMD_CRC_SIZE;

      			if(dataLen>0)
      			{
      				for(i=0;i<dataLen; i++)
      				{
      					WebSendDataBuf[sndCount+i]=dataBuf[i];
      				}
      				sndCount+=dataLen;
      				if((sndCount&0X01)==0X01)
      				{
      					WebSendDataBuf[sndCount++]=0X00;
      				}
      			}

      			 crc16=MahuConfig.MahuCmdCrc(WebSendDataBuf,sndCount);

      			int8Flag=crc16&0XFF;

      			WebSendDataBuf[ MahuConfig.MAHU_CMD_CRC_OFFSET+0]=int8Flag;
      			int8Flag=(crc16>>8)&0XFF;
      			WebSendDataBuf[ MahuConfig.MAHU_CMD_CRC_OFFSET+1]=int8Flag;
      			 for(i=0; i<sndCount; i++)
      			 {
      				 ServerDataBuf[i]=WebSendDataBuf[i];
      			 }
      	var sendBuf=  WebSendDataBuf.subarray(0, sndCount);
      			ServerDataSendCount=sndCount;
      			//WebSendDataBuf.length= MahuConfig.MAHU_CMD_HEADER_SIZE;
      			WebClient.socket.send(sendBuf);
      						return MahuConfig.SYSTEM_OK;
      		}

var WebLightCmd=0;
var WebLightCmdAddr=0;
var WebLightId=0;
var WebToLightSendTime=0;
var WebLightDataSendCount=0;
//var  MahuConfig.MAHU_WEB_TO_LIGHT_TIME_OUT						=5000;
var WebLightDataBuf=new Uint8Array( MahuConfig.MAHU_BUF_SIZE);

          function WebToLight(cmd, addr, writeLen,readLen, dataBuf, dataLen,ackFlag)
          		{
          					var i,j,int8Flag,int16Flag,crc16;
          			var sndCount=0;
          			var crc;
                      if(WebClient.ConnectRcvCount==0)
                      {
                          return MahuConfig.SYSTEM_FAIL;
                      }
          							  var myDate = new Date();
          		   var curTime =  myDate.getTime();
          		   if(WebLightCmd!=0)
          				{
          					if(curTime<	WebToLightSendTime)
          					{
          						return MahuConfig.SYSTEM_FAIL;
          					}
          					WebLightCmd=0;
          				}
          			if(ackFlag>0)	{
          				WebLightCmd=cmd;
          				WebLightCmdAddr=addr;
          				WebLightId=MahuLight.lightCurId;//LightIdBuf[0];
          			}else	{
          				WebLightId=0;
          			}
          			WebToLightSendTime= curTime +  1000000;//MahuConfig.MAHU_WEB_TO_LIGHT_TIME_OUT;


              //  MahuLight.lightCurId=
          			 for(i=0;i< MahuConfig.MAHU_CMD_HEADER_SIZE; i++) {
          				 WebSendDataBuf[i]=0;
          			 }

                      MahuConfig.IdToMac(WebSendDataBuf,0,MahuLight.lightCurId);
                      MahuConfig.IdToMac(WebSendDataBuf, MahuConfig.MAHU_MAC_SIZE,MahuLight.localId);
                      sndCount=  MahuConfig.MAHU_MAC_SIZE*2;

          			 WebSendDataBuf[sndCount+0]=(cmd&0XFF);
          			 WebSendDataBuf[sndCount+1]=((cmd>>8)&0XFF);
          			 sndCount+= MahuConfig.MAHU_CMD_SIZE;

          			 WebSendDataBuf[sndCount+0]=(addr&0XFF);
          			 WebSendDataBuf[sndCount+1]=((addr>>8)&0XFF);
          			 sndCount+= MahuConfig.MAHU_CMD_ADDR_SIZE;

          			 WebSendDataBuf[sndCount+0]=(writeLen&0XFF);
          			 WebSendDataBuf[sndCount+1]=((writeLen>>8)&0XFF);
          			 sndCount+= MahuConfig.MAHU_CMD_LENGTH_SIZE;

          			 WebSendDataBuf[sndCount+0]=(readLen&0XFF);
          			 WebSendDataBuf[sndCount+1]=((readLen>>8)&0XFF);
          			 sndCount+= MahuConfig.MAHU_CMD_LENGTH_SIZE;
                      WebSendDataBuf[sndCount+0]=0;
                      WebSendDataBuf[sndCount+1]=0;
          	 		sndCount+= MahuConfig.MAHU_CMD_CRC_SIZE;

          			if(dataLen>0) {
          				for(i=0;i<dataLen; i++){
          					WebSendDataBuf[sndCount+i]=dataBuf[i];
          				}
          				sndCount+=dataLen;
          				if((sndCount&0X01)==0X01){
          					WebSendDataBuf[sndCount++]=0X00;
          				}
          			}
                      crc16= MahuConfig.MahuCmdCrc(WebSendDataBuf,sndCount);

          			int8Flag=crc16&0XFF;

          			WebSendDataBuf[ MahuConfig.MAHU_CMD_CRC_OFFSET+0]=int8Flag;
          			int8Flag=(crc16>>8)&0XFF;
          			WebSendDataBuf[ MahuConfig.MAHU_CMD_CRC_OFFSET+1]=int8Flag;
          			 for(i=0; i<sndCount; i++){
          				 WebLightDataBuf[i]=WebSendDataBuf[i];
          			 }
          			WebLightDataSendCount=sndCount;
          			var sendBuf=  WebSendDataBuf.subarray(0, sndCount);

          			//WebSendDataBuf.length= MahuConfig.MAHU_CMD_HEADER_SIZE;
          			WebClient.socket.send(sendBuf);
          			return MahuConfig.SYSTEM_OK;
          		}

              function WebToLightByNodeId(nodeId,cmd, addr, writeLen,readLen, dataBuf, dataLen,ackFlag)
                  {
                        var i,j,int8Flag,int16Flag,crc16;
                    var sndCount=0;
                    var crc;
                          if(WebClient.ConnectRcvCount==0)
                          {
                              return MahuConfig.SYSTEM_FAIL;
                          }
                              var myDate = new Date();
                     var curTime =  myDate.getTime();
                     if(WebLightCmd!=0)
                      {
                        if(curTime<	WebToLightSendTime)
                        {
                          return MahuConfig.SYSTEM_FAIL;
                        }
                        WebLightCmd=0;
                      }
                    if(ackFlag>0)	{
                      WebLightCmd=cmd;
                      WebLightCmdAddr=addr;
                      WebLightId=MahuLight.lightCurId;//LightIdBuf[0];
                    }else	{
                      WebLightId=0;
                    }
                    WebToLightSendTime= curTime +  1000000;//MahuConfig.MAHU_WEB_TO_LIGHT_TIME_OUT;


                  //  MahuLight.lightCurId=
                     for(i=0;i< MahuConfig.MAHU_CMD_HEADER_SIZE; i++) {
                       WebSendDataBuf[i]=0;
                     }

                          MahuConfig.IdToMac(WebSendDataBuf,0,nodeId);
                          MahuConfig.IdToMac(WebSendDataBuf, MahuConfig.MAHU_MAC_SIZE,MahuLight.localId);
                          sndCount=  MahuConfig.MAHU_MAC_SIZE*2;

                     WebSendDataBuf[sndCount+0]=(cmd&0XFF);
                     WebSendDataBuf[sndCount+1]=((cmd>>8)&0XFF);
                     sndCount+= MahuConfig.MAHU_CMD_SIZE;

                     WebSendDataBuf[sndCount+0]=(addr&0XFF);
                     WebSendDataBuf[sndCount+1]=((addr>>8)&0XFF);
                     sndCount+= MahuConfig.MAHU_CMD_ADDR_SIZE;

                     WebSendDataBuf[sndCount+0]=(writeLen&0XFF);
                     WebSendDataBuf[sndCount+1]=((writeLen>>8)&0XFF);
                     sndCount+= MahuConfig.MAHU_CMD_LENGTH_SIZE;

                     WebSendDataBuf[sndCount+0]=(readLen&0XFF);
                     WebSendDataBuf[sndCount+1]=((readLen>>8)&0XFF);
                     sndCount+= MahuConfig.MAHU_CMD_LENGTH_SIZE;
                          WebSendDataBuf[sndCount+0]=0;
                          WebSendDataBuf[sndCount+1]=0;
                    sndCount+= MahuConfig.MAHU_CMD_CRC_SIZE;

                    if(dataLen>0) {
                      for(i=0;i<dataLen; i++){
                        WebSendDataBuf[sndCount+i]=dataBuf[i];
                      }
                      sndCount+=dataLen;
                      if((sndCount&0X01)==0X01){
                        WebSendDataBuf[sndCount++]=0X00;
                      }
                    }
                          crc16= MahuConfig.MahuCmdCrc(WebSendDataBuf,sndCount);

                    int8Flag=crc16&0XFF;

                    WebSendDataBuf[ MahuConfig.MAHU_CMD_CRC_OFFSET+0]=int8Flag;
                    int8Flag=(crc16>>8)&0XFF;
                    WebSendDataBuf[ MahuConfig.MAHU_CMD_CRC_OFFSET+1]=int8Flag;
                     for(i=0; i<sndCount; i++){
                       WebLightDataBuf[i]=WebSendDataBuf[i];
                     }
                    WebLightDataSendCount=sndCount;
                    var sendBuf=  WebSendDataBuf.subarray(0, sndCount);

                    //WebSendDataBuf.length= MahuConfig.MAHU_CMD_HEADER_SIZE;
                    WebClient.socket.send(sendBuf);
                    return MahuConfig.SYSTEM_OK;
                  }

      function WEB_MAHU_USER_LOG_ON_()
                {
                    var myDate = new Date();
                    var curTime =  myDate.getTime();
                    var logonTime=0;
                    var id=0;

                    var i=0,sndCount=0;
                    var dataBuf=new Uint8Array( MahuConfig.MAHU_SSID_SIZE*3);
                    WebToServer( MahuConfig.MAHU_LIGHT_LOG_ON, MahuConfig.MAHU_NODE_TYPE_WEB_VIEW_FLAG, 0, 0, 0, 0, 0);
                    console.log("web user logon id: "+MahuLight.localId.toString(16));
                    return ;

                }
                var WebCarWheelStateFlag=0;
                function WEB_MAHU_CAR_CMD_ADJUST_(carWheelSpeed)
                          {
                              var myDate = new Date();
                              var curTime =  myDate.getTime();
                              var logonTime=0;
                              var id=0;

                              var i=0,sndCount=0;
                              var dataBuf=new Uint8Array( MahuConfig.MAHU_SSID_SIZE*3);
                              WebCarWheelStateFlag++;
                              WebCarWheelStateFlag&=0X1;

                            //  if(WebCarWheelStateFlag==0)
                          //    {
                                WebToLight( MahuConfig.MAHU_CAR_WEB_ONN_FLAG, CarWheelAddrFlag, 0, carWheelSpeed, 0, 0, 0);
                              console.log("web car onn: "+MahuLight.localId.toString(16));
                          //  }
                          //  if(WebCarWheelStateFlag==1) {
                          //    WebToServer( MahuConfig.MAHU_CAR_WEB_OFF_FLAG, 0, 0, 0, 0, 0, 0);
                          //  console.log("web car off: "+MahuLight.localId.toString(16));
                          //}
                              return ;

                          }
          function WEB_MAHU_CAR_CMD_TRUN_(carTrunFlag)
                    {
                      if(carTrunFlag==true)
                      {
                        WebToLight( MahuConfig.MAHU_CAR_WEB_ONN_FLAG, 0, 0, 0, 0, 0, 0);
                        console.log("web car onn: "+MahuLight.localId.toString(16));
                      }
                      if(carTrunFlag==false)
                      {
                        WebToLight( MahuConfig.MAHU_CAR_WEB_OFF_FLAG, 0, 0, 0, 0, 0, 0);
                        console.log("web car off: "+MahuLight.localId.toString(16));
                      }
                                    //  }
                                    //  if(WebCarWheelStateFlag==1) {
                                    //    WebToServer( MahuConfig.MAHU_CAR_WEB_OFF_FLAG, 0, 0, 0, 0, 0, 0);
                                    //  console.log("web car off: "+MahuLight.localId.toString(16));
                                    //}
                      return ;

                    }

                          function WEB_MAHU_CAR_CMD_TRUN_SINGLE(carWheelId,carTrunFlag)
                                    {
                                      console.log("   MahuConfig.MAHU_CAR_CMD_TRUN_SINGLE "+carTrunFlag);
                                    if(carTrunFlag==true)
                                    {
                                          WebToServer( MahuConfig.MAHU_CAR_WEB_ONN_FLAG, 1<<carWheelId, 0, 0, 0, 0, 0);
                                        console.log("web car onn: "+MahuLight.localId.toString(16));
                                    }
                                    if(carTrunFlag==false)
                                    {
                                        WebToServer( MahuConfig.MAHU_CAR_WEB_OFF_FLAG, 1<<carWheelId, 0, 0, 0, 0, 0);
                                        console.log("web car off: "+MahuLight.localId.toString(16));
                                    }
                                    //  }
                                    //  if(WebCarWheelStateFlag==1) {
                                    //    WebToServer( MahuConfig.MAHU_CAR_WEB_OFF_FLAG, 0, 0, 0, 0, 0, 0);
                                    //  console.log("web car off: "+MahuLight.localId.toString(16));
                                    //}
                                        return ;

                                    }

function WebFlowerSwitch(FlowerSwitchState)
{
  console.log("WebFlowerSwitch: "+FlowerSwitchState);

      if(FlowerSwitchState)
      {
        WebToLight( MahuConfig.MAHU_FLOWER_AIR_WEB_FLOWER_ONN_FLAG,  MahuConfig.MAHU_FLOWER_FLOWER_ONN_TIME_DEFAULT, 0,  MahuConfig.MAHU_FLOWER_FLOWER_OFF_TIME_DEFAULT, 0, 0, 0);
      }
      else
      {
        WebToLight( MahuConfig.MAHU_FLOWER_AIR_WEB_OFF_FLAG, 0, 0, 0, 0, 0, 0);
      }
}
function WebSiBatChargeTurn(state)
{
  console.log("WebSiBatChargeTurn: "+state);
WebToLight( MahuConfig.MAHU_BATS_CMD_POWER_GND, state, 0, 0, 0, 0, 0);

}
function WebMotoTurn(state)
{
  console.log("WebSiBatChargeTurn: "+state);
  WebToLight( MahuConfig.MAHU_LIGHTT, state, 0, 0, 0, 0, 0);

}
function WebAirSwitch(AirSwitchState)
{
  console.log("WebAirSwitch: "+AirSwitchState);
      if(AirSwitchState)
      {
        WebToLight( MahuConfig.MAHU_FLOWER_AIR_WEB_AIR_ONN_FLAG,  MahuConfig.MAHU_FLOWER_AIR_ONN_TIME_DEFAULT, 0,  MahuConfig.MAHU_FLOWER_AIR_OFF_TIME_DEFAULT, 0, 0, 0);
      }
      else
      {
        WebToLight( MahuConfig.MAHU_FLOWER_AIR_WEB_OFF_FLAG, 0, 0, 0, 0, 0, 0);
      }
}
function WebLightSwitch(LightSwitchState)
{
  console.log("WebLightSwitch: "+LightSwitchState);

      if(LightSwitchState)
      {
        WebToLight( MahuConfig.MAHU_LIGHTT,  MahuConfig.MAHU_LIGHTT_ON, 0,  MahuConfig.MAHU_FLOWER_FLOWER_OFF_TIME_DEFAULT, 0, 0, 0);
      }
      else
      {
        WebToLight( MahuConfig.MAHU_LIGHTT,  MahuConfig.MAHU_LIGHTT_OFF, 0, 0, 0, 0, 0);
      }
}
function WebLuxLightCheck()
{
//  console.log("WebLuxLightCheck: "+LightSwitchState);

    //  if(LightSwitchState)
    //  {
        WebToLight( MahuConfig.MAHU_CMD_WEB_CHECK_LUX, 0, 0, 0, 0, 0, 0);
    //  }
  //    else
    //  {
    //    WebToLight( MahuConfig.MAHU_LIGHTT,  MahuConfig.MAHU_LIGHTT_OFF, 0, 0, 0, 0, 0);
    //  }
}
const MOTO_STEPY28_CYCLE_STEP_NUM   =(4096/2);
const MOTO_STEPY28_CYCLE_STEP_NUM_BYANGLE   =6;//(4096/2)/180;
var WebMotoStep28YRotateAngleSendTimeOut= 0;


function WebMkwheelsTest(wheelId,dir,angle)
{
    var curTime= new Date().getTime();
    curTime&=0X7FFFFFFF;
    if(curTime!=0)return ;
    if(curTime<WebMotoStep28YRotateAngleSendTimeOut)
    {
      console.log("WebMkwheelsTest too fast: "+(WebMotoStep28YRotateAngleSendTimeOut-curTime));
      return ;
    }
    WebMotoStep28YRotateAngleSendTimeOut = curTime ;
    WebMotoStep28YRotateAngleSendTimeOut+=100;

  //  if(angle<-180) angle= -18000;
  //  if(angle> 180) angle=  18000;
    let step = angle * MOTO_STEPY28_CYCLE_STEP_NUM_BYANGLE;
    console.log("WebMkwheelsTest "+wheelId.toString(16)+" dir "+dir + "angle :"+ angle);
    //WebToLight( MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST, dir, 0, angle, 0, 0, 0);
    WebToLightByNodeId(wheelId, MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST, dir, 0, angle, 0, 0, 0);
}
function WebMkwheelsConfig(wheelId,configBuf,configSize,configFlag){
  console.log("WebMkwheelsConfig ",configBuf,configSize,configFlag.toString(16));
   if(configSize>0){
      WebToLightByNodeId(wheelId, MahuConfig.MAHU_CONFIG_FILE_SET, configFlag, configSize, 0, configBuf, configSize, 0);
   }else{
     WebToLightByNodeId(wheelId, MahuConfig.MAHU_CONFIG_FILE_SET, configFlag, 0, 0X10, 0, 0, 0);
   }
  //WebToLight( MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST, dir, 0, angle, 0, 0, 0);

}
const TAIL_ANGLE_MIN =90-25;
const TAIL_ANGLE_MAX =90+25;
 var TailAngleStart=new Uint16Array(4);//30;
 var TailDir       =new Uint16Array(4);
 var MotoDuojiId=0;

 const MOTO_DUOJI_ANGLE_RATE_BIT_NUM =6;
 const MOTO_DUOJI_ANGLE_RATE =(1<<MOTO_DUOJI_ANGLE_RATE_BIT_NUM);//64
 const MOTO_DUOJI_ANGLE_90=90*MOTO_DUOJI_ANGLE_RATE;

 const MOTO_DUOJI_DIR_91_135   =1;
 const MOTO_DUOJI_DIR_135_91   =3;
 const MOTO_DUOJI_DIR_91_89    =5;
 const MOTO_DUOJI_DIR_89_45    =7;
 const MOTO_DUOJI_DIR_45_89    =9;
 const MOTO_DUOJI_DIR_89_91    =11;

//
var MotoDuojiAngleMinBuf= new Uint16Array(4);//{ (90-25)*MOTO_DUOJI_ANGLE_RATE,(90-30)*MOTO_DUOJI_ANGLE_RATE,(90-40)*MOTO_DUOJI_ANGLE_RATE,(90-45)*MOTO_DUOJI_ANGLE_RATE};
MotoDuojiAngleMinBuf[0]=(90-25)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMinBuf[1]=(90-30)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMinBuf[2]=(90-40)*MOTO_DUOJI_ANGLE_RATE;
MotoDuojiAngleMinBuf[3]=(90-45)*MOTO_DUOJI_ANGLE_RATE;

 //var MotoDuojiAngleMaxBuf={ (90+25)*MOTO_DUOJI_ANGLE_RATE,(90+30)*MOTO_DUOJI_ANGLE_RATE,(90+40)*MOTO_DUOJI_ANGLE_RATE,(90+45)*MOTO_DUOJI_ANGLE_RATE};
 var MotoDuojiAngleMaxBuf= new Uint16Array(4);
 MotoDuojiAngleMaxBuf[0]=(90+25)*MOTO_DUOJI_ANGLE_RATE;
 MotoDuojiAngleMaxBuf[1]=(90+30)*MOTO_DUOJI_ANGLE_RATE;
 MotoDuojiAngleMaxBuf[2]=(90+40)*MOTO_DUOJI_ANGLE_RATE;
 MotoDuojiAngleMaxBuf[3]=(90+45)*MOTO_DUOJI_ANGLE_RATE;


 //45 -->

 var MotoDuojiAngleStep=[64,64,64,64];
 //step =0.5 45 = 90Clk= 90 *20=1.8s
 //step =1   45 = 45Clk= 45 *20=0.9s



function WebMotoDuojiCtrl(wheelId,dir,angle,bodyId)
{
    var curTime= new Date().getTime();
    let writeLen=256;
    curTime&=0X7FFFFFFF;
    if(curTime<WebMotoStep28YRotateAngleSendTimeOut)
    {
      console.log("WebMotoDuojiCtrl too fast: "+(WebMotoStep28YRotateAngleSendTimeOut-curTime));
      return ;
    }
    WebMotoStep28YRotateAngleSendTimeOut = curTime ;
    WebMotoStep28YRotateAngleSendTimeOut+=100;

    if(angle<-180) angle= -180;
    if(angle> 180) angle=  180;


    if(bodyId!=-1){
        MotoDuojiId= bodyId & 0X03 ;

    }
    let duojiCurId=MotoDuojiId;
    //WebToLight( MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST, dir, 0, angle, 0, 0, 0);
    let moveBuf =new Uint8Array(256*3);
    let tailAngleStart=TailAngleStart[duojiCurId];

    let i;
    let step =5;

    console.log("WebMotoDuojiCtrl "+wheelId.toString(16)+" dir "+dir + "angle :"+ tailAngleStart+" id "+duojiCurId+"bodyId"+bodyId);





      if(dir==0XF9){
        writeLen=2;
        moveBuf[0]=angle;
        moveBuf[1]=angle;
        tailAngleStart=angle ;
      //  WebToLightByNodeId(wheelId, MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST, dir, writeLen, 1, moveBuf, writeLen, 0);
      }else {
    if(dir==1){
      TailDir[duojiCurId]=1;
    for(i=0; i<256; i++){
      moveBuf[i]=tailAngleStart;//+tailAngleStep;
      //if((i%step)!=0)continue;
      if(tailAngleStart>TAIL_ANGLE_MAX)break;
      tailAngleStart++;
    }

    i&=~0X01;
    writeLen=i;
    if(writeLen==0){
      moveBuf[0]=TAIL_ANGLE_MAX;
      moveBuf[1]=TAIL_ANGLE_MAX;
      tailAngleStart=TAIL_ANGLE_MAX;
      writeLen=2;
    }

  }else{
    TailDir[duojiCurId]=0XFF;
    for(i=0; i<256; i++){
      moveBuf[i]=tailAngleStart;//+tailAngleStep;
  //    if((i%step)!=0)continue;
      if(tailAngleStart<TAIL_ANGLE_MIN){
        break;
      }
      tailAngleStart--;
    }
    i&=~0X01;
    writeLen=i;
    if(writeLen==0){
      moveBuf[0]=TAIL_ANGLE_MIN;
      moveBuf[1]=TAIL_ANGLE_MIN;
      tailAngleStart=TAIL_ANGLE_MIN;
      writeLen=2;
    }
  }
}

  TailAngleStart[duojiCurId] = tailAngleStart;

  dir&=0XFF;
  dir|=(duojiCurId<<8);
    WebToLightByNodeId(wheelId, MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST, MotoDuojiAddr, writeLen, 1, moveBuf, writeLen, 0);
    MotoDuojiAddr++;
    MotoDuojiAddr&=0XFFFF;
}
function WebMotoStep28YRotateAngle(angle)
{
    var curTime= new Date().getTime();
    curTime&=0XFFFFFFFF;
    if(curTime<WebMotoStep28YRotateAngleSendTimeOut)
    {
      console.log("WebMotoStep28YRotateAngle too fast: "+(WebMotoStep28YRotateAngleSendTimeOut-curTime));
      return ;
    }
    WebMotoStep28YRotateAngleSendTimeOut = curTime ;
    WebMotoStep28YRotateAngleSendTimeOut+=100;

    if(angle<-180) angle= -180;
    if(angle> 180) angle=  180;
    let step = angle * MOTO_STEPY28_CYCLE_STEP_NUM_BYANGLE;
    console.log("WebMotoStep28YRotateAngle: "+angle);
     WebToLight( MahuConfig.MAHU_CMD_WEB_MOTO_STEP_DIST, step, 0, 0, 0, 0, 0);



}

var CmdCount=0;
function WebMotoStepMes(mes){
  let mesLen= mes.length;
  let mesBuf= new Uint8Array(0X20);
  if(mesLen>0X20)mesLen=0X20;
  let i;
  for (i = 0; i< mesLen; i++) {
    mesBuf[i]=mes.charCodeAt(i);
  }

  WebToLight(MahuConfig.MAHU_CMD_WEB_MESSAGE,CmdCount,mesLen,0,mesBuf,mesLen);
  CmdCount++;
}
function WebSetNodeCurIdNo(nodeCurIdNo){
  console.log("web socket  SetNodeCurIdNo "+nodeCurIdNo)
  WebToServer(MahuConfig.MAHU_CMD_LIGHT_CUR_NODE_ID_SET,nodeCurIdNo,0,0,0,0);
  CmdCount++;
}
var SystemDeviceSn=0;
function WebSetNodeElfPort(deviceSn2){
//  WebToServer(MahuConfig.MAHU_USER_ID_SERVER,elfPort,0,0,0,0);

if(WebClient.ConnectRcvCount==0)
{
    return MahuConfig.SYSTEM_FAIL;
}
if(WebClient.socket==null)
{
    WebClient.ConnectRcvCount=0;
    return MahuConfig.SYSTEM_FAIL;
}

var SnowYunCommand = {
  deviceSn:deviceSn2,
  requestId: 1709867547576 ,
  commandId:"ElfServer",//":"$remote_control",
  paras:"local3030X",//":{"cmd ":"on"},
   command:"logon",
  state:"ok"
}

SystemDeviceSn=deviceSn2;

var json = JSON.stringify(SnowYunCommand);
console.log(" WebSetNodeElfPort "+json)
//   let buf = Buffer.from(json);
  //WebSendDataBuf.length= MahuConfig.MAHU_CMD_HEADER_SIZE;
//    WebClient.socket.send(bufuf);
let i,j=json.length;
let  buf=new Uint8Array(j);
for(i=0; i<j; i++)buf[i]=json.codePointAt(i);
//  buf[j]=0;
   //WebClient.socket.send(json);
   WebClient.socket.send(buf);
}

function WebCmdByJson(cmd,addr){
//  WebToServer(MahuConfig.MAHU_USER_ID_SERVER,elfPort,0,0,0,0);

if(WebClient.ConnectRcvCount==0)
{
    return MahuConfig.SYSTEM_FAIL;
}
if(WebClient.socket==null)
{
    WebClient.ConnectRcvCount=0;
    return MahuConfig.SYSTEM_FAIL;
}

var SnowYunCommand = {
  deviceSn:SystemDeviceSn,
  requestId: 1709867547576 ,
  commandId:"ElfServer",//":"$remote_control",
  paras:"local3030X",//":{"cmd ":"on"},
   command:cmd,
  state:addr
}



var json = JSON.stringify(SnowYunCommand);
console.log(" WebSetNodeElfPort "+json)
//   let buf = Buffer.from(json);
  //WebSendDataBuf.length= MahuConfig.MAHU_CMD_HEADER_SIZE;
//    WebClient.socket.send(bufuf);
let i,j=json.length;
let  buf=new Uint8Array(j);
for(i=0; i<j; i++)buf[i]=json.codePointAt(i);
//  buf[j]=0;
   //WebClient.socket.send(json);
   WebClient.socket.send(buf);
}
function WebCmdBufByJson(cmd,dataBuf,dataCount){
//  WebToServer(MahuConfig.MAHU_USER_ID_SERVER,elfPort,0,0,0,0);
let i,j;

if(WebClient.ConnectRcvCount==0)
{
    return MahuConfig.SYSTEM_FAIL;
}
if(WebClient.socket==null)
{
    WebClient.ConnectRcvCount=0;
    return MahuConfig.SYSTEM_FAIL;
}
let paraBuf=new Array(dataCount)
let paraBufStr="null"
if(dataCount>0){
for(i=0; i<dataCount; i++){
    paraBuf[i]=dataBuf[i].toString();
}
    paraBufStr= "["+paraBuf.toString()+"]";
}

var SnowYunCommand = {
  deviceSn:SystemDeviceSn,
  requestId: 1709867547576 ,
  commandId:dataCount.toString(),//":"$remote_control",
  paras:paraBufStr,//":{"cmd ":"on"},
   command:cmd,
  state:0
}



var json = JSON.stringify(SnowYunCommand);
console.log(" WebSetNodeElfPort "+json)
//   let buf = Buffer.from(json);
  //WebSendDataBuf.length= MahuConfig.MAHU_CMD_HEADER_SIZE;
//    WebClient.socket.send(bufuf);
 j=json.length;
let  buf=new Uint8Array(j);
for(i=0; i<j; i++)buf[i]=json.codePointAt(i);
//  buf[j]=0;
   //WebClient.socket.send(json);
   WebClient.socket.send(buf);
}
export   { WebClient , WebClientConnect,
                        WebMkwheelsTest,
                        WebMotoDuojiCtrl,
                        WebToLightByNodeId,
                        WebMkwheelsConfig,
                        WebSetNodeCurIdNo,
                    WEB_MAHU_USER_LOG_ON_,
                    WebSetNodeElfPort,
                    WebCmdByJson,
                    WebCmdBufByJson
                   }  ;
