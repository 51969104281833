<template>
  <transition name="v">
    <div v-show="isShow" class="loading">
      <span>asdfasdfasdfasdfasdf{{ msg }}</span>
    </div>
  </transition>
</template>

<script  >
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'LoadingA',
  props: {
    msg: {
      type: String
    },
  },
  setup(props, context) {
    const isShow = ref(false)
    return {
      isShow
    }
  },
  methods: {
    show() {
      this.isShow = true
      this.$emit('show')
    },
    hide() {
      this.isShow = false
    },
  },
  mounted() {
    console.log('挂载')
  },
  unmounted() {
    console.log('卸载')
  }

})
</script>
