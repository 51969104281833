<template>
  <div class="marker" id="marker">
  <p>"adfasdfasdsf"</p>
      <p>{{this.a}}</p>
      <p>{{this.prop1}}</p>
      <button @click="test">hello:{{this.prop2}}</button>
  </div>
</template>

<script>
export default {
    name:'markerDetail',
    props:['prop1','prop2'],
    data(){
        return{
            a:"markerDetail"

        }
    },
    created(){
        console.log('zi组件在created调用了')

    },
    methods:{
        test(){

            console.log(this.prop1,'prop1')
        }
    },
    mounted(){
        console.log('zi组件在mounted调用了')
    },
    beforeUnmount(){
        console.log('zi组件准备销毁了')
    },
    unmounted(){
        console.log('zi组件销毁了')
    }
}
</script>

<style   scoped>
.marker{
    z-index: 2022;
    background: pink;
    height: 500px;
    width: 500px;
}

</style>
